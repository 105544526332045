import React from 'react';
import {viewModules} from '../config.js';
//import {api} from './api';


const styles = {

  dataListObject: {
    position: 'relative',
  },

  topArrow: {
    borderTop: '1px solid #aaa',
    borderRight: '1px solid #aaa',
    width: 25,
    height: 25,
    background: 'white',
    position: 'absolute',
    top: -13,
    left: '15%',
    transform: 'rotate(-45deg)'
  },
  header: {
    textAlign: 'right',
    padding: 10,
    borderBottom: '1px solid #dadada'

  },
  body :{
    padding: '0 10px'
  }

};

export class DataListObject extends React.Component{

  handleEditObject = ( e ) => {
    e.stopPropagation();
    this.props.openWindow( this.props.object.id, this.props.params );
  }
  onObjectChange = ( object ) => {
    this.props.onObjectChange( object );
  }
  render(){
    const { object, params } = this.props;

    const View = viewModules[params.view];

    return (
      <div className="dataListObject" style={styles.dataListObject}>

        <div className="dataListObject-body" style={ styles.body }>

        <View
          object={object}
          module={params.module}
          onChange={this.props.onChange}
          onStatusChange={this.onObjectChange}
          openWindow={this.props.openWindow}
          lang={this.props.lang}
        />
        </div>
        <div className="dataListObject-footer"/>
      </div>
    );

  }
}
