import React from 'react';
import { createRoot } from 'react-dom/client';
import {Bootstrap} from 'bootstrap';
import {PopperJs} from 'popper.js';
import { HashRouter as Router } from 'react-router-dom';
//import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Admin from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
//const apiSettings = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_PROD_API_URL : process.env.REACT_APP_DEV_API_URL;
//import * as serviceWorker from './serviceWorker';
//console.log( localStorage );
//  localStorage.clear();
const container = document.getElementById( 'application' );
const root = createRoot( container );
const url = window.location.pathname.substring(1).split('/');
root.render(
    <Router>
        <Admin query={url} />
    </Router>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
//serviceWorker.register();
serviceWorkerRegistration.register({
    onUpdate: (registration) => {
      if (registration && registration.waiting) {
        registration.waiting.postMessage({ type: 'SKIP_WAITING' });
      }
    },
  });
