import React, { Component } from 'react';
import $ from 'jquery';
import {DataList} from './DataList.jsx'
import {api} from './api';

const lang = require("../lang/se.json");

export const articleFormAttributes = {
  "objnamn"         : { "type" : "text", "label" : "label_object_name" },
  "objtitel"        : { "type" : "text", "label" : "label_object_title" },
  "info"            : { "type" : "textarea", "label" : "label_info" },
  "antrader"        : { "type" : "number", "label" : "label_number_rows" },
  "imagelink"       : { "type" : "text", "label" : "label_image_link", "disabled" : true , "read_only" : true  },
  "artnr"           : { "type" : "text", "label" : "label_article_number" },
  "price"           : { "type" : "number", "label" : "label_price", "step" : 0.01 },
  "previousPrice"   : { "type" : "text", "label" : "label_previous_price", "disabled" : true , "read_only" : true },
  "priceUpdated"   : { "type" : "text", "label" : "label_price_updated" },
  "artrabatt"       : { "type" : "checkbox", "label" : "label_article_discount" },
  "specialstatus"   : { "type" : "checkbox", "label" : "label_special_status" }
};

export const articleFormParams = {
  module    : 'Article',
  formModule: 'ArticleFormModule',
  view      : 'ArticleView',
  iconSrc   : api.Article.iconSrc,
  api_query : api.Article.read,
  titleColumns: ['objnamn','objtitel'],
  attributes : articleFormAttributes
};

export class ArticleModule extends Component{

  constructor( props ){
    super(props);

    this.state = {
      tableProps    : {
        idColumn        : 'artid',
        tableColumns  : ['artid','objnamn','objtitel','price', 'priceUpdated','status'],
        articleUsage  : true,
        articleUsageCol: 5,
        disabledSort : [5],
        columnFloatFix : [3],
        searchFields  : ['artid','objnamn','objtitel'],
        sortColumn    : 1,
        sortDirection : 'asc',
        api_query     : api.Article.dataList,
        start         : 0,
        length        : 100

      },
      name            : 'Articles',
      module_query  : window.location.pathname.substring(1).split('/'),

    };

  }
  handleCreate = async (e) => {

    const response = await api.send( 'Article', api.Article.new );
    this.props.openWindow( response.data.id, articleFormParams );
  }
  openWindow = ( objectId ) => {

    this.props.openWindow( objectId, articleFormParams );
  }
  searchByCustomerNr = (e) => {
    let table = $('#data-list').DataTable();
    let input = e.target.value;
    table.search( 'custnr|'+input ).draw();

  }
  render(){

    return (
      <div className="container-fluid">

        <div className="col" style={{ maxWidth: 1000 }}>
          <div id="moduleContent">
            <h3> { lang[this.state.name] } </h3>

            <DataList
              onCreate={this.handleCreate}
              onExternalSearch={ this.searchByCustomerNr }
              tableProps={ this.state.tableProps }
              openWindow={this.openWindow}
              handleLogout={this.props.handleLogout}
              prepareEditableAttributes={this.props.prepareEditableAttributes}
              formParams={articleFormParams}
              onChange={this.props.onChange}
            >
            <div className="mb-1"><input type="text" placeholder={ this.props.lang.label_search_by_custnr } onChange={ this.searchByCustomerNr } /> </div>
            </DataList>
          </div>
        </div>

      </div>

    )

  }
}
