import React, { Component } from 'react';
//import $ from 'jquery';
import {DataList} from './DataList.jsx'
import {api} from './api';
export const adminFormAttributes = {
  "username"      : { "type" : "text", "label" : "label_username" },
  "password"   : { "type" : "password", "label" : "label_password", "disabled" : true },
  "password2"   : { "type" : "password", "label" : "label_password_again", "disabled" : true },
  "priv"      : { "type" : "select", "label" : "label_privilege", "selection" : [] },
  "activeToken"  : { "type" : "text", "label" : "label_admin_token", "disabled" : true }
}

export const adminFormParams = {
  module    : 'Admin',
  formModule: 'AdminFormModule',
  view      : 'AdminView',
  iconSrc   : api.Admin.iconSrc,
  api_query : api.Admin.read,
  titleColumns: ['name','company'],
  attributes : adminFormAttributes
};

export class AdminModule extends Component{

  constructor( props ){
    super(props);

    this.state = {
      tableProps    : {
        idColumn        : 'id',
        tableColumns  : ['id','username','priv','activeToken','expire'],
        searchFields  : ['username','priv'],
        sortColumn    : 1,
        sortDirection : 'asc',
        api_query     : api.Admin.dataList+'/'+props.user.token,
        start         : 0,
        length        : 100

      },
      name            : 'admins',
      module_query  : window.location.pathname.substring(1).split('/'),

    };

  }

  openWindow = ( objectId ) => {
    this.props.openWindow( objectId, adminFormParams );
  }

  handleCreate = async (e) => {

    const response = await api.send( 'Admin', api.Admin.new );
    this.props.openWindow( response.data.id, adminFormParams );
  }

  render(){

    return (
      <div className="container-fluid">

        <div className="col" style={{ maxWidth: 1000 }}>
          <div id="moduleContent">
            <h3> { this.props.lang[this.state.name] } </h3>
            <DataList
              tableProps={ this.state.tableProps }
              openWindow={this.openWindow}
              handleLogout={this.props.handleLogout}
              onCreate={this.handleCreate}
              prepareEditableAttributes={this.props.prepareEditableAttributes}
              formParams={adminFormParams}
              onChange={this.props.onChange}
              openinWindow={true}
            />
          </div>
        </div>

      </div>

    )

  }
}
