import $ from 'jquery';
import {uniqId} from './uniqid.js';
//import {api} from './modules/api.js';
import {isPhone, getBrowsingDevice } from './utils/mediaDevice.jsx';
import { useMainStore } from 'mainStore.js';

export function windowManager( ){

    this.getWindows = function (){
        const {windows} = useMainStore.getState();
        return windows;
    };

    this.windowExists = function ( windowId ){
        const windows = this.getWindows();
        const w = windows.find( w => w.windowId === windowId );

        return typeof w === 'undefined' ? false : true;

    };

    this.generateId = function (){
        const id = 'window_'+uniqId();
        
        if( this.windowExists( id ) )
            return this.generateId();

        return id;
    }

  this.pinWindow = function ( windowId ){
    let settings = { ...this.getWindow( windowId ) };
    let pinButton = $('#'+windowId).find('.pin');

    if( $(pinButton).hasClass('pinnedButton')){
      settings.pinned = false;
      $('#'+windowId).removeClass('pinned');
      $(pinButton).removeClass('pinnedButton');

    } else {
      settings.pinned = true;
      $('#'+windowId).addClass('pinned');
      $(pinButton).addClass('pinnedButton');

    }
    this.setWindow( settings, windowId );
  };

  this.displayOpenWindows = function(){

    let windows = JSON.parse(localStorage.getItem('windows'));

    for(var i in windows ){
      let window = windows[i];
      this.windowManager.openWindow( window.api_url, window.module, window.objectId , true);
    }

  };

  this.compress = function ( windowId ){

    let windo = $('#'+windowId);
    let button = $(windo).find('.compress');
    let panelHeight = $('#top-panel').outerHeight();
    let documentHeight = $(window).height();
    let windowHeight = documentHeight - panelHeight;
    let winSettings = this.getWindow( windowId );
    let device = getBrowsingDevice();
    if( isPhone( device ) ){
        winSettings.orgWidth = device.screen.width - 30;
        winSettings.orgLeft = 0;
    }


    $(windo)
    .height( windowHeight )
    .removeClass('minimized maximized stacked')
    .addClass('openWindow')
    .css({ width:winSettings.orgWidth, height:winSettings.orgHeight, top:winSettings.orgTop, left: winSettings.orgLeft })
    .draggable('enable')
    .resizable('enable');

    this.setBodyHeight(winSettings);

    $(button).css('display','none');
    $(button).siblings('.fa-window-maximize').css('display','block');
  };

  this.maximize = function( button, windowId )
  {
    if( typeof button === 'undefined' ){
      button = $('#'+windowId).find('.maximize');
    }
    let panelHeight = $('#top-panel').outerHeight();
    let documentHeight = $(window).height();
    let windowHeight = documentHeight - panelHeight;

    $('#'+windowId)
      .removeClass('openWindow minimized stacked')
      .addClass('maximized')
      .css({ height:windowHeight, width: '100%', left:0, top:panelHeight })
      .draggable('disable')
      .resizable('disable');

    $(button).css('display','none');
    $(button).siblings('.compress').css('display','block');
    let winSettings = this.getWindow( windowId );
    this.setBodyHeight(winSettings, true);
  };

  this.compressWindows = function()
  {
    let windows = $('#windowWrapper').find('.window');
    let self = this;
    $.each( windows , function( i, el ){
      let winSettings = self.getWindow( $(el).attr('id') );
      $(el).removeClass('stacked minimized maximized').addClass('openWindow').css({
        width: winSettings.orgWidth,
        left: winSettings.orgLeft,
        height: winSettings.orgHeight,
        top: winSettings.orgTop
      }).draggable('enable').resizable('enable');
      self.setBodyHeight( winSettings );
      let button = $(el).find('.fa-compress-arrows-alt');

      $(button).css('display','none');
      $(button).siblings('.fa-columns').css('display','block');

    });

  };

  this.stackWindows = function(){
    let screenWidth = $(window).width();
    let panelHeight = $('#top-panel').outerHeight();
    let documentHeight = $(window).height();
    let windowHeight = documentHeight - panelHeight;

    let windows = $('#windowWrapper').find('.window');
    let qty = windows.length;
    let leftPos = 0;

    let windowWidth = screenWidth / qty;
    let self = this;
    $.each( windows , function( i, el ){
      let winSettings = self.getWindow( $(el).attr('id') );
      $(el)
        .removeClass('openWindow minimized maximized')
        .addClass('stacked')
        .css({ width: windowWidth, left: leftPos, height: windowHeight, top: panelHeight })
        .draggable('disable')
        .resizable('disable');
      self.setBodyHeight( winSettings, true );
      leftPos = leftPos + windowWidth;
      let button = $(el).find('.stack');
      $(button).css('display','none');
      $(button).siblings('.compressX').css('display','block');

    });
  };

  this._makeWindowTitle = function( object, params )
  {
    let fields = [];
    for(var i in params ){
      fields.push(object[params[i]]);
    }

    return fields.join(" ");


  }

    this.updateWindowObject = function ( windowId, object ){
        let win = { ...this.getWindow( windowId ) };
        win.object = Object.assign({}, win.object, object );
        this.setWindow( win, windowId );
    };

    this.setWindow = function( windowSettings, windowId, params ){

        const { updateWindow } = useMainStore.getState();
        const updated = typeof params !== 'undefined' ? Object.assign( { ...windowSettings }, { ...params } ) : { ...windowSettings };

        updateWindow( updated );

    };

    this.getWindow = function( windowId, onlyIndex){

        const windows = this.getWindows();
        
        if( windows.length === 0 ){
        return false;
        }

        if( typeof onlyIndex === 'undefined' ){
            return windows.find( w => w.windowId === windowId );
        } else {
            return windows.findIndex( w => w.windowId === windowId );
        }

    };

  this.removeWindow = function( windowId )
  {
    const { removeWindow } = useMainStore.getState();
    removeWindow( windowId );

  };

  this.focusWindow = function( windowId, fetchedFromStorage ){

    let windowSettings = this.getWindow( windowId );

    if( windowSettings === false || typeof windowSettings.module === 'undefined' || windowSettings.module === null  ) return false;

    let self = this;

    let window = $('#'+windowSettings.windowId);
    if($(window).hasClass('minimized')){
      $(window).css('display','block').animate({ width:windowSettings.orgWidth, height:windowSettings.orgHeight, top:windowSettings.orgTop, left:windowSettings.orgLeft, opacity:1 },200);
      $(window).removeClass('minimized').addClass('openWindow');
    }

    if( !$(window).hasClass('openWindow'))
      $(window).addClass('openWindow');

    if( $(window).hasClass('minimized') )
      $(window).removeClass('minimized');


    if( typeof fetchedFromStorage !== 'undefined' && fetchedFromStorage !== false ){
      $(window).css('display','block').animate({ width:windowSettings.orgWidth, height:windowSettings.orgHeight, top:windowSettings.orgTop, left:windowSettings.orgLeft, opacity:1 },200);
      if( windowSettings.pinned === true ){
        $(window).addClass('pinned');
        $(window).find('.pin').addClass('pinnedButton');


      }

      self.setBodyHeight( windowSettings );

    }
    let windows = $(window).siblings('');

    $.each( windows, function(i, el){
      if( !$(el).hasClass('pinned'))
        $(el).css('z-index','900');
      if( $(el).hasClass('openWindow'))
        $(el).removeClass('openWindow');
    });

    $(window).css({ zIndex: 999 });


  };

  this.updateMeasurements = function ( windowSettings ){

    let window = $('#'+windowSettings.windowId);
    const settings = { ...windowSettings };
    settings.orgWidth  = $(window).width();
    settings.orgHeight = $(window).height();
    settings.orgTop    = $(window).css('top');
    settings.orgLeft   = $(window).css('left');

    this.setWindow( settings );
    this.setBodyHeight( settings );

  }

  this.addPanelIcon = function( windowSettings ){
    $('#'+windowSettings.iconContainer).append('<li id="icon-'+windowSettings.windowId+'" class="item top-panel-window-icon" title="'+windowSettings.title+'"  data-id="'+windowSettings.windowId+'"><i  data-id="'+windowSettings.windowId+'" class="icon fas '+windowSettings.iconClass+'"></i></li>');
  };

  this.removePanelIcon = function( windowSettings ){
    let windowId = windowSettings.windowId;
    let items = $('#icon-container').find('.item');
    $.each(items, function(i,el){
      if($(el).data('id') === windowId ){
        $(el).remove();
        return true;
      }
    });
    return false;
  }
  this.setBodyHeight = function ( windowSettings, maximize ){

    let wh = windowSettings.orgHeight;
    if( typeof wh === 'undefined' || wh.length === 0 || typeof maximize !== 'undefined' )
      wh = $('#'+windowSettings.windowId).height();
    let ph = $('#'+windowSettings.windowId).find('.window-panel').outerHeight();
    let height = wh - ph;

    $('#'+windowSettings.windowId).find('.window-body').css('height', height );

  }

};
