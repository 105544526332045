import React from 'react';
import {windowManager} from '../windowManager';
import {api} from './api';
import $ from 'jquery';
import {ObjectForm} from './ObjectForm.jsx';
//import {Admin} from './Admin.jsx';
//import {ContentBlock} from './HtmlComponents.jsx';
import {adminFormAttributes} from './AdminModule';

//var lang = require("../lang/se.json");

export class AdminFormModule extends React.Component{

  constructor( props ){
    super( props )

    let attrs = JSON.parse( JSON.stringify( adminFormAttributes ) );
    attrs.priv.selection = [
        {
          "label"     : props.lang['label_admin'],
          "value"     : 1
        },
        {
          "label"     : props.lang['label_super_admin'],
          "value"     : 2
        }
    ];


    this.state = {
      object : props.object,
      formAttributes: attrs
    };

    
    this.wm = new windowManager();
    this.updateWindowTitle = this.updateWindowTitle.bind( this );
    this.onChange = this.onChange.bind( this );
  }

  updateWindowTitle( e ){

    this.props.updateTitle( e.target.value );

  }

  onChange( object, changes ){
    this.setState({ object : object });
    this.props.onChange( object, changes );
  }

  handleDelete = () => {
      $('#'+this.props.windowId).find('.close').click();
      

  }

  async fetchObject(){
    let self = this;
//    let url = api.api+api.Admin.read+'/'+this.props.objectId;
//    let order = false;

    await api.send(
      'Admin',
      'admin/'+this.props.objectId,
      false,
      {
        type: 'GET',
        success : function( response ){

          let object = response.data;
          let time = new Date().getTime();
          self.setState({ isUpdated:  time, object: object });
      //    self.props.updateState();
          self.props.updateTitle( self.props.makeWindowTitle( object , ['name']) );

        }
      }
    );

  }

  async componentDidMount(){
    await this.fetchObject();

  }

  getExpireDate( expire ){
   
    let date = new Date(expire*1000);
    let year = date.getFullYear();
    let month = '0'+ ( parseInt( date.getUTCMonth() ) +1 );
    let day = '0'+date.getUTCDate();
    let hours = date.getHours();
    let minutes = "0" + date.getMinutes();

    
    // Will display time in 10:30:23 format
    var formattedTime = year + '-' + month.slice(-2) + '-' + day.slice(-2)  + ' ' +hours + ':' + minutes.substr(-2);
    return formattedTime;
  }

  render(){

    let object = this.state.object;
    if( typeof object === 'undefined' || object === false ) return null;
    let time = this.getExpireDate( object.expire );
    let attributes = this.props.prepareEditableAttributes( this.state.formAttributes, object );

    return (
      <div className="container">
        <div className="row">
          <div className="col-md-12">
          <div className="small pull-right">{ this.props.lang.table_columns.activeToken } { this.props.lang.table_columns.expire } { time } </div>
            <ObjectForm
              objectId={ object.id }
              attributes={attributes}
              tabIndex={this.props.tabIndex}
              savePath={ api.Admin.save }
              parent={this.props.parent}
              object={object}
              onChange={this.onChange}
              updateState={this.props.updateState}
              module="Admin"
              refreshOnAction={true}
              onDelete={this.handleDelete}

            />
            
          </div>
        </div>
      </div>
    );
  }
};
