import React, { Component } from 'react';
//import $ from 'jquery';
import {DataList} from './DataList.jsx'
import {api} from './api';

const lang = require("../lang/se.json");
const searchActions = [
  {
    'label'   : lang.label_customers,
    'module'  : 'CustomerModule',
    'url'     : 'customers'
  },
  {
    'label'   : lang.label_employees,
    'module'  : 'EmplyoeeModule',
    'url'     : 'employees'
  }
];

export const customerFormAttributes = {
  "name"          : { "type" : "text", "label" : "label_company_name", "disabled" : true },
  "custnr"        : { "type" : "number", "label" : "label_customer_number", "disabled" : true },
  "username"      : { "type" : "text", "label" : "label_username" },
  "orgnr"         : { "type" : "text", "label" : "label_registration_number" },
  "info"          : { "type" : "textarea", "label" : "label_customer_information" },
  "terms"         : { "type" : "text", "label" : "label_payment_terms", "disabled" : true },
  "comments"      : { "type" : "textarea", "label" : "label_comments" },
  "email"         : { "type" : "text", "label" : "label_email_invoice", "disabled" : true },
  "customergroupid" : { "type" : "select" ,"label" : "label_customer_group" , "selection" : [] },
  "referralTo"  : { "type" : "number", "label" : "label_affiliate_id", "disabled" : true },
  "affiliateCode"  : { "type" : "text", "label" : "label_affiliate_code", "disabled" : true },
  "doInvoicePool" : { "type" : "checkbox", "label" : "label_do_invoice_pool" },
  "useminimumvalue" : { "type" : "checkbox", "label" : "label_use_minimum_value" },
  "isAffiliate": { "type" : "checkbox", "label" : "label_is_affiliate", "helper": { text: lang['is_affiliate_helper_text'] } },
};

export const customerFormParams = {
  module    : 'Customer',
  formModule :'CustomerFormModule',
  view      : "CustomerView",
  iconSrc   : api.Customer.iconSrc,
  api_query : api.Customer.read,
  titleColumns: ['name'],
  attributes : customerFormAttributes
};

export class CustomerModule extends Component{

  constructor( props ){
    super(props);


    this.state = {
      tableProps    : {
        selectionColumn  : 'id',
        tableColumns  : ['id','name','orgnr','custnr','username','isAffiliate'],
        isBool: [5],
        searchFields  : ['id','name','orgnr','custnr','username'],
        sortColumn    : 1,
        sortDirection : 'asc',
        api_query     : api.Customer.dataList,
        start         : 0,
        length          : 100

      },
      name            : 'Customers',
      module_query  : window.location.pathname.substring(1).split('/'),

    }

  }

  handleCreate = async (e) => {

    const response = await api.send( 'Customer', api.Customer.new );
    this.props.openWindow( response.data.id, customerFormParams );
  }

  openWindow = ( objectId, params ) => {

    // If a child component uses openWindow for a link, it will use the params arguments
    let wparams = ( (typeof params === 'object') ? params : customerFormParams );
    this.props.openWindow( objectId, wparams );
  }

  render(){

    return (
      <div className="container-fluid">

        <div className="col" style={{ maxWidth: 1000 }}>
          <div id="moduleContent">
            <h3> { lang[this.state.name] } </h3>
            <DataList
              onCreate={this.handleCreate}
              handleLogout={this.props.handleLogout}
              searchActions={searchActions}
              tableProps={ this.state.tableProps }
              openWindow={this.openWindow}
              prepareEditableAttributes={this.props.prepareEditableAttributes}
              formParams={customerFormParams}
              onChange={this.props.onChange}
              lang={this.props.lang}
            />
          </div>
        </div>
      </div>
    )

  }
}
