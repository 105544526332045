export const getBrowsingDevice = () => {
    const device = {
        userAgent: window.navigator.userAgent,
        screen: window.screen,
        languages: window.navigator.languages,
        pixelRatio: window.devicePixelRatio
    };
    return device;
}

export function isPhone( device ){

    let string = device.userAgent;
    let isPhone = false;
    if( device.userAgent.indexOf( 'iPhone' ) > -1 ){
        isPhone = true;
    } else if( device.userAgent.indexOf( 'Android' ) > -1 ){
        if( device.screen.width < 450 )
            isPhone = true;
    }

    return isPhone;
}

export function isMobile( device ){
    let mobileExamples = ['iPhone', 'iPad', 'Android', 'KFAPWI' ];
    if( device.userAgent.includes( mobileExamples ) )
        return true;

    return false;
}