import React from 'react';

export class Block extends React.Component {

  update = ( block ) => {
    const {product} = this.props;

    product.blocks[this.props.blockNr] = block;
    this.props.onUpdate( product );


  }
  handleRemoveBlock = ( ) => {
    const product = { ...this.props.product };

    product.blocks.splice( this.props.blockNr, 1);

    this.props.onUpdate( product );
  }
/*
  handleTextSize = ( e, index ) => {
    const product = { ...this.props.product };
    const block = { ...this.props.object };
    let rows = [ ...block.rows ];
    let row = { ...rows[index] };

    if( e.target.value < e.target.attributes.min.value )
      e.target.value = parseInt(e.target.attributes.min.value);
    row.size = e.target.value;

    rows[index] = row;
    block.rows = rows;
    product.blocks[this.props.blockNr] = block;

    this.update( block );


  }
  handleAddRow = ( ) => {
    const block = { ...this.props.object };
    let rows = [];
    if( typeof block.rows !== 'undefined')
      rows = [ ...block.rows ];

    const newRow = {
      text:'',
      align:'',
      size:3
    };

    rows.push(newRow);
    block.rows = rows;

    this.update( block );

  }

  handleRemoveRow = ( index ) => {
    const block = { ...this.props.object };
    let rows = [ ...block.rows ];
    rows.splice(index,1)
    block.rows = rows;
    this.update( block );


  }
*/

  handleChangeChar = ( e ) => {
    const block = { ...this.props.object };
   // console.log( e.target.value );
    let len = parseInt( e.target.value );
    let rows = [];
    if( typeof block.rows !== 'undefined')
      rows = [ ...block.rows ];

    
    let char = 'c';
    let str = new Array(len+1).join( char );
    rows[0].text = str;
    
    block.rows = rows;

    this.update( block );

  }

  handleProperty = (e, key) => {
    const block = { ...this.props.object };

    let value = e.target.value;
    if( e.target.type === 'checkbox' ){
      value = ( e.target.checked ? true : false );
    }
    block[key] = value;

    this.update( block );
  }

  render(){
    const type = this.props.object.type;

    return (
      <div>{ this.renderBlock( type ) } </div>
    );
  }

  renderBlock( type ){
      switch( type ){

        default:
        case "TextBlock":
          return this.renderTextBlock();
        case "HoleBlock":
          return this.renderHoleBlock();
        case "FrameBlock":
          return this.renderFrameBlock();
        case "SymbolBlock":
          return this.renderSymbolBlock();

      }
  }

  renderTextBlock(){
    const rows = this.props.object.rows || [];
    const lang = this.props.lang;
    
    return(
      <div className="card bg-light">
        <div className="card-header">
          <div className="title">
            <i className="fas fa-times-circle text-danger mr-1" style={{ cursor:'pointer'}} onClick={this.handleRemoveBlock} />
            { lang[ this.props.object.translation_tag] }
          </div>
        </div>
        <div className="card-body" style={{ padding: 7 }}>
        {
          rows.map( (row,i) => {
            let j=i+1;
            return (
              <div key={i} className="row">
                <div className="col">
                  <input type="number" className="form-control" defaultValue={ row.text.length } step="1" min="0" onChange={ (e) => this.handleChangeChar(e,i) } />
                </div>
              </div>
            );
          })
        }
        </div>
      </div>

    );
  }

  renderHoleBlock(){
    const {lang, object:block} = this.props;

    return(
      <div className="card bg-light">
        <div className="card-header">
          <div className="title">
            <i className="fas fa-times-circle text-danger mr-1" style={{ cursor:'pointer'}} onClick={this.handleRemoveBlock} />
            { lang[ this.props.object.translation_tag] }
          </div>
        </div>
        <div className="card-body" style={{ padding: 7 }}>
          <div className="row">

          </div>
          <div className="row">
            <div className="col">
              <label>{ lang.label_diameter }</label>
              <input type="number" className="form-control" defaultValue={ block.diameter } step="0.5" onChange={ (e) => this.handleProperty(e,'diameter') } />
            </div>
            <div className="col">
              <label>{ lang.label_quantity }</label>
              <input className="mr-1 form-control" type="number" value={ block.quantity } onChange={ (e) => this.handleProperty(e,'quantity') } />
            </div>
          </div>
        </div>
      </div>

    );
  }

  renderSymbolBlock(){
    const {lang, object:block} = this.props;

    return(
      <div className="card bg-light">
        <div className="card-header">
          <div className="title">
            <i className="fas fa-times-circle text-danger mr-1" style={{ cursor:'pointer'}} onClick={this.handleRemoveBlock} />
            { lang[ this.props.object.translation_tag] }
          </div>
        </div>
        <div className="card-body" style={{ padding: 7 }}>
          <div className="row">
            <div className="col">
              <label>{ lang.label_width }</label>
              <input type="number" className="form-control" defaultValue={ block.width } step="5" onChange={ (e) => this.handleProperty(e,'width') } />
            </div>
            <div className="col">
              <label>{ lang.label_height }</label>
              <input type="number" className="form-control" defaultValue={ block.height } step="1" onChange={ (e) => this.handleProperty(e,'height') } />
            </div>
            <div className="col">
              <label>{ lang.label_quantity }</label>
              <input className="mr-1 form-control" type="number" value={ block.quantity } onChange={ (e) => this.handleProperty(e,'quantity') } />
            </div>
          </div>
        </div>
      </div>

    );
  }

  renderFrameBlock(){
    const {lang, object:block} = this.props;
    let frame = "checked";
    if( block.standard_frame === false )
      frame = "";
    return(
      <div className="card bg-light">
        <div className="card-header">
          <div className="title">
            <i className="fas fa-times-circle text-danger mr-1" style={{ cursor:'pointer'}} onClick={this.handleRemoveBlock} />
            { lang[ this.props.object.translation_tag] }
          </div>
        </div>
        <div className="card-body" style={{ padding: 7 }}>
          <div className="row">
            <div className="col">
              <input className="mr-1" type="checkbox" checked={ frame } onChange={ (e) => this.handleProperty(e,'standard_frame') } />
              <label>{ lang.label_default_frame }</label>
            </div>
            <div className="col">
              <label>{ lang.label_quantity }</label>
              <input className="mr-1 form-control" type="number" value={ block.quantity } onChange={ (e) => this.handleProperty(e,'quantity') } />

            </div>
          </div>
          <div className="row">
            <div className="col">
              <label>{ lang.label_thickness }</label>
              <input type="number" className="form-control" defaultValue={ block.thickness } step="0.5" onChange={ (e) => this.handleProperty(e,'thickness') } />
            </div>
            <div className="col">
              <label>{ lang.label_width }</label>
              <input type="number" className="form-control" defaultValue={ block.width } step="5" onChange={ (e) => this.handleProperty(e,'width') } />
            </div>
            <div className="col">
              <label>{ lang.label_height }</label>
              <input type="number" className="form-control" defaultValue={ block.height } step="5" onChange={ (e) => this.handleProperty(e,'height') } />
            </div>
          </div>
        </div>
      </div>

    );
  }

};
