import { create } from 'zustand'
import { persist, createJSONStorage } from "zustand/middleware";
import { produce } from "immer";


export const useMainStore = create( persist( ( set, get ) => ({
    isAuthenticated: false,
    engine: null,
    authToken: null,
 
    windows: [],

    addWindow: ( window ) => set( 
        produce( ( state ) => {
            state.windows.push( window );
        })
    ),

    removeWindow: ( windowId ) => set(
        produce( ( state ) => {
            const windows = get().windows.filter( w => w.windowId !== windowId );
            state.windows = [ ...windows ];
        }) 
    ),

    updateWindow: ( updatedWindow ) => set(
        produce( ( state ) => {
            const window = get().windows.find( w => w.windowId === updatedWindow.windowId ) || get().addWindow( updatedWindow );

            const index = get().windows.findIndex( w => w.windowId === updatedWindow.windowId )
            state.windows[ index ] = updatedWindow;
        })
    )
    
}),
{
    name: "localStorage", // unique name
    storage: createJSONStorage( () => localStorage ), // (optional) by default the 'localStorage' is used
}) );
