import $ from 'jquery';
var lang = require("../lang/se.json");

function apiModules(){
  this.root = process.env.REACT_APP_API_URL;
  this.api = this.root+'api/';

  this.response = false;
  this.repsonse_status = false;
  this.responseXHR = false;
  this.responder = {};

  this.Auth = {
    "login"               : "authentication/login",
    "logout"              : "authentication/logout"
  };

    this.Admin = {
     "dataList"            : "admin/dataList",
     "read"                : "admin",
     "delete"              : "admin/delete",
     "save"                : "admin/update",
     "iconSrc"             : "/assets/images/icons/system-users.svg",
     "new"                 : "admin/new"
   };

   this.Statistics = {
    "dataList"            : "statistics/dataList",
    "read"                : "statistics",
    "delete"              : "statistics/delete",
    "save"                : "statistics/update",
    "iconSrc"             : "/assets/images/icons/statistics-svgrepo-com.svg",
    "new"                 : "statistics/new"   
   };

   this.Customer = {
    "list"                : "company/list",
    "dataList"            : "company/dataList",
    "read"                : "company",
    "delete"              : "company/delete",
    "save"                : "company/update",
    "iconSrc"             : "/assets/images/icons/system-users.svg",
    "new"                 : "company/new"
  };

  this.Employee = {
    "list"                : "employee/list",
    "dataList"            : "employee/dataList",
    "read"                : "employee",
    "save"                : "employee/update",
    "iconSrc"             : "/assets/images/icons/preferences-desktop-user.svg",
    "new"                 : "employee/new",
    "delete"              : "employee/delete",
    "savePhoneNr"         : "employee/savePhone"
  };

  this.Order = {
    "dataList"            : "order/dataList",
    "read"                : "order",
    "save"                : "order/update",
    "new"                 : "order/new",
    "listRows"            : "order/listRows",
    "delete"              : "order/delete",
    "iconSrc"             : "/assets/images/icons/folder-yellow.svg",
    "updateRow"           : "order/item/update",
    "changeTape"          : "order/rows/changeTape/",
    "updateAddress"       : "order/address/update"
  };

  this.Production = { ...this.Order };

  this.Article = {
    "dataList"            : "article/dataList",
    "read"                : "article",
    "save"                : "article/update",
    "upload"              : "article/upload",
    "iconSrc"             : "/assets/images/icons/asc-de.svg",
    "delete"              : "article/delete",
    "new"                 : "article/new",
    "addToCompany"        : "article/addToCompany",
    "removeItem"          : "article/removeItem"

  };
  this.News = {
    "dataList"            : "news/dataList",
    "read"                : "news",
    "save"                : "news/update",
    "iconSrc"             : "/assets/images/icons/com.github.allen-b1.news.svg",
    "upload"              : "news/upload",
    "delete"              : "news/delete",
    "new"                 : "news/new"

  };
  this.Accessory = {
    "dataList"            : "accessory/dataList",
    "read"                : "accessory",
    "save"                : "accessory/update",
    "upload"              : "accessory/upload",
    "iconSrc"             : "/assets/images/icons/boxes.svg",
    "delete"              : "accessory/delete",
    "new"                 : "accessory/new"

  };

  this.Template = {
    "dataList"            : "template/dataList",
    "read"                : "template",
    "save"                : "template/update",
    "upload"              : "template/upload",
    "iconSrc"             : "/assets/images/icons/website-svgrepo-com.svg",
    "delete"              : "template/delete",
    "new"                 : "template/new"

  };
  this.Sticker = {
    "dataList"            : "stickers/dataList",
    "read"                : "stickers",
    "save"                : "stickers/update",
    "upload"              : "stickers/upload",
    "iconSrc"             : "/assets/images/icons/boxes.svg",
    "delete"              : "stickers/delete",
    "new"                 : "stickers/new"

  };
  this.Prices = {
    "priceList"           : "prices/priceList",
    "tapes"               : "prices/tapes",
    "iconSrc"             : "assets/images/icons/calligraplan.svg"
  };
  this.CustomerGroup = {
    "dataList"            : "customerGroup/dataList",
    "read"                : "customerGroup",
    "save"                : "customerGroup/update",
    "delete"              : "customerGroup/delete",
    "list"                : "customerGroup/list",
    "new"                 : "customerGroup/new",
    "iconSrc"             : "assets/images/icons/_system-users.svg",
    "addToCompany"        : "customerGroup/addToCompany",
    "removeItem"          : "customerGroup/removeItem"

  };
  this.Address = {
    "dataList"            : "apiaddress/dataList",
    "iconSrc"             : "assets/images/icons/x-office-address-book.svg",
    "read"                : "apiaddress",
    "new"                 : "apiaddress/new",
    "save"                : "apiaddress/update",
    "delete"              : "apiaddress/delete"
  };
  this.Quote = {
    "dataList"            : "quote/dataList",
    "iconSrc"             : "assets/images/icons/preferences-activities.svg",
    "read"                : "quote",
    "new"                 : "quote/new",
    "save"                : "quote/update",
    "delete"              : "quote/delete"
  }

  this.getAPIUrl = function( module, query_type ) {
    return this.api + this[module][query_type];
  };

  this.saveObject = async function ( objectId, changes, module ) {

    let url = this[module].save;
    changes =  btoa(unescape(encodeURIComponent(JSON.stringify(changes))));

    return await this.send(
      false,
      url,
      { id: objectId, properties: changes },
      {
        type: 'POST',
        dataType: 'json',
        crossDomain: true,
        success: function( response ){

          if( response.code === 200)
            return true;
          else
            return false;
        }
      }
    );

  };
    this.requestPDFFiles = async function( data ){
        let promise = await fetch(process.env.REACT_APP_PDF_API_URL+'convertpdf/init', {body:data, method:'POST' } );
        const response = await promise.json();
        
        if( response.code === 200 ){
            this.handleSuccess( response );
        } else {
            this.handleError( response, response.code, 'convertpdf/init' );
        }
        return response;
    }
  /*
  this.sendFiles = async function( request_query, data ){
    let headers = {};
  

    headers.Authorization = "Bearer "+process.env.REACT_APP_APP_TOKEN;
    if( typeof data.login === 'undefined' && typeof data.logout === 'undefined' ){
      headers["x-auth"] = localStorage.getItem('token')
    }
    
    const promise = await fetch( this.api+request_query, { method: "POST", body: data , headers });
    const response = await promise.json();
    if( response.code == 200 )
        this.handleSuccess( response );
    else 
        this.handleError( response, false, request_query );

    return response;

  }
  */
  this.send = async function( module, request_query, data, parameters, headerOptions ){

    let self = this;
    if( typeof data === 'undefined' )
      data = {};
    
    let headers = {};
    if( typeof headerOptions !== 'undefined' )
        headers = { ...headerOptions };


    headers.Authorization = "Bearer "+process.env.REACT_APP_APP_TOKEN;
    if( typeof data.login === 'undefined' && typeof data.logout === 'undefined' ){
      headers["x-auth"] = localStorage.getItem('token')
    }

    let params = Object.assign(
      {
        url         : this.api + request_query,
        type        : 'POST',
        crossDomain : true,
        headers     : headers,
        data        : data,
        error       : function(json, status, errorThrown) {
          self.handleError( json, status, request_query );
        },
        complete    : function( json , statusText){
          self.handleSuccess( json, statusText );

        }
      },
      parameters
    );

    return await $.ajax( params );

  };
  this.logout = async function( A ){

    let token = localStorage.getItem('token');
    return this.send('Auth', this.Auth.logout, { token: token, logout:true } );

  };
  this.login = async function( credentials ){

    let data = {
      "username"  : credentials[0],
      "password"  : credentials[1]
    };

    const hashed = btoa( unescape( encodeURIComponent( JSON.stringify( data ) ) ) );
    return this.send( 'Auth', this.Auth.login, { credentials: hashed, login:true } );

  }

  this.createNotification = function ( notificationType, text, delay ){
    if( typeof delay === 'undefined' )
      delay = 3000;

    let statusClass = 'info';
    let status = 'info';
    if( notificationType === 'error'){
      statusClass = 'exclamation';
      status = 'danger';
    }

    $('<div class="notification '+notificationType+' text-'+status+'"><i class="fas fa-'+statusClass+'-circle"/> '+text+'</div>' ).appendTo( 'body' ).fadeIn('fast').delay(delay).fadeOut('slow');

  };

  this.exists = function( object ){
    if( typeof object !== 'undefined' && object !== null )
      return true;

    return false;
  };

    this.handleError = function( json, status, request_query ){
        let errorText = '';
        let message = '';
        let code = json.status;
        let response = JSON.parse(json.responseText);

        if(code === 0 ){
        errorText = lang.errors['api_url_not_found'] + " "+ request_query+' Code: '+code;
        } else if( code === 401 ) {

        window.location = window.location.href.split("/").splice(0,-1).join('/')+'/#/logout';

        } else {

        errorText = lang[response.error] || response.error;
        message = response.message;
        code = json.status;
        }
        if( errorText.length > 0 )
        this.createNotification("error", errorText, 10000 );

        else if( message.length  > 0 &&  typeof response.notification !== 'undefined' && response.notification === true )
            this.createNotification("info", message, 5000 );

    }

    this.handleSuccess = function( json, statusText ){
        // do something general on all requests like if a notification should be sent
       
        if( ( json.status > 0 && typeof json.responseText !== 'undefined' ) ){
            let response = JSON.parse(json.responseText);


            if( typeof response.notification !== 'undefined' && response.notification === true ){
                let message = lang[ response.message ] || response.message;
                this.createNotification( "info", message );
            }
            if( typeof response.error !== 'undefined' && response.error !== false ){
                let error = lang[response.error] || response.error;
                this.createNotification( "error", error, 10000 );

            }
        } else if ( typeof json.code !== 'undefined' && json.code == 200 ){
            
            if( typeof json.notification !== 'undefined' && json.notification === true ){
                this.createNotification( "info", json.message );
            }

            if( typeof json.error !== 'undefined' && json.error !== false ){
                this.createNotification( "error", json.error, 10000 );
            }
        }


    }

};

export const api = new apiModules();
