import React, { Component } from 'react';
import {getPixelConverter} from './pixelConverter';
import {Sign} from './Sign.jsx';
import { svgSheetConfig } from './config';
import { Fragment } from 'react';
const lang = require("../lang/se.json");

export class SVGSheet extends Component{

    constructor( props ){

        super(props);
        this.pc = getPixelConverter();
        
        
        this.state = {
            ...props.data,
            width: this.pc.convertTo('pixel', svgSheetConfig.width ),
            height: this.pc.convertTo('pixel', svgSheetConfig.height )
        }
        this.document = React.createRef( props.domId );
    }

    buildCollectionSVG( collection ){
 
        let collectionSvg = collection.items.map( (product, index) => {
            
            let { x,y } = product;
            let translate = `translate( ${x},${y} )`;

            return(
                <g className="Product" transform={ translate } key={index}>
                    <Sign product={product} pixelConverter={ this.pc } />
                </g>
            );

        });
        
        return { height: collection.height, svg: collectionSvg };
      
    }
 
    renderTopLeft( group ){
        let width = group.width - group.margin;
        let height = group.height - group.margin;
        
        return (
            <>
                <line x1={0} y1={0} y2={0} x2={width} style={{ strokeWidth: '0.2px', stroke:'rgb(255,0,0)' }} />
                <line x1={0} y1={0} x2={0} y2={height} style={{ strokeWidth: '0.2px', stroke:'rgb(255,0,0)' }} />
            </>
        )
    }

    renderSheet(){

        let groups = this.state.groups;
       // let colHeight = 0;
        let svg = groups.map( (group, coli ) => {
            let template = group.items[0];
            let build = this.buildCollectionSVG( group );
            let { height, svg: collectionSVG } = build;
        //    colHeight += height;
            let collectionTranslate = `translate( ${group.x}, ${group.y + 31} )`;
            
            return ( 
                <g className="Collection" transform={ collectionTranslate } key={ coli }>
                    { template.shape === "rectangular" && this.renderTopLeft( group ) }
                    { collectionSVG }
                </g>
            );


        });

        return (
            <Fragment>
                <text dominantBaseline="hanging" textAnchor="start" fontSize="15" style={{ fill: '#000' }} y={ 7 } x="7">
                    { this.props.orderNr } ARK #{ this.props.sheetNr }: { lang.materials[this.state.material] } { this.state.color }
                </text>
                { svg }
            </Fragment>
            );
        
    }

    render( ){
        
        let viewBox = `0 0 ${this.state.width} ${this.state.height}`;

        return (
            <svg
                ref={ this.document }
                width={ this.state.width }
                height={ this.state.height }
                id={this.props.domId}
                xmlns="http://www.w3.org/2000/svg" 
                version="1.2"
                viewBox={ viewBox }
            >               
                <g className="sheet" transform={ 'translate(7,0)' }>
                    { this.renderSheet() }
                </g>
                
            </svg>

        );

    }
}