import React, { Component } from 'react';
import {ReactSVG} from "react-svg";

export class SymbolBlock extends Component{

    constructor( props ){
        super(props);
        this.pc = props.pixelConverter;
    }

       
    render(){
        let b = { ...this.props.data };

        return ( 
            <ReactSVG 
                id={ b.name } 
                wrapper='svg' 
                x={ this.pc.toPixel( b.position.left ) } 
                y={ this.pc.toPixel( b.position.top ) } 
                src={ b.image_src } 
                width={ this.pc.toPixel( b.width ) } 
                height={ this.pc.toPixel( b.height ) } 
            />
        );
    }
}