import React, {Component,Fragment} from 'react';
import {api} from './api';
import * as XLSX from 'xlsx/xlsx.mjs';
const lang = require("../lang/se.json");

export class ExcelImport extends Component{

    constructor(props){
        super(props);
        this.state={
            object: props.object,
            excelData:{},
            items: []
        }
    }

    excelToJson(reader){
        var fileData = reader.result;
        var wb = XLSX.read(fileData, {type : 'binary' });
        var data = {};
        wb.SheetNames.forEach(function(sheetName){
                var rowObj = XLSX.utils.sheet_to_json(wb.Sheets[sheetName],{ defval: '' });
                var rowString = JSON.stringify(rowObj);
                data[sheetName] = rowString;
        });
        
        let items = JSON.parse( data?.Sheet1 ) || [];
        this.setState({ excelData: data, items });
        
    }

    loadFileXLSX(event){
        var input = event.target;
        var reader = new FileReader();
        reader.onload = this.excelToJson.bind(this,reader);
        reader.readAsBinaryString(input.files[0]);
    }

    importToOrder = async () => {
        let items = this.state.items;
        let orderId = this.state.object.id;
        if( items.length > 0 ){
            api.send(
                'Order',`importExcelOrder/import/${orderId}`, 
                { items: JSON.stringify(items) },
                {
                    type: 'POST',
                    success: function( response ){
                        console.log( ' response ', response );
                    }
                })

            
        }
    }

    renderRows(){

        let sheetdata = JSON.parse( this.state.excelData.Sheet1 ) || [];
        let rowHtml = sheetdata.map( (item, index ) => {
            
            return (
                <tr key={index}>
                    <td>
                        <div>{ item.name }</div>
                        <div>{ lang[item.product_type] }</div>
                    </td>
                    <td>
                        <div>{ item.textrow1 }</div>
                        <div>{ item.textrow2 }</div>
                        <div>{ item.textrow3 }</div>
                        <div>{ item.textrow4 }</div>
                        <div>{ item.textrow5 }</div>
                    </td>
                    <td>
                        <div>{ item.width }x{ item.height }mm</div>
                    </td>
                    <td>
                        <div>{ item.hole_quantity > 0 && item.hole_quantity + lang['hole'] }</div>
                    </td>
                    <td>
                        { item.color }
                    </td>
                    <td>
                        { item.quantity }
                    </td>
                </tr>
            );
        });
        
        return rowHtml;

    }

    render(){
        return (
            <Fragment>
                <input type="file" onChange={this.loadFileXLSX.bind(this)}/>

                { this.state.items.length > 0 && <button className="btn btn-success" onClick={ this.importToOrder }>Importera</button> }
                <table className=" customer-order table responsive" id={this.state.object.oid+"_order_rows"}>
                    <thead>
                        <tr>
                        <th>Produkt</th>
                        <th>Rader</th>
                        <th>Storlek</th>
                        <th>Fästmetod</th>
                        <th>Färg</th>
                        <th >Antal</th>
                        </tr>
                    </thead>
                    <tbody id={"order-rows"+this.state.object.oid}>
                        { typeof this.state.excelData.Sheet1 !== 'undefined' && 
                            this.renderRows()
                        }
                    </tbody>
                </table>
            </Fragment>
        );
    }
}
