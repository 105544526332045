import React, { Fragment, Component } from 'react';
import { api } from 'modules/api';
import { sanitizeDomId } from 'utils/objectSanitizer';
var lang = require("../lang/se.json");
//var JSZip = require("jszip");

export class SVGToPDFConverter extends Component{

    constructor(props){
        super(props);
        let sheets = Object.values( { ...props.svgPages } );

        this.state = {
            svgPages: sheets,
            file_quantity: sheets.length,
            pdfFiles: []
        }

    }

    async componentDidMount(){

        let sheets = Object.values( { ...this.state.svgPages } );
        // let contents = [];
        // let zip = new JSZip();
        
         let fd = new FormData();
         sheets.map( (sheet,i) => {
            let j = i+1;
            let color = sanitizeDomId( sheet.color );
            let domId = "s_"+this.props.label+'-'+sheet.material.replaceAll('_','-')+'-'+color+'-'+j;
//             let domId = "s_"+this.props.label+'-'+sheet.material.replaceAll('_','-')+'-'+sheet.color.replaceAll('/','-')+'_'+j;
            let material = lang.materials[sheet.material] || sheet.material;
            // fileName = "s_"+this.props.label+'-'+material.replaceAll('(','-').replaceAll(')','_')+'-'+sheet.color.replaceAll('/','-')+'_'+j;
            let fileName =  domId;
            let base64doc = Buffer.from(decodeURIComponent(encodeURIComponent( document.querySelector('#'+domId).outerHTML )));
            // fd.append( "files[]", domId+'-ark-'+j );
            fd.append( fileName+'-ark-'+j, base64doc );
 //            zip.file( domId+'-ark-'+j+'.svg', base64doc, { base64: true } );
  //            zip.file( domId+'-ark-'+j+'.pdf', base64doc, { binary: true } );
             
             
         });
       //  let orderId = this.state.object.id;
 
 
         const response = await api.requestPDFFiles( fd )
         this.setState( { pdfFiles: response.data } );
        
        // wrapper.append = files;
         
         /*
         console.log( 'zip ', zip );
         zip.generateAsync({type:"blob"})
         .then(function(content) {
             // see FileSaver.js
             fileDownload(content, orderId+".zip");
         });
         */
 
    }


    render(){
        let { files } = this.state;
        if ( this.state.pdfFiles.length <= 0 ){
            return (
                <div className="list-group">
                    <li className="list-group-item list-group-item-info">
                        <div className="d-flex w-100 justify-content-between">
                            <h5 className="mb-1">{ lang.pdf_converter }</h5>
                        </div>
                        
                    </li>
                    <li className="list-group-item list-group-item-action">
                        <i className="fa fa-cog fa-spin fa-1x fa-fw"></i>
                        <span> { lang.processing_files } ...</span>
                    </li>
                </div>
            ); 
        }


        let filesHtml = this.state.pdfFiles.map( (file,i) => {
            return ( 
                <li className="list-group-item list-group-item-action" key={i}>
                    <p className="mb-1">
                        <a href={file.url} download target="_blank"><small>{file.label}</small></a>
                    </p>
                </li>
            );
        });

        return (
            <div className="list-group">
                <li className="list-group-item list-group-item-info">
                    <div className="d-flex w-100 justify-content-between">
                        <h5 className="mb-1">PDF Converter</h5>
                    </div>
                </li>
                { filesHtml }
            </div>
        );

        
    }
}