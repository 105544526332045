import React from 'react';
import { api } from '../api.js';

const lang = require('../../lang/se.json');

export const TemplateView = (props) => {
  const object = props.object;
  

  return (
    <div className="">
      <div>
        
         <div>{ object.name }</div>
         <div>ID #{ object.id }</div>

         <div style={{ float:'left' }}>
           <div>{ lang['label_description'] }</div>
           <div>{ object.description }</div>
           <div>{ lang['label_type'] } <span className="type" id="type"> { object.type } </span> </div>

           
         </div>
       </div>
    </div>

  );

};
