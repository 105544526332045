import Group from "./Group";
import Envelope from "./Envelope";
import Sheet from "./Sheet";
import { svgSheetConfig, outputUnits } from './config.js';
import { getPixelConverter } from './pixelConverter';

export default class MaterialCompound {
    constructor( material, color, hash ){
        this.groups = [];
        this.collections = [];
        this.items = [];
        this.sheets = [];
        this.color = color;
        this.material = material;
        this.hash = hash;    
        this.envelope = svgSheetConfig.collectionConfig;
        this.pc = getPixelConverter( svgSheetConfig.output_method );
    }

    createGroups( collection ){
        
        let {items, direction} = collection;
        let template = items[0];
        let envelope = new Envelope( template );
        let maxQuantity = envelope.getMaxQuantityByTemplate( template );
        
        if( maxQuantity == 0 )
            throw "FEL: Max antal skyltar inom en grupp är 0. skall inte kunna vara mindre än 1";

        if( items.length > maxQuantity ){
           
            while( items.length > 0 ){
                let group = new Group( collection.direction, envelope, template );
                group.setItems( items.splice(0,maxQuantity) );
                this.addGroup( group );
   
            }
        
        } else{ 
            let group = new Group( collection.direction, envelope, template );
            group.setItems( collection.items );
            this.addGroup( group );
  
        }
        
        delete this.collections; 

        return this;
    }

    delegateGroupsToSheet( groups ){
        let maxWidth = this.pc.toPixel( this.envelope.width );
        let maxHeight = this.pc.toPixel( svgSheetConfig.height );
        let calculatedHeight = 0;
        let calcWidth = 0;
        let sheetNr = 0;
        let first = true;
        groups.map( (g, i) => {
            
            calcWidth += g.width;
            if( first ){
                calculatedHeight += g.height
                first = false;
            }
            if( calcWidth > maxWidth ){
                calculatedHeight += g.height;
                calcWidth = g.width;
            }
            if( calculatedHeight > maxHeight ){
                sheetNr++;
                calculatedHeight = g.height;
            }
            g.designatedSheet = sheetNr;
          
        });
       
    }

    addGroup( group ){
        this.groups.push( group );
    }

    addItem( item ){
        this.items.push( item );
    }

    buildGroups(){
        let groups = this.groups;
        // 1. build the groups item grids to get height and width of the group rectangle
        groups.forEach( g => {
            g.buildGrid();
        });
        
        // 2. sort the groups after height;
        //groups = groups.sort( (a, b)  => { return a.height > b.height ? -1 : 1 });
       
        groups = groups.sort( (a, b)  => { 
              
            if( a.height === b.height ){
                return b.items.length - a.items.length;
            }
        
            return a.height > b.height ? -1 : 1 
        });
        
        return this;
    }

    createSVGSheets(){
  
        // 1. CREATE NESSESSARY SVG SHEETS, IF GROUPS DOESN'T FIT ON ONE SHEET EXTRA SHEETS ARE CREATED
        let maxWidth = this.pc.toPixel( svgSheetConfig.width );
        //let maxHeight = this.pc.toPixel( svgSheetConfig.height );
        
        let groups = [ ...this.groups ];
        let sheets = [];
        let sheet;
        
        
        this.delegateGroupsToSheet( groups );
        
        let prevSheet = false;
        
        groups.forEach( group => {

            if( prevSheet !== group.designatedSheet || prevSheet === false ){
                sheet = new Sheet( this.material, this.color );
                sheets.push( sheet );
            }
            sheet.addGroup( group );
            prevSheet = group.designatedSheet;
           // delete groups[index];

        });
        
        // 2.POSITION GROUPS ON SHEETS         
        sheets.forEach( s => {
            let x = 0, y = 0;
            let largestHeight = 0;
            s.groups.forEach( ( g, i ) => {


                if( x + g.width > maxWidth ){
                    x = 0;
                    y += largestHeight;
                    largestHeight = 0;
                } 
                
                g.x = x;
                g.y = y;
              //  s.groups[i] = { ...g };
                x += g.width;
                if( g.height > largestHeight )
                    largestHeight = g.height;
                
            });

            s.calculateUsedArea();
        });

        return sheets;
    }
}