
/* THIS IS FOR THE DIRECTLY PRINTABLE PRODUCTION ORDER */
export const printable = (
"body{ font-family:Verdana; }"+
".hashedProd{ padding:10px; margin:0; border-bottom:1px solid #757575; border-top:1px solid #999; font-size:13px; color:#555; }"+
".hashedProd h4, .hashedProd h5, .hashedProd b { color:black; font-size:13px; margin:4px 4px 4px 0; }"+
".production-block-content { list-style-type: none; margin-bottom:10px; padding-bottom:10px; border-bottom:1px solid #999; }"+
".production-block-list{  margin:10px 0; padding:0; list-style-type:none; }"+
".production-block-list::after{ content: ''; display:block; clear:both;  list-style-type: none; }"+
".production-property { white-space: nowrap; float:left; width:100px; }"+
".production-properties table{ font-size:13px!important; }"+
".production-rows-wrapper { padding:7px; margin:5px 0; border-radius: 5px; border:1px solid #dadada; background:#eaeaea; }"+
".textinfo{ margin-top:10px; }"+
".product-row { border:none; line-height: 1em; margin-bottom:5px; }"+
".sorted-project:last-child{ border-bottom:none; }"+
".sorted-product::after{ display:block; content:''; clear:both; }"+
".sorted-product{ clear:both; margin-bottom:30px; }"+
".sorted-product-prop{ float:left; margin:0 10px 10px 0; }"+
".sorted-product-rows{ list-style-type: none; margin:0; padding:0; clear:both; }"+
".sorted-product-rows::after{ display:block; content:''; clear:both; }"+
".sorted-product-row { list-style-type: none; margin:0 10px 1px 0; padding:0; float:left; }"+
".sorting-content{ font-size:12px; padding:30px 30px 30px 60px!important; clear:both; }"+
".text-danger{ color:red; } "+
"h5{ font-size:15px; }"
);


/* THIS IS FOR THE DOWNLOAD VERSION OF PRINTABLE PRODUCTION ORDER */
export const downloadable = (
".hashedProd{ padding:10px; margin:0; border-bottom:1px solid #757575; border-top:1px solid #999; font-size:13px; color:#555; }"+
".hashedProd h4, .hashedProd h5, .hashedProd b { color:black; font-size:13px; margin:4px 4px 4px 0; }"+
".production-block-content { list-style-type: none; margin-bottom:10px; padding-bottom:10px; border-bottom:1px solid #999; }"+
".production-block-list{  margin:10px 0; padding:0; list-style-type:none; }"+
".production-block-list::after{ content: ''; display:block; clear:both;  list-style-type: none; }"+
".production-property { white-space: nowrap; float:left; }"+
".production-properties::after{ content: ''; display:block; clear:both; }"+
".production-rows-wrapper { padding:7px; margin:5px 0; border-radius: 5px; border:1px solid #dadada; background:#eaeaea; }"+
".textinfo{ margin-top:10px; }"+
".product-row { border:none; line-height: 1em; margin-bottom:5px; }"+
".sorted-project:last-child{ border-bottom:none; }"+
".sorted-product::after{ display:block; content:''; clear:both; }"+
".sorted-product{ clear:both; margin-bottom:30px; }"+
".sorted-product-prop{ float:left; margin:0 10px 10px 0; }"+
".sorted-product-rows{ list-style-type: none; margin:0; padding:0; clear:both; }"+
".sorted-product-rows::after{ display:block; content:''; clear:both; }"+
".sorted-product-row { list-style-type: none; margin:0 10px 1px 0; padding:0; float:left; }"+
".sorting-content{ font-size:13px; padding:30px!important; clear:both; }"+
".text-danger{ color:red; } "+
"h5{ font-size:15px; }"
);
