import { getMaterialAndColorClass, getSVGProductClass } from './config.js';
import MaterialCompound from './MaterialCompound.js';

export default class SVGProduction{
    constructor( productItems ){
        this.items = productItems;
    }

    /**
     * @method separateItems: separates productItems into first, material and color, but also productclasses
     * @returns materialCompound array
     */
    separateItems(){
        
        //1. MATERIAL AND COLOR
        let materialCompound = this.separateByMaterialAndColor( this.items );

        //2. BY SVGBUILDCLASS see /svg/config.js
        this.separateBySVGClass( materialCompound );
        return materialCompound;
        
    }

    /**
     * @method buildProduction: First separates items and then creates EnvelopeGroups and Sheets
     * @returns 
     */
    buildProduction(){
        let svgSheets = [];
        try{
            
            let materialCompound = this.separateItems();
            // CONVERT COLLECTIONS INTO GROUPS AND POSITION GROUP ITEMS INTO GRIDS
            materialCompound.forEach( material => {
                material.collections.forEach( col => {
                    material
                    .createGroups( col )
                    .buildGroups();
                    
                });
                
            });
            // CREATE SVG SHEETS
            
            materialCompound.forEach( material => {
                svgSheets = [ ...svgSheets, ...material.createSVGSheets() ];
            });
    
        } catch( e ){

            console.log( 'ERROR ', e );
        }
        
        return svgSheets;
    }

    separateByMaterialAndColor( productItems ){

        let materials = [];
    
        for(let i in productItems ){
            let item = productItems[i];
            let product = JSON.parse( item.object );
            let quantity = item.quantity;
            if( product.entity === "SogProduct" && product.product_type === 'Rectangular' ){
                
                let hash = getMaterialAndColorClass( product );
                let material = materials.find( mc => mc.hash === hash );
                if( typeof material === 'undefined' ){
                    material = new MaterialCompound( product.material, product.color, hash );
                    materials.push( material );
                }
                for(let j=1; j<=quantity; j++ ){
                    material.items.push( product );
                }
            }
        }
        
        return materials;
           
    }

    separateBySVGClass( materials ){
    
        if( materials.length > 0 ){
            
            materials.forEach( ( material, i ) => {
                   
                material.items.forEach( ( product, index ) => {
                    let hash = getSVGProductClass( product );
                    let direction = product.tape === "foam_tape" ? "horizontal" : "vertical";
                    let collection = material.collections.find( c => c.hash === hash );
    
                    if( typeof collection === 'undefined' ){
    
                        collection = { direction, hash, items: [] };
                        collection.items.push( product );
                        materials[i].collections.push( collection );
    
                    } else {
                        collection.items.push( product );
                    }
                });
    
                delete material.items;
            });
        }
     
    }
    
};


