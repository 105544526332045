import React from 'react';
import {api} from '../api';
var lang = require('../../lang/se.json');

export const TextProductionView = ( props ) => {
  const rows = ( typeof props.object.rows !== 'undefined' ? props.object.rows : [] );
  const maximized = ( props.object.maximized === true ? 'Ja' : 'Nej' );
  const rowsOnSameRow = rows.filter( (r) => r.sameRow === true || r.sameRow === "true" );
  let sameString;
  if( rowsOnSameRow.length > 0 ){
    let i;
    let rs = '';
    for( i in rowsOnSameRow ){
      let nr = rowsOnSameRow[i].rowNr +1;
      rs += ':'+nr+'('+rowsOnSameRow[i].text+'), ';
    }
    sameString = lang['textrows'] +' '+ rs + ' '+ lang['are_on_the_same_row']

  }

  if( typeof props.productType !== 'undefined' && props.productType === 'CableMarkerStainless' ){
    return (
      <React.Fragment>
        { props.children }
        
        <div>Antal rader <b>{ props.object.rows.length }</b></div>
        <div className="textinfo">
          <div><b>Textinfo</b></div>
          {
            (rowsOnSameRow.length > 0 && typeof sameString !== 'undefined' ) &&
            <div style={{ color:'red' }}>{ sameString }</div>
          }
          <div>Storlek: <b>
          {
            rows.length > 0 &&
            rows.map( (row,i) => {
              return ( <span key={i}>{row.size+' / '}</span> );
            })
          }
          </b>
          </div>
          <div>Justering: <b>
          {
            rows.length > 0 &&
            rows.map( (row,i) => {
              return ( <span key={i}>{row.alignment+' / '}</span>);
            })
          }</b>
          </div>

        </div>
      </React.Fragment>
    );

  } else {

  

    return (
      <React.Fragment>
        { props.children }
        {
          ( props.object.vertical_content_alignment != 'm' ) &&
          <div>Vertikal textjustering: <b>{ lang.alignments[props.object.vertical_content_alignment] }</b></div>
        }
        <div>Maximerat: <b>{ maximized }</b></div>
        <div>Antal rader <b>{ props.object.rows.length }</b></div>
        <div className="textinfo">
          <div><b>Textinfo</b></div>
          {
            (rowsOnSameRow.length > 0 && typeof sameString !== 'undefined' ) &&
            <div style={{ color:'red' }}>{ sameString }</div>
          }
          <div>Storlek: <b>
          {
            rows.length > 0 &&
            rows.map( (row,i) => {
              return ( <span key={i}>{row.size+' / '}</span> );
            })
          }
          </b>
          </div>
          <div>Justering: <b>
          {
            rows.length > 0 &&
            rows.map( (row,i) => {
              return ( <span key={i}>{row.alignment+' / '}</span>);
            })
          }</b>
          </div>

        </div>
      </React.Fragment>
    );

  }
};

export const FrameProductionView = ( props ) => {
  const { standard_frame,name } = props.object;
  const maximized = ( props.object.maximized === true ? 'Ja' : 'Nej' );
  const defframe = ( standard_frame === true ? 'Ja' : 'Nej' );
  const blockParts = props.object.name.split('_');
  const blockName = lang[blockParts[0].toLowerCase()]+'_'+blockParts[1];
  let framecontent = null;

  if( standard_frame === true ){
    framecontent = (
      <div>
        <h5>{blockName}</h5>
        <div><b>Standardram:</b> { defframe } </div>
      </div>
    );
  } else {
    framecontent = (
      <div>
        {props.children}
        <div><b>Maximerat:</b> { maximized }</div>
      </div>
    )
  }


  return (
    <React.Fragment>
      { framecontent }
    </React.Fragment>
  );

};

export const HoleProductionView = ( props ) => {

  return (
    <React.Fragment>
      { props.children }

    </React.Fragment>
  );

};

export const SymbolProductionView = ( props ) => {
  const { image_label:label, image_src: file } = props.object;

  return (
    <React.Fragment>
      { props.children }
      {
        <div>
          <div><b>Symbol:</b> { label }</div>
          <img src={ api.root + '/'+file } style={{ maxHeight: 100, width:'auto'}}/>
        </div>
      }
    </React.Fragment>
  );

};

