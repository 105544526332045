import { colorCodes } from "config";
const lang = require("../lang/se.json");
const materialsWithSize = ['cablemarker_plastic','cablemarker_stainless','cablemarker_stainless_emboss','three_layers'];

export default function getOrderMaterials( order ){
    let materials = [];
    order.rows.forEach( item => {
        let prod = JSON.parse( item.object );
        if( prod.entity === "SogProduct" ){

            let bgColor = colorCodes[prod.background_color];
            let tColor = colorCodes[ prod.text_color ];
            let material = lang.materials[ prod.material ];
            let combo = prod.material+bgColor+tColor;
            let size = null;
            let tag = null;

            if( materialsWithSize.indexOf( prod.material ) > -1 ){
                combo += ` ${prod.width}x${prod.height}`;
                size = `${prod.width}x${prod.height}`;
            }

            if( prod.material === 'cablemarker_stainless_emboss' && prod.translation_tag === 'cablemarker_emboss'){
                combo = lang[prod.translation_tag] + combo;
                tag = lang[prod.translation_tag];
            }

            let exists = materials.findIndex( item => {
                return item.combo === combo 
            });
            
            if( materials.length === 0 || exists === -1 ) {
                materials.push({
                    combo,
                    material,
                    bgColor,
                    tColor,
                    color: prod.color,
                    size,
                    tag
                });    
            }

        }

    });

    return materials;

}