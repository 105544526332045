import React, { Component } from 'react';
import { drillBitOffset } from 'svg/config';

export class HoleBlock extends Component{

    constructor( props ){
        super(props);
        this.pc = props.pixelConverter;
    }
       
    render(){
        let b = { ...this.props.data };
        const dbOffset = this.pc.toPixel( drillBitOffset );
        let radius = this.pc.toPixel( b.diameter )/2 - dbOffset;
        
        const props = {
            cx: this.pc.toPixel( b.position.left ) + radius + dbOffset,
            cy: this.pc.toPixel( b.position.top ) + radius + dbOffset,
            r: radius,
            fill: '#ff0'
        };
        
        return ( <circle { ...props }  /> );
    }
}