export const sanitizeVars = ( obj ) => {
    let vars = { ...obj };
    for( let prop in obj ){
        let value = vars[prop];
        if( typeof value === 'undefined' || value == null || value == "null" )
            value = '';
        else if ( value === false || value === "false" )
            value = 0;
        else if ( value === true || value === "true" )
            value = 1;

        vars[prop] = value;

    }

    return vars;

};

export const sanitizeCompoundingVariable = ( value ) => {
   
    if( typeof value === 'undefined' || value == null || value == "null" )
        value = '';
    else if ( value === false || value === "false" )
        value = 0;
    else if ( value === true || value === "true" )
        value = 1;

    return value;
    
};

export const sanitizeDomId = ( value ) => {

    value = value.replace( /å/g, 'a' )
    .replace(/Å/g, 'A' )
    .replace(/ö/g, 'o' )
    .replace(/Ö/g, 'O' )
    .replace(/ä/g, 'a' )
    .replace(/Ä/g, 'A' )
    .replace(/ /g, '-' )
    .replace(/_/g, '-' )
    .replaceAll('/', '' )
    .replaceAll('(', '-' )
    .replaceAll(')', '-' );

    return value;
}