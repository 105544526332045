import React from 'react';
import {Redirect} from 'react-router-dom';


export class Logout extends React.Component{


  render(){

    if( this.props.isAuthenticated === true )
      this.props.handleLogout();

    return <Redirect to="/login" />;

  }
}
