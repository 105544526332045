import React from 'react';
import { api } from '../api';


const lang = require('../../lang/se.json');


export class EmployeeView extends React.Component {
  constructor( props ){
    super( props );
    this.state = {
      loaded : false,
      orders : []
    };
  }

  renderCompanyInfo( object ){

    let {Foretag:Company} = object;
    if( typeof Company === 'undefined' || Company === null ) return <div>{ lang['user_has_no_company'] }</div>

    return (
      <div>
        <h6>{ Company.name }</h6>
        <div><span className="label">{ lang['label_customer_number']}</span> { Company.custnr }</div>
      </div>
    );

  }

  renderPhones( object ){

    let {phoneNumbers} = object;
    if( typeof phoneNumbers === 'undefined' || phoneNumbers.length <= 0 ) return <div>{ lang['user_has_no_phonenumbers'] }</div>

    return (
      <div>
        <h6>{ lang['label_phone_number'] }</h6>
        {
          phoneNumbers.map( (number,i) => {
            return <div key={i}>{ number.riktnr } { number.telnr }</div>;
          })
        }
      </div>
    );

  }

  openWindow (e, module, objectId, titleColumns, argwindowParams, props){

    if( typeof titleColumns === 'undefined' )
      titleColumns = ['name']

    let query = ( (typeof objectId !== 'undefined' && objectId !== null && objectId > 0) ? api[module].read : api[module].new );

    let windowParams = {
      module    : module,
      api_query : query,
      iconSrc   : api[module].iconSrc,
      titleColumns: titleColumns
    };

    if( typeof argwindowParams === 'object' )
      windowParams = Object.assign({},windowParams, argwindowParams );

    return props.openWindow( objectId, windowParams );
  }

  async componentDidMount(){

    const response = await api.send('Employee','employee/orders/'+this.props.object.id, {}, { type:'GET'} );

    if( response.code === 200 ){
      this.setState({
        orders : response.data
      });
    }
  }

  render(){
    const object = this.props.object;
    const orders = this.state.orders;

    return (
      <div className="container">
        <div className="row">
          <div className="col-sm-12 col-md-6 col-lg-4">
            { this.renderCompanyInfo( object ) }
          </div>
          <div className="col-sm-12 col-md-6 col-lg-4">
            { this.renderPhones( object ) }
          </div>
          <div className="col-sm-12 col-md-6 col-lg-4" style={{ maxHeight: 300, overflowY:'auto'}}>
            {

              <React.Fragment>
              <h6>{ lang['label_order_history']}</h6>

                {
                  ( typeof orders !== 'undefined') &&
                  orders.length > 0 &&
                  orders.map( (o,i) => {
                  if( o.deleted === 1 ) return '';
                  return (
                    <div key={i} style={{ marginBottom:3, cursor:'pointer' }} className="link" onClick={(e) => this.openWindow(e,'Order', o.oid, ['oid','ordername'], false, this.props)}>{ o.oid } - { o.date } - { o.ordername }</div>
                  );
                })
                }

              </React.Fragment>
            }
          </div>
        </div>
      </div>
    );

  }

};
