import React from 'react';

export class Address extends React.Component {

  renderInline( address ){
    return(
      <div className={"address"}>
        {  this.props.editable === true &&
          <div className="top-right">
            <button className="btn btn-xs btn-primary" onClick={(e) => this.props.openWindow(e,'Address', address.id, ['name'] )}>
              <i className="fas fa-edit"/>
            </button>
          </div>
        }
        { (address.company !== null && address.company.length > 0 )&& <span className={"Address"+address.id} data-referensprops="company">{ address.company + ', ' }</span> }
        { ( address.company !== null && this.props.companyName !== null ) && <span className={"Address"+address.id} data-referensprops="company">{ this.props.companyName + ', ' } </span> }
        { (address.gata && !address.box ) && <span className={"Address"+address.id} data-referensprops="gata,box">{ address.gata  + ', '}</span> }
        { (address.box && !address.gata ) && <span className={"Address"+address.id} data-referensprops="gata,box">{ address.box  + ', '}</span> }
        { address.address2 && <span className={"Address"+address.id} data-referensprops="address2">{ address.address2 }</span> }
        <span className={"Address"+address.id} data-referensprops="postnr,postort"><span style={{ paddingRight:5 }}>{ address.postnr }</span><span> { address.postort }</span></span>
      </div>
    );
  }

  render(){
    let address = this.props.object;

    if( typeof address === 'undefined' || address === null || address === false ) return <div>{ this.props.lang['no_address_registered']}.</div>;
    if( typeof this.props.displayInline !== 'undefined' ) return this.renderInline( address );

    return(
      <div className={"address"}>
        {
          this.props.editable === true &&
          <div className="top-right">
            <button className="btn btn-xs btn-primary" onClick={(e) => this.props.openWindow(e,'Address', address.id, ['name'] )}>
              <i className="fas fa-edit"/>
            </button>
          </div>
        }
        { ( address.company !== null ) && <div className={"Address"+address.id} data-referensprops="company">{ address.company }</div> }
        { ( address.company === null || ( address.company.length === 0 || address.company === false ) && typeof this.props.companyName !== 'undefined') && <div className={"Address"+address.id} data-referensprops="company">{ this.props.companyName } </div> }

        <div className={"Address"+address.id} data-referensprops="gata,box">{ address.gata } { address.box }</div>
        <div className={"Address"+address.id} data-referensprops="address2">{ address.address2 }</div>
        <div className={"Address"+address.id} data-referensprops="postnr,postort"><span style={{ paddingRight:5 }}>{ address.postnr }</span><span> { address.postort }</span></div>
      </div>
    );
  }


}
