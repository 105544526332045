import React from 'react';
import { api } from '../api';
import { objectStatuses} from '../../config.js';
import { Address } from '../Address';
import { OrderRowTable } from './orderRowTable';
import { OrderTotal } from './orderTotal';
import PropTypes from 'prop-types';
import { ProductionFormModule } from 'modules/ProductionFormModule';
import { SlideInView } from './SlideInView';

const lang = require('../../lang/se.json');

async function getUser( userId ){

  const response = await api.send('Employee', 'employee/'+userId , { group:'plain'}, { type:'GET'} );

  return response.data;
}

export class OrderView extends React.Component {
  constructor( props ){
    super( props );
    this.state = {
      object : props.object,
      openOrder: false,
      loaded : false,
      User: false
    };

  }
    openSlideIn = ( e ) => {
        this.setState({ openOrder: true })
    }

    closeSlide( e ){
        this.setState({ openOrder: false })
    }

  async componentDidMount(){
    let User = false;
    if( this.props.object.userId > 0 && this.props.object.userId !== null ){
      User = await getUser( this.props.object.userId );
    }

    this.setState({ loaded : true, User: User });
  }
  handleStatusChange = async (status) => {
    const order = { ...this.state.object };
    order.status = status;

    const response = await api.saveObject(order.oid, { status : status, invoice_pool: this.state.User.Foretag.doInvoicePool }, 'Order');

    this.setState({ object: order });
    this.props.onStatusChange( order );

  }
  render(){
    const object = this.state.object;
    const User = this.state.User;
   
    if( this.state.loaded === false ) return null;
    else if ( this.state.loaded === true && (typeof User === 'undefined' || User === false) )
      return <div>{ this.props.lang.no_user_found } </div>

    const statusLabel = "badge badge-"+objectStatuses[object.status];
    let deliveryAddress;
    if( object.addressJson !== null && object.addressJson.length > 0 ){
      let delAdr = JSON.parse( object.addressJson );
      deliveryAddress = <Address lang={this.props.lang} object={ delAdr } companyName={ User.Foretag.name }/>
    }

    let invAdr = User.Foretag.addresses.find( a => a.id === User.Foretag.activeAddressId);
    let invoiceAddress = <Address lang={this.props.lang} object={invAdr} companyName={ User.Foretag.name } />

    let orderContact;
    if( object.contact !== null && object.contact.length > 0 ){
      let phone;
      if( object.phone.length > 0 )
        phone = ", "+object.phone;

      orderContact = <div><div className="label">Leveranskontakt</div><div>{ object.contact }{ phone }</div></div>
    }

    return (
        <>
            <div className="container" ref={ this.mainContainer }>
                { 
                !this.state.openOrder && 
                <>
                <div className="row">
                <div className="col-sm-6 col-md-4">
                    <h3>
                    #<span> { object.oid }</span>
                    <div className="btn-group m-l-10">
                        <button type="button" className={"btn btn-"+statusLabel}>{ this.props.lang[object.status] }</button>
                        <button type="button" className={"dropdown-toggle dropdown-toggle-split btn btn-"+statusLabel} data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <i className="fas fa-caret-down" />
                        </button>
                        <div className="dropdown-menu">
                        {
                            object.statuses.map( ( status,i ) => {
                            return <div key={i} onClick={ () => this.handleStatusChange(status) } className="dropdown-item" href="javascript.void();"> { this.props.lang[status] } </div>
                            })
                        }
                        </div>
                    </div>
                    </h3>
                    <div className="label">{ lang.label_last_updated } </div><div><span> {object.time } </span></div>
                    <div className="label">{ lang.table_columns['custnr'] } </div><div><span> { User.Foretag.custnr } </span></div>
                    <div className="label">{ lang.table_columns['ordername'] } </div><div><span> { object.ordername } </span></div>
                    <div className="label">{ lang['label_io_number'] }</div>
                    <div> <span> { object.ionumber } </span></div>

                    <div className="card bg-basic text-dark" style={{ marginTop:5, padding:5 }}>
                    <div style={{ fontWeight:'bold' }}>{ lang.order_options['in_case_of_errors'] }</div>
                    { object.changeallowed === null && <div>{ lang.order_options.no_option }</div> }
                    { object.changeallowed !== null && <div>{ lang.order_options.change_allowed[ object.changeallowed ] }</div> }
                    { object.changeallowed > 0 &&
                        <div>{ lang.order_options.edit_options[ object.editoption ] }</div>
                    }
                    </div>
                </div>
                <div className="col-sm-6 col-md-4">
                    <h6><span> { User.Foretag.name } </span></h6>
                    <h6><span>{ User.fnamn +' '+ User.enamn }  </span></h6>
                    { orderContact }

                </div>
                <div className="col-sm-6 col-md-4">
                    <div style={{ marginBottom:20}}>
                    <div className="label">{ lang['label_delivery_address'] }</div>
                    { object.pickup === true && <span className="text-info">{ lang['will_be_picked_up'] }</span> }
                    { deliveryAddress }
                    </div>
                    <div>
                    <div className="label">{ lang['label_invoice_address'] }</div>
                    { invoiceAddress }

                    </div>
                </div>
                </div>
                <div className="row">
                <div className="col-sm-12">
                    <button
                    className="btn btn-sm btn-info"
                    onClick={(e) => this.openSlideIn(e) }
                    >
                    { lang['open_production_order'] }
                    </button>
                    <OrderRowTable object={ object } />
                </div>
                <div className="col-sm-12">
                    <OrderTotal object={ object } />
                </div>
                </div>
                </>
                }
            </div>

            <div className="container-fluid">
                { 
                    this.state.openOrder && 
                    <SlideInView onClose={ e => this.closeSlide( e ) } maxWidth={1140}>
                        <ProductionFormModule object={this.state.object} objectId={ this.state.object.id } lang={ lang }/>
                    </SlideInView>
                }
            </div>
        </>
    );
  }
};

OrderView.propTypes = {
  object: PropTypes.object.isRequired,

};
//onClick={(e) => this.props.openWindow(object.oid, { formModule: 'ProductionFormModule', module:'Production', titleColumns: [':'+lang['sort_production'],'oid'], } )}