import React, { Component } from 'react';

export class FrameBlock extends Component{

    constructor( props ){
        super(props);
        this.pc = props.pixelConverter;
    }

    setDefaultFrameSize(data){

        let product =  this.props.sign;
        const signWidth   = product.width;
        const signHeight  = product.height;
        const size = (signWidth / signHeight < 1 ? signWidth : signHeight);
        let thickness = (size * 0.04 * 0.5) ;
        let distancetoEdge = (size * 0.04);
        const width = Math.round(signWidth - distancetoEdge * 2 );
        const height = Math.round(signHeight - distancetoEdge * 2);
    
        distancetoEdge = parseFloat(distancetoEdge.toFixed(0));
        thickness = Math.round(thickness*10)/10;
        
        data.thickness = thickness;
        data.width = width;
        data.height = height;
        if(typeof data.position === 'undefined' )
          data.position = {};
        data.position.left = distancetoEdge;
        data.position.top = distancetoEdge;
    
        return data;
    
    }
    
    getShape(){
        let { shape, width, height } = this.props.sign;

        let bShape = {};
        switch( shape ){
            case 'rounded':
                bShape = { rx: height/10, ry: height/10 };
                break;
            case 'oval':
                bShape = { rx: width/2, ry: height/2 };
        }

        return bShape;
    }

    render(){
        let b = { ...this.props.data };
       // let radius = this.pc.toPixel( this.props.data.height )/10;
        
        
        
        let thickness = this.pc.toPixel( b.thickness );
        const props = {
            x: this.pc.toPixel( b.position.left ) + thickness/2,
            y: this.pc.toPixel( b.position.top ) + thickness/2,
            width: this.pc.toPixel( b.width ) - thickness,
            height: this.pc.toPixel( b.height ) - thickness,
            style: { strokeWidth: this.pc.toPixel( b.thickness ), stroke: '#000', fill: 'none' }

        };
        if( b.standard_frame === true || b.standard_frame === "true" ){
            const data = this.setDefaultFrameSize( b );
            props.x = data.position.left;
            props.y = data.position.top;
            props.height = data.height - thickness/2;
            props.width = data.width - thickness/2;
            props.thickness = data.thickness;
            
        }
        let rounded = this.getShape();
       
        return ( 
            <rect { ...props } { ...rounded }/> 
        );
    }
}