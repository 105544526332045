import React,{Fragment} from 'react';
import {uniqId} from '../uniqid.js';
import $ from 'jquery';

const lang = require("../lang/se.json");

export class DropdownSelection extends React.Component{

  constructor( props ){
    super( props );

    let domId = ( typeof props.domId === 'undefined' ? 'dropdown'+uniqId() : props.domId );
    let label = ( typeof this.props.label !== 'undefined' ? this.props.label : "label_"+uniqId() )
    this.state = {
      domId : domId,
      label: label
    };
  }

  filterBy( key, data )
  {
    let items = this.props.selection;

    let i;

    let selection = [];
    for(i in items ){
      let item = items[i];
      if( item[key] === data ){
        selection.push( item );
      }
    }

    return selection;
  }

  componentDidMount(){

    $('#'+this.state.label+'_selection_'+this.props.parent.id).on('click', function( e ){
      $(this).css('height','auto').find('.dropdown-selection').slideToggle();
    });

  }

  render(){
    let label = this.state.label;

    if(
      typeof this.props.selection === 'undefined' ||
      this.props.selection.length === 0 ||
      typeof this.props.parent === 'undefined' ||
      typeof this.props.onSelect === 'undefined'
    ) {
      console.log("WARNING: All required props not defined in DropdownButton Component ");
//      return '';
    }

    let selection = [];
    if( typeof this.props.filter !== 'undefined' && this.props.filter === true ){
      let key = this.props.filterKey;
      let value = this.props.filterValue;
      selection = this.filterBy(key, value );
    } else {
      selection = this.props.selection;
    }

    return (


      <div id={label+"_selection_"+this.props.parent.id} className="selection-dropdown">

        <div className="selection-dropdow-btn">
          <div className="selection-dropdown-l">
            <div className="selection-content">
              <span className="cell">
                <div className="propLabel">{this.props.title}</div>
                <div><small>{this.props.content}</small></div>
              </span>
            </div>
          </div>
          {
            typeof this.props.noEdit === 'undefined' &&
            <div className="selection-dropdown-r">
              <span className="cell"><i className="fas fa-angle-down fa-2x" /></span>
            </div>
          }
        </div>
        {
          typeof this.props.noEdit === 'undefined' &&
          <ul className="dropdown-selection">
          {
            (typeof this.props.addable !== 'undefined' && this.props.addable === true ) &&
            <Fragment>
              <li key="0" className="dropdown-selection-item">
                <i className="dropdown-selection-item-i fas fa-plus-square text-success" />
                <div className="dropdown-selection-item-span" id={"item_"+uniqId()+"_parent_"+this.props.parentId} onClick={ (e) => this.props.onSelect({}, this.props.parentId, true ) } >
                  <div>{ lang['label_create_new_address'] }</div>
                </div>
              </li>
            </Fragment>
          }
          { selection.length > 0 &&
            selection.map( (item, i) => {
                let itemDomId = "item_"+uniqId()+"_parent_"+this.props.parentId;

                let label = item.name;
                if( typeof label === 'undefined' )
                  label = item.label;

                let itemContent = '';

                if( typeof this.props.selectable !== 'undefined' ){
                  itemContent = (
                    <div title={ lang['select_item_as_primary'] } className="dropdown-selection-item-span" id={ itemDomId } onClick={(e) => this.props.onSelect(item, this.props.parentId )}>
                      <div>{ label }</div>
                      <div>{ item.company }</div>
                      <div>{ item.gata }{ item.box }</div>
                    </div>
                  );
                } else {
                  itemContent = (
                    <div className="dropdown-selection-item-span" id={ itemDomId }>
                      <div>{ label }</div>
                      <div>{ item.gata }{ item.box }</div>
                    </div>
                  );

                }


                return (
                  <li key={item.id} className="dropdown-selection-item">
                    {
                      (typeof this.props.editable !== 'undefined' ) &&
                      <i
                        title={ lang['edit_item_in_new_window'] }
                        className={"dropdown-selection-item-i fas fa-edit"}
                        onClick={(e) => this.props.onSelect( item, this.props.parentId, true )}
                      />
                    }
                    { itemContent }
                  </li>
                );

            })
          }
          </ul>
        }
      </div>


/*
      <div className="dropdown dropleft">
        <button
          className="btn btn-default btn-xs dropdown-toggle"
          type="button"
          id={this.state.domId}
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="true"
          style={{ display: 'inline-block', float: 'right'}}
        >
          <i className="fas fa-ellipsis-v"></i>
        </button>
        <ul className="dropdown-menu">
        {
          selection.map( (item, i) => {
              let itemDomId = "item_"+uniqId()+"_parent_"+this.props.parentId;

              let label = item.name;
              if( typeof label === 'undefined' )
                label = item.label;

              if( typeof item.itemType !== 'undefined' ){
                return (
                  <li key={i} className="">
                    <div className="separator separator-horizontal" />
                  </li>
                );

              } else {
                return (
                  <li key={i}>
                    { (typeof item.iconClass !== 'undefined' ) && <i className={"dropDownItem "+item.iconClass+" "+item.class} style={{ marginTop: 5}}/> }
                    <span className="" id={"item_"+itemDomId } onClick={(e) => this.props.onSelect( item, this.props.parentId )}>
                      <div>{ label }</div>
                      <div>{ item.gata }{ item.box }</div>
                    </span>
                  </li>
                );

              }
          })
        }
        </ul>
      </div>
      */
    );
  }
}
