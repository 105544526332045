import React from "react"
export default function PrintSorting(){

    return ( 
        <>
            <div className="bold" style={{ marginTop: 20 }}>Sorteras efter:</div>
            <table>
                
                <tr >
                    <td className="p-1">
                        <svg 
                            width={ 20 }
                            height={ 20 }
                            y={0}
                            x={20}
                            xmlns="http://www.w3.org/2000/svg" 
                            version="1.2"
                        >
                            <rect style={{ x:0, y:0, fill:'none', width:15, height:15, stroke:'black', strokeWidth:1 }} />
                        </svg>
                    </td>
                    <td className="p-1">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="black" height="30" width="20" viewBox="0 0 400 250">
                            <path id="XMLID_27_" d="M15,180h263.787l-49.394,49.394c-5.858,5.857-5.858,15.355,0,21.213C232.322,253.535,236.161,255,240,255  s7.678-1.465,10.606-4.394l75-75c5.858-5.857,5.858-15.355,0-21.213l-75-75c-5.857-5.857-15.355-5.857-21.213,0  c-5.858,5.857-5.858,15.355,0,21.213L278.787,150H15c-8.284,0-15,6.716-15,15S6.716,180,15,180z"/>
                        </svg>
                        ______ per rad    
                    </td>
                </tr>
            
                <tr >
                    <td className="p-1">
                        <svg 
                            width={ 20 }
                            height={ 20 }
                            y={0}
                            x={20}
                            xmlns="http://www.w3.org/2000/svg" 
                            version="1.2"
                        >
                            <rect style={{ x:0, y:0, fill:'none', width:15, height:15, stroke:'black', strokeWidth:1 }} />
                        </svg>
                    </td>
                    <td className="p-1">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="black" transform="rotate(90)" height="30" width="20" viewBox="0 0 400 250">
                            <path id="XMLID_27_" d="M15,180h263.787l-49.394,49.394c-5.858,5.857-5.858,15.355,0,21.213C232.322,253.535,236.161,255,240,255  s7.678-1.465,10.606-4.394l75-75c5.858-5.857,5.858-15.355,0-21.213l-75-75c-5.857-5.857-15.355-5.857-21.213,0  c-5.858,5.857-5.858,15.355,0,21.213L278.787,150H15c-8.284,0-15,6.716-15,15S6.716,180,15,180z"/>
                        </svg>
                            ______ per kolumn    
                    </td>
                </tr>

 
                <tr >
                    <td className="p-1">
                        <svg 
                            width={ 20 }
                            height={ 20 }
                            y={0}
                            x={20}
                            xmlns="http://www.w3.org/2000/svg" 
                            version="1.2"
                        >
                            <rect style={{ x:0, y:0, fill:'none', width:15, height:15, stroke:'black', strokeWidth:1 }} />
                        </svg>
                    </td>
                    <td className="p-1">
                        Ental Sorteras efter  ______________  
                    </td>
                </tr>

            
                <tr >
                <td className="p-1">
                        <svg 
                            width={ 20 }
                            height={ 20 }
                            y={0}
                            x={20}
                            xmlns="http://www.w3.org/2000/svg" 
                            version="1.2"
                        >
                            <rect style={{ x:0, y:0, fill:'none', width:15, height:15, stroke:'black', strokeWidth:1 }} />
                        </svg>
                    </td>
                    <td className="p-1">
                        Ska skäras
                    </td>
                </tr>
            </table>
            
        </>
    )
}