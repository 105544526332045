import React, { Component } from 'react';
import $ from 'jquery';
import DataTable from 'datatables.net'
import {api} from './api';
import {windowManager} from '../windowManager';
import ReactDOM from 'react-dom';
import { accountingNumbers, objectStatuses } from '../config';
import {DataListObject} from './dataListObject';
//import 'datatables.net/css/dataTables.bootstrap4.css';
var lang = require("../lang/se.json");

$.DataTable = DataTable

export class DataList extends Component{

  constructor( props ){
    super(props);
    this.state = { disabledSort:[], hiddenColumns:[], ...props.tableProps };
    this.windowManager = new windowManager();

    this.table = React.createRef();
    this.searchBox = React.createRef();

  }

  componentDidMount(){

    let self = this;

    let data = {
      "idColumn"         : self.state.idColumn,
      "betweenColumn"    : self.state.betweenColumn,
      "searchFields"     : self.state.searchFields,
      "childColumns"     : self.state.childColumns,
      "tableColumns"     : self.state.tableColumns
    };
    if(typeof self.state.start !== 'undefined' && typeof self.state.length !== 'undefined')
      data.between = self.state.start+','+self.state.length;

    let headers = {};
    headers.Authorization = "Bearer "+process.env.REACT_APP_APP_TOKEN;
    headers["x-auth"] = localStorage.getItem('token');

    data = btoa(JSON.stringify(data));
    let table = $('#data-list').DataTable({
      "dom"           : 'tpr',
      "processing"    : true,
      "autoWidth"     : false,
      "pagingType": "numbers",
      "pageLength"    : self.state.length,
      "language": {
        "zeroRecords": lang["no_matching_records_found"]
      },
      "fnDrawCallback": function(oSettings) {

        if ($('#data-list tr').length > self.state.length) {
            $('.dataTables_paginate').show();
        } else {
          $('.dataTables_paginate').hide();
        }
      },
      "responsive"    : {
          breakpoints: [
              { name: 'desktop', width: Infinity },
              { name: 'tablet',  width: 1024 },
              { name: 'fablet',  width: 768 },
              { name: 'phone',   width: 480 }
          ]
      },
      "serverSide"    : true,
      "order"         : [self.state.sortColumn, self.state.sortDirection],
      "lengthMenu"    : [[self.state.length], [self.state.length]],
      "search"        : { "search" : this.props.defaultSearchValue },
      "ajax"          : {
         url: api.api + this.state.api_query,
         type : "GET",
         data: { parameters: data },
         headers: headers,
         error: function( json , error ){

           let errorText = '';
           let message = '';
           let code = json.status;

           if(code === 0 ){
             errorText = lang.errors['api_url_not_found'] + " "+ self.state.api_query+' Code: '+code;
           } else if ( code === 401 ) {

             window.location= window.location.href.split("/").splice(0,-1).join('/')+'/#/logout';

           } else {
             let response = JSON.parse(json.responseText);
             errorText = response.error;
             message = response.message;
             code = json.status;
           }
           if( errorText.length > 0 )
             api.createNotification("error", errorText, 10000 );

           else if( message.length  > 0 )
             api.createNotification("info", message, 5000 );
         },
         complete: function(json,status){
           if( json.status > 0 && typeof json.responseText !== 'undefined' ){
             let response = JSON.parse(json.responseText);

             if( typeof response.notification !== 'undefined' && response.notification === true ){

               api.createNotification( "info", response.text );
             }
             if( typeof response.error !== 'undefined' && response.error !== false ){

               api.createNotification( "error", response.error, 10000 );

             }

             let data = response.data;
             if( typeof self.props.sumColumn !== 'undefined'){

                let i;
                let sum = 0;
                for( i in data ){
                  let row = data[i];
                  let value = parseFloat(row[self.props.sumColumn]);
                  if( isNaN(value) )
                    value = 0;

                    sum += parseFloat(value);
                }


                $('#sum').html(accountingNumbers.se( sum, true ));

             }
           }


         }
      },
      rowCallback: function (row, data, d, e, tr ) {
        $(row).attr('data-id', data[0] ).addClass('table-row');
        let i;
        const hasFloatFix = ( typeof self.state.columnFloatFix !== 'undefined' ? true : false );
        const hasStatusColumn = ( typeof self.state.statusColumn !== 'undefined' ? true : false );
        const articleUsage = ( typeof self.state.articleUsage !== 'undefined' ? true : false );
        const isBool = ( typeof self.state.isBool !== 'undefined' ? true : false );
        const invoicePool = ( typeof self.state.invoicePool !== 'undefined' ? true : false );
        const shouldAbbreviate = ( typeof self.state.abbreviateCols !== 'undefined' ? true : false );
        $('td',row).eq(0).html( '<i class="fas fa-clone openinWindow text-primary" title="'+lang.open_in_window+'" style="cursor: pointer;"></i> '+data[0] ).css({ whiteSpace:'nowrap'});

        for(i in data ){
            let column = data[i];
            if( hasFloatFix ){
              let hasFix = (self.state.columnFloatFix.indexOf(parseInt(i)) > -1 ? true : false );
              if( hasFix === true && typeof self.isNumber( column ) === 'number' ){
                $('td',row).eq( i ).html( accountingNumbers.se( column ) );
              }
            }
            if( invoicePool ){
              let value = (self.state.invoicePool.indexOf(parseInt(i)) > -1 ? true : false );

              if( value === true  ){
                if ( column === true )
                $('td',row).eq( i ).html( '<div class="dot dot-danger" style="margin-top:5px;" title="'+lang.label_do_invoice_pool+'"> </div>' );
                else
                $('td',row).eq( i ).html( '' );
              }
            }
            if( isBool ){
              let value = (self.state.isBool.indexOf(parseInt(i)) > -1 ? true : false );

              if( value === true  ){
                if ( column === true )
                $('td',row).eq( i ).html( '<span class="badge badge-success">'+lang.yes+'</span>' );
                else
                $('td',row).eq( i ).html( '<span class="badge badge-default">'+lang.no+'</span>' );
              }
            }

            if( shouldAbbreviate && ( typeof self.state.abbreviateCols !== 'undefined' && self.state.abbreviateCols.indexOf( i ) > -1)){
              let maxChars = 100;

              if( typeof self.state.maxChars !== 'undefined' )
                maxChars = self.state.maxChars;
              let value = column;
              if( value === null )
                value = '';
              let abbr = value.substring(0, maxChars );
              $('td',row).eq( 3 ).html( abbr );

            }
            if( hasStatusColumn ) {
              if( self.state.statusColumn === parseInt(i)){
                $('td',row).eq(i).html( '<span class="badge badge-'+objectStatuses[column]+'"> '+lang[column]+'</span>');
              }
            }
            if( articleUsage ){

              if( self.state.articleUsageCol === parseInt(i)){
                let updated = lang['last_price_update']+" "+data[4]+'. '+lang['last_used']+' '+data[5];
                $('td',row).eq(i).html( '<div class="dot dot-'+data['status']+'" title="'+updated+'"> </div>');
              }

            }

        }

      }
    });
    var timeout = null;
    $('#searchbox').on('keyup', function(e){

      var input = $(this).val();
      clearTimeout(timeout)
      timeout = setTimeout( () => {
  //      self.setState({ search: input });
        table.search(input).draw();

      }, 400);
      /*
      if( e.keyCode === 13){

        self.setState({ search: $(this).val() });
          table.search($(this).val()).draw();
      }
      */

    });
    $('#data-list').on('click', 'thead > th', function ( e ) {
      let direction = $(this).attr('class').split("_");
      if( typeof direction === 'object' ){
        let sort = direction[1];
        if( typeof sort !== 'undefined' ){

        ( sort !== "sorting" ? sort = direction : sort = 'asc' );
        self.setState({
          sortColumn: $(this).data('colnr'),
          sortDirection: 'desc'
        });
      }
      }
    });
    $('#data-list').on('click', '.table-row>td', function ( e ) {

      let id = $(e.target).closest('tr').data('id');

      if ($(this).index() === 0 ){
        e.stopPropagation();
        e.preventDefault();
        self.props.openWindow( id, self.props.formParams );
      } else {
        self.openObject( id, e );
      }
    });

    $('#filterDate').on('click', function( e ){
      let start = $('#startDate').val();
      let end = $('#endDate').val();

      let searchString = '|filterDate,'+start+','+end+','+self.searchBox.current.value;

      table.search( searchString ).draw();

    });
  }

  doSearch = () => {
    let input = this.searchBox.current.value;
    $('#startDate').val('');
    $('#startDate').val('');
    $('#data-list').DataTable().search(input).draw();
    this.setState({ search: input });
  }

  openObject = async ( objectId, e ) => {
    let params = this.props.formParams;

    if( this.props.openinWindow === true ){
      this.props.openWindow( objectId, params );

    } else {

      let self = this;
      let target = e.target;
      let api_url = params.api_query;
      let data = false;
      if( objectId > 0 && typeof objectId !== 'undefined' && objectId !== null )
        api_url = api_url + "/" + objectId;

      const response = await api.send(
        params.module,
        api_url,
        data,
        {
          type : 'GET'
        }
      );

      let item = response.data;
      const siblings = $(target).closest('tr').siblings('.openedinfo');

      $.each( siblings, function ( i , el ){
        let id = $(el).find('td').data('id');
        ReactDOM.unmountComponentAtNode( document.getElementById( 'openedInfo_'+id ) );
        $(el).closest('tr').remove();
      });
      $(target).parent().siblings().removeClass('open inset bold');
      if($(target).parent().hasClass('open')){
        let next = $(target).closest('tr').next();
        if($(next).hasClass('openedinfo'))
            $(next).remove();
        $(target).parent().removeClass('open inset bold');
      }
      else {
        $(target).parent().addClass('open inset bold');
        let targetTr = $(target).closest('tr');
        $(targetTr).after('<tr class="openedinfo"><td colspan="10" id="openedInfo_'+item.id+'" data-id="'+item.id+'"></td></tr>');

        ReactDOM.render(
          <DataListObject
            object={ item }
            params={params}
            lang={this.props.lang}
            attributes={self.props.prepareEditableAttributes(params.attributes, item)}
            prepareEditableAttributes={self.props.prepareEditableAttributes}
            openWindow={self.props.openWindow}
            appendWindow={self.props.appendWindow}
            onChange={self.props.onChange}
            onObjectChange={self.onObjectChange}
            openInWindow={true}
          />,
          document.getElementById( 'openedInfo_'+item.id )
        );
      }

    }


  }
  isNumber = (data) => {
    return parseFloat( data );
//    return !!(data % 1);
  }
  handleFilterClick = ( e, item ) => {
    this.searchBox.current.value = item.string;
    $('#data-list').DataTable().search(item.string).draw();

    this.setState({ search: item.string });
  }
  handleEmptyFilter = () => {

    this.searchBox.current.value = '';
    this.setState({ search: '' });
    $('#data-list').DataTable().search('').draw();
  }
  onObjectChange = ( object ) => {
    let table = $('#data-list').DataTable();
    table.draw();
  }
  componentDidUpdate(){
    let table = $('#data-list').DataTable();
    table.draw();
  }
  render(){
    let searchActions = this.props.searchActions;
    let {tableColumns, search} = this.state;
    const actionLabel = this.props.searchActionLabel || lang['filter_by'];

    return (
      <div id="data-container" className="container-fluid" >
        {
          ( typeof this.props.children !== 'undefined' ) &&
          this.props.children
        }
        <div className="row">
          <div className="col-sm-12">
            <div className="input-group">
              <input type="text" id="searchbox" ref={this.searchBox} defaultValue={ this.props.defaultSearchValue } aria-controls="data-list" className="form-control" aria-label="Text input with dropdown button" />
              {
                ( typeof search !== 'undefined' && search.length > 0 ) &&
                <span style={{ position:'relative' }}><i className="fas fa-times emtpyFilter" onClick={ this.handleEmptyFilter } style={{ cursor:'pointer', zIndex:3, position: 'absolute', right:15, top:10, color:'#757575' }} /></span>

              }
              <div className="input-group-append m-r-5">
              {
                typeof searchActions !== 'undefined' &&
                <React.Fragment>
                  <button className="btn btn-outline-secondary dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{ actionLabel }</button>
                  <div className="dropdown-menu">
                  {

                    searchActions.map( ( item ,i) => {

                      if( typeof item.type !== 'undefined' && item.type === 'filter' )
                        return <li key={item.label+i} className="dropdown-item" onClick={(e) => this.handleFilterClick( e, item ) }>{ item.label }</li>
                      else
                        return <li key={item.label+i} className="dropdown-item" href={ item.url }>{ item.label }</li>

                    })

                  }
                  </div>
                </React.Fragment>
              }
              {
                typeof searchActions === 'undefined' &&
                <button
                  className="btn btn-outline-secondary dropdown-toggle"
                  type="button"
                  onClick={this.doSearch}
                >
                  { lang.label_search }
                </button>
              }
              </div>
              { typeof this.props.onCreate !== 'undefined' && <button className="btn btn-success m-r-5" href="#" onClick={this.props.onCreate}><i className="fas fa-plus"></i></button> }
              { typeof this.props.canExportPDF !== 'undefined' && <button className="btn btn-outline-secondary m-r-5" href="#"><i className="fas fa-file-pdf"></i></button> }
              { typeof this.props.canExportExcel !== 'undefined' && <button className="btn btn-outline-secondary" href="#"><i className="fas fa-file-excel"></i></button> }
            </div>




          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <table id="data-list" className="table responsive" style={{ marginTop: 10 }} ref={this.table}>
              <thead>
                <tr>
                {
                  tableColumns.map( ( field,i ) => {

                    return <th key={i} data-field={field} data-colnr={i} data-orderable={ this.state.disabledSort.indexOf( i ) > -1 ? "false" : "true" }> { lang.table_columns[field] } </th>

                  })
                }
                </tr>
              </thead>
              <tbody></tbody>
            </table>
          </div>
        </div>
      </div>
    )

  }
}
