import React, { useState } from 'react';

import { tabModules } from './statistics';

export default function Statistics( { lang } ){
 
    const [ activeTab, setActiveTab ] = useState( 0 );

    const tabs = [
        {
            label: lang.affiliate_report,
            module: 'AffiliateReport'
        },
        {
            label: lang.sales,
            module: 'Test'
        }
    ];
   
    const onTabClick = ( index ) => {
        setActiveTab( index );
    }
   
    const TabComponent = tabModules[ tabs[ activeTab ].module ];

    return (
        
        
          <div className="container-fluid">
            <div className="col" style={{ maxWidth: 1000 }}>
              <div id="moduleContent">
                <h3>{ lang.statistics } </h3>
                <nav>
                  <div className="nav nav-tabs" id="nav-tab" role="tablist">
                  
                { 
                    tabs &&
                    tabs.map( (t, index ) => {
                    
                        return(
                            <a
                                className={ activeTab === index ? "nav-item nav-link active" : "nav-item nav-link"}
                                data-toggle="tab"
                                key={ index }
                                href={"#nav-other"}
                                role="tab"
                                aria-controls="nav-home"
                                onClick={ () => onTabClick( index ) }
                            >
                                { t.label }
                            </a>
                        )
                    })
                }


                  </div>
                </nav>
                <div className="tab-content" id="nav-tabContent" style={{ border:'1px solid #dee2e6', borderTop:'none', padding:20, background:'white' }}>
                    <div className="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
                        <TabComponent lang={lang}/>
                    </div>
                </div>
              </div>
            </div>
          </div>
    
       
    )
}