import React from 'react';
import {windowManager} from '../windowManager';
import {api} from './api';
import $ from 'jquery';
import {ObjectForm} from './ObjectForm.jsx';
//import {Address} from './Address.jsx';
//import {ContentBlock,DropdownSelection} from './HtmlComponents.jsx';
//import {Window} from '../window';
import {FileUpload} from './fileUpload';
import {stickerFormAttributes} from './StickerModule';

//const lang = require("../lang/se.json");

export class StickerFormModule extends React.Component{

  constructor( props ){
    super( props )

    this.state = {
      object : props.object,
      files: [{
        source: 'index.html',
        options: {
            type: 'local'
        }
      }]
    };

    this.wm = new windowManager();


  }

  updateWindowTitle = ( e ) => {

    this.props.updateTitle( e.target.value );

  }

  onChange = ( object, changes ) => {
    this.setState({ object : object });
    this.props.onChange( object, changes );
  }

  async fetchObject(){
    let self = this;
//    let url = api.api+api.Sticker.read+'/'+this.props.objectId;
//    let order = false;

    await api.send(
      'Sticker',
      'stickers/'+this.props.objectId,
      false,
      {
        type: 'GET',
        success : function( response ){

          let object = response.data;
          let time = new Date().getTime();
          self.setState({ isUpdated:  time, object: object });
          self.props.updateTitle( self.props.makeWindowTitle( object , ['name']) );
        }
      }
    );

  }

  async componentDidMount(){
    await this.fetchObject();
  }

  post = async (formData) => {

      const response = await api.send(
        'StickerModule',
        api.Sticker.upload + '/' + this.state.object.id,
        formData,
        {
          processData: false,
          contentType: false,
          crossDomain: true,
          type: "POST"
        }
      );

      if( typeof response.object === 'object' && response.object !== null )
        this.onChange( response.object, response.object )

  }

  handleDelete = () => {
    $("#"+this.props.windowId).find('.close').click();
    window.location.reload(true);

  }

  render( ){

    let object = this.state.object;
    if( typeof object === 'undefined' || object === false ) return null;

    let attributes = this.props.prepareEditableAttributes(stickerFormAttributes, object );
    const imageSrc = api.root + object.imagelink;

    return (
      <div className="container">
        <div className="row">
          <div className="col-md-7">
            <ObjectForm
              objectId={ object.id }
              parent={this.props.parent}
              attributes={attributes}
              tabIndex={this.props.tabIndex}
              savePath={ api.Sticker.save }
              object={object}
              onChange={this.onChange}
              updateState={this.props.updateState}
              module="Sticker"
              onDelete={this.handleDelete}
            />
          </div>
          <div className="col-md-5 windowInfo">
            <div className="card">
              <img className="card-img-top" src={ imageSrc } style={{ width: '100%', maxHeight:300, objectFit: 'cover' }} alt={"image thumb "+imageSrc } />
              <FileUpload
                onSubmit={this.post}
                id={ "fileForm_"+ object.id }
                apiUrl={api.api + api.Sticker.upload + '/'+ object.id }
              />
            </div>

          </div>
        </div>
      </div>
    );

  }
};
