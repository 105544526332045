import React from 'react';
import styled from 'styled-components';

const Tab = styled.button`
    whiteSpace: nowrap;
    ${({ active }) =>
    active &&
    `
        border-bottom: 2px solid black;
        background-color: #eaeaea!important;
        opacity: 1;
    `}
`;

const TabContent = styled.div`
    display: none;
`;

export class TabGroup extends React.Component{
    
    constructor( props ){
        super(props);

        this.state = {
            tabs: props.tabs,
            active: props.tabs[0]
        }
    }

    setActive( t ){
        this.setState( { active: t });
    }

    render() {
        let { tabs, active } = this.state;

        return (
          <>
            <nav  >

                <div className="nav nav-tabs" id="nav-tab" role="tablist" style={ { overflowX:'auto', overflowY:'hidden', flexWrap:'nowrap', whiteSpace: 'nowrap' } }>
                { tabs.map( (t,i) => (
                    <Tab 
                        className={ `nav-link` }
                        key={i}
                        active={active.label === t.label} 
                        id={"#nav-tab-"+i } 
                        data-toggle="tab" 
                        data-target={"#nav"+i } 
                        aria-controls="nav-home" 
                        aria-selected="true"
                        onClick={() => this.setActive(t)}
                    >
                        { t.label }
                    </Tab>
                ))}
                </div>
            </nav>
            <div className="tab-content" id="nav-tabContent" style={{ overflow:'hidden', height: 500 }}>
                { typeof active !== 'undefined' ? active.svg : "No products that can be made to SVG" }
                { tabs.map( (t,i) => (
                    <TabContent 
                        active={active.label === t.label} 
                        key={i}
                        id={"#nav-tab-"+i } 
                        data-toggle="tab" 
                        data-target={"#nav"+i } 
                        aria-controls="nav-home" 
                        aria-selected="true"
                        
                    >
                        { t.content }
                    </TabContent>
                ))}
            </div>

          </>
        );
    }
}