import React from 'react';
import { api } from '../api';
//import {accountingNumbers} from '../../config.js';
const lang = require('../../lang/se.json');
export class CustomerGroupView extends React.Component{

  constructor( props ){
    super( props );

    this.state = {
      customers : false
    };
  }

  async componentDidMount(){

    const response = await api.send( 'CustomerGroup', 'customerGroup/children/'+ this.props.object.id , false , { type:'GET'})
    if( response.code === 200 ){
      this.setState({
        customers : response.data
      });
    }
  }

  render(){
    const {customers} = this.state;
    const object = this.props.object;
    if( customers === false ) return null;

    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-6">
            <h4>{ lang.label_discount }: { object.discount }% </h4>

          </div>
          <div className="col-md-6">
            <h6>{ lang['is_used_by'] }</h6>
            {
              customers.length > 0 &&
              customers.map( (customer,i) => {
                return (
                  <div key={i}>
                    <span
                      className="link"
                      onClick={(e) => openWindow(e,'Customer', customer.id, ['name'], false, this.props)}
                    >
                      { customer.custnr } - { customer.name }
                    </span>
                  </div>

                );
              })
            }
          </div>
        </div>
      </div>

    );
  }
};

function openWindow (e, module, objectId, titleColumns, argwindowParams, props){

  if( typeof titleColumns === 'undefined' )
    titleColumns = ['name']

  let query = ( (typeof objectId !== 'undefined' && objectId !== null && objectId > 0) ? api.Customer.read : api.Customer.new );

  let windowParams = {
    module    : 'Customer',
    api_query : query,
    iconSrc   : api.Customer.iconSrc,
    titleColumns: titleColumns
  };
  if( typeof argwindowParams === 'object' )
    windowParams = Object.assign({},windowParams, argwindowParams );

  return props.openWindow( objectId, windowParams );
}
