import React, { Component } from 'react';
//import $ from 'jquery';
import {DataList} from './DataList.jsx'
import {api} from './api';

const lang = require("../lang/se.json");


export const templateFormAttributes = {
  "name"            : { "type" : "text", "label" : "label_name" },
  "type"            : { "type" : "textarea", "label" : "label_product_type" },
  "description"     : { "type" : "textarea", "label" : "label_description" },
  "creatorId"      : { "type" : "text", "label" : "label_creator", disabled: true },
  "defaultTemplate" : { "type" : "checkbox", "label" : "label_default_product", "helper": { text: "En standardprodukt visas i listan av produktyper i produktväljaren i skyltmotorn."} },
  "onlySharedVisibility": { type: "checkbox", label: "label_only_shared_visibility", helper: { text: "Visas i produktväljaren för alla utdelade personer." } },
  "object"          : { "type" : "textarea", "label": "label_object", disabled: true },
  "data"            : { "type" : "textarea", "label": "label_old_template", disabled: true },
  "imagelink"       : { "type" : "text", "label" : "label_image_link", "disabled" : true , "read_only" : true  },
  "customRuleset"   : { "type" : "textarea", "label": "label_custom_ruleset", disabled: true },
};

export const templateFormParams = {
  module    : 'Template',
  formModule: 'TemplateFormModule',
  view      : 'TemplateView',
  iconSrc   : api.Template.iconSrc,
  api_query : api.Template.read,
  titleColumns: ['name'],
  attributes : templateFormAttributes
};

export class TemplateModule extends Component{

  constructor( props ){
    super(props);
    this.state = {
      tableProps    : {
        idColumn        : 'id',
        betweenColumn   : 'id',
        tableColumns  : ['id','name','description','type','defaultTemplate'],
        isBool        : [4],
        searchFields  : ['id','name','type','description'],
        sortColumn    : 1,
        sortDirection : 'asc',
        itemsPerPage  : 100,
        api_query     : api.Template.dataList,
        start         : 0,
        length        : 100

      },
      name            : 'templates',
      module_query  : window.location.pathname.substring(1).split('/'),

    };

  }

  handleCreate = async (e) => {

    const response = await api.send( 'Template', api.Template.new );
    this.props.openWindow( response.data.id, templateFormParams );
  }

  openWindow = ( objectId ) => {
    let params = { ...templateFormParams, orgWidth: 700, orgHeight: 440 };
    this.props.openWindow( objectId, params );
  }

  render(){
    
    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12" style={{ maxWidth:1200 }}>
            <div id="moduleContent">
              <h3> { lang[this.state.name] } </h3>
              <DataList
                onCreate={this.handleCreate}
                tableProps={ this.state.tableProps }
                openWindow={this.openWindow}
                prepareEditableAttributes={this.props.prepareEditableAttributes}
                formParams={templateFormParams}
                onChange={this.props.onChange}
              />
            </div>
          </div>
        </div>
      </div>

    )

  }
}
