import React, {Component} from 'react';
import $ from 'jquery';
import {windowManager} from './windowManager';
import {windowModules} from './config.js';
import {api} from './modules/api';
import {isPhone,getBrowsingDevice} from './utils/mediaDevice';
//import { objectStore } from './container';
require('jquery-ui');
require('jquery-ui/ui/widgets/draggable.js');
require('jquery-ui/ui/widgets/resizable.js');

const lang = require("./lang/se.json");

export class Window extends Component{

  constructor( props ){
    super( props );

    this.wm = new windowManager();
    let windowId = props.windowId;
    if( typeof windowId === 'undefined' )
        windowId = this.wm.generateId();

    this.state = {
      ...props.window,
      hasChanges : false

    };
    this.window = React.createRef(null);
    this.updateTitle = this.updateTitle.bind( this );
    this.openWindow = this.openWindow.bind( this );
  }

  updateTitle( title ){
    this.setState({ title : title });
  }

  onObjectChange = ( object, changes ) => {
    let hasChanges = ( changes.length > 0 ? true : false );
    let settings = this.wm.getWindow( this.state.windowId );

    this.wm.setWindow( settings, this.state.windowId, { hasChanges: hasChanges } );
    this.setState({
      object: object,
      hasChanges : hasChanges
    });

    this.props.updateState();
  }
  openWindow(e, module, objectId, titleColumns, argwindowParams){

    if( typeof titleColumns === 'undefined' )
      titleColumns = ['name']

    let query = ( (typeof objectId !== 'undefined' && objectId !== null && objectId > 0) ? api[module].read : api[module].new );

    let windowParams = {
      module    : module,
      api_query : query,
      iconSrc   : api[module].iconSrc,
      titleColumns: titleColumns
    };

    if( typeof argwindowParams === 'object' )
      windowParams = Object.assign({},windowParams, argwindowParams );

    this.props.openWindow( objectId, windowParams );
  }

  reloadObject = async (e, objectId ) => {

    let self = this;
    const { windowId, module } = this.props.window;

    let url = ( (objectId === null || typeof objectId === 'undefined' ) ? api[module].new : api[module].read + '/'+objectId );

    if( ( $(e.target).hasClass('fa-sync') ) || $(e.target).attr('class') === 'window-panel-title' ) {
      let response = await api.send(
        module,
        url,
        false,
        { type: 'GET' }
      );
      self.setState({ object: response.data, isLoaded: true });


    }
    this.wm.focusWindow( windowId );
  }
  shouldComponentUpdate( arg1, arg2 ){

    if(JSON.stringify(arg1) === JSON.stringify(arg2))
      return false;
    return true;
  }

  closeWindow = () => {
    $('#'+this.props.window.windowId ).find('.close').click();
  }
  componentDidMount(){

    let self = this;
    const { windowId } = this.props.window;
    const element = $('#'+windowId);
    let bodyH = $(window).height() - 50;
    let bodyW = $(window).width() - 50;
    $(element).resizable({
      handles: 'n, e, w, s,se,sw,ne,nw',
      resize: function(e,ui){
        let dialog = ui.helper[0];
        let width = parseInt( dialog.style.width );
        let left =  parseInt( dialog.style.left );
        let top =  parseInt( dialog.style.top );
        let winSettings = self.wm.getWindow( $(this).attr('id') );

        if( left > bodyW )
            left = bodyW;
        if( left < - width )
            left = -width + 50;
        if( top < 50 )
            top = 50;
        if( top > bodyH )
            top = bodyH;

        $(this).css({ left, top });

        self.wm.updateMeasurements( winSettings );
    //      self.wm.setBodyHeight( winSettings );
      },
      stop: function(e, element){

        let bodyH = $(window).height() - 20;
        let bodyW = $(window).width() - 20;
        let { left, top } = element.position;

        if( $(this).width() > bodyW )
          $(this).width(bodyW - left );

        if( $(this).height() > bodyH )
          $(this).height(bodyH - top);


        let winSettings = self.wm.getWindow( $(this).attr('id') );
        self.wm.updateMeasurements( winSettings );

      }
    }).draggable({

        handle: '.window-panel',
        scroll: false,
        start: function( e ){
            self.wm.focusWindow( $(this).attr('id') );
        },
        stop: function( e,ui ){

            let dialog = ui.helper[0];
            let width = parseInt( dialog.style.width );
            let left =  parseInt( dialog.style.left );
            let top =  parseInt( dialog.style.top );
            let winSettings = self.wm.getWindow( $(this).attr('id') );

            if( left > bodyW )
                left = bodyW;
            if( left < - width )
                left = -width + 50;
            if( top < 50 )
                top = 50;
            if( top > bodyH )
                top = bodyH;

            $(this).css({ left, top });

            
            self.wm.updateMeasurements( winSettings );
        }
    });

    // STACKING WINDOWS

    $(element).find('.stack').on('click', function( e ){
      self.wm.stackWindows();
    });

    $(element).find('.compressX').on('click', function( e ){
      self.wm.compressWindows();
    });

    // MAXIMIZE A WINDOW

    $(element).find('.maximize').on('click', function( e ){

      self.wm.maximize(this, $(this).closest('.window').attr('id') );

    });

    // PIN A WINDOW

    $(element).find('.pin').on('click', function( e ){
      self.wm.pinWindow( $(this).closest('.window').attr('id') );
    });

    // COMPRESS A WINDOW

    $(element).find('.compress').on('click', function( e ){
      self.wm.compress( $(this).closest('.window').attr('id') );
    });

    // CLOSE WINDOW

    $(element).find('.close').on('click', function( e ){
      let settings = self.wm.getWindow( $(element).attr('id') );
      self.wm.removePanelIcon( settings );
      let window = $(this).closest('.window');

      self.setState({ openWindows: self.wm.getWindows(), hasChanges: false });
      self.wm.removeWindow( $(window).attr('id') );
      self.props.updateState( );

    });

    // MINIMIZE WINDOW

    $(element).find('.minimize').on('click', function (e ){

      let windo = $(this).closest('.window');
      let width = $(windo).width()-150;
      let height = $(windo).height()-150;

      $(windo).animate({ width: width, height:height, opacity:0, top:5, left:5 }, 200, function(){
        $(this).removeClass('stacked maximized openWindow').addClass('minimized').css('display','none');

      });

    });

    this.wm.focusWindow( windowId );
    this.wm.setBodyHeight( this.wm.getWindow( windowId) );
  }


  render(){

    let {objectId} = this.state;

//    if( typeof object === 'undefined' || object === false || JSON.stringify(object).length === 2 ) return null;
    
    const FormModule = windowModules[ this.state.module+'FormModule' ];
    
    const {orgHeight,orgWidth,orgTop,orgLeft} = this.props.window;
    let style = {};

    if( typeof this.props.window === 'object' ){
      style = {
        width       : orgWidth,
        height      : orgHeight,
        left        : orgLeft,
        top         : orgTop
      };

    }
    const device = getBrowsingDevice();
    if( isPhone( device ) ){
        style = {
            width: '100%',
            height: device.screen.height - 46,
            left: 0,
            top: 46
        };
    }
  
    let pinClass = "fas fa-map-pin pin ";
    let windowClass = "window openWindow ";
    if( this.props.window.pinned === true ){
      pinClass += "pinnedButton";
      windowClass += "pinned";

    }
    return (
      <div ref={ this.window } id={this.props.window.windowId} style={ style } className={windowClass}  onClick={(e) => this.reloadObject(e,objectId)}>
        <div className="window-panel">
          <img className="iconTitle" src={ this.state.iconSrc } alt={ this.state.iconSrc }/>
          <h3 className="window-panel-title">{ this.state.title } { this.props.hasChanges && <img alt={ lang['there_are_unsaved_changes']} title={ lang['there_are_unsaved_changes'] } className="icon small"  src={"assets/images/icons/software-update-urgent-symbolic.svg"} /> }</h3>

          <div className="windowActions">
            <button className="btn btn-xs btn-custom"><i title={ lang.pin_window } className={pinClass}></i></button>
            <button className="btn btn-xs btn-custom"><i className="fas fa-window-minimize minimize" title={ lang.minimize_window }></i></button>
            <button className="btn btn-xs btn-custom">
              <i className="fas fa-window-maximize maximize" title={ lang.maximize_window }></i>
              <i className="fas fa-compress compress" title={ lang.compress_window } style={{display:'none'}}></i>
            </button>
            <button className="btn btn-xs btn-custom">
              <i className="fas fa-columns stack" title="{%trans%} stack_all_windows {%endtrans%}"></i>
              <i className="fas fa-compress-arrows-alt compressX" title={lang.compress_all_windows} style={{display:'none'}}></i>
            </button>
            <button className="btn btn-xs btn-custom"><i className="fa fa-times close" title={ lang.close_window }></i></button>
          </div>
        </div>
        <div className="window-body">

          <FormModule
            prepareEditableAttributes={this.props.prepareEditableAttributes}
            objectId={objectId}
            parent={this.props.parent}
            activeObjects={this.props.activeObjects}
            lang={this.props.lang}
            tabIndex={this.props.tabIndex}
            updateState={this.props.updateState}
            openWindow={this.openWindow}
            windowId={this.state.windowId}
            onChange={this.onObjectChange}
            closeWindow={this.closeWindow}
            updateTitle={this.updateTitle}
            makeWindowTitle={this.props.makeWindowTitle}

          />
        </div>
        <div className="window-footer">
        </div>
      </div>
    )
  }
};
