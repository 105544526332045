import React from 'react';
import { api } from './modules/api';


export class Login extends React.Component{

  constructor( props ){
    super( props );
    this.state = {
      user: false,
      isAuthenticated: false
    }
    this.username = React.createRef();
    this.password = React.createRef();
  }

  handleLogin = async (e) => {
    e.preventDefault();
 
    const username = this.username.current.value;
    const password = this.password.current.value;

    let response = await api.login( [username,password] );

    if( response.code === 200 ){
//      window.location.href = window.location.href.split("/").slice(0,-1).join('/')+'/orders';
      this.props.authenticate( response.data, response.token );
    }
    return false;
  }

  render(){

    return (
      <form id="loginWrapper">
      <h2>{ this.props.lang['label_login']}</h2>
          <div style={{ margin:'50px auto'}}>
            <img src="/assets/images/sog_logo.png" alt='Skylt och Gravyr logo'/>
          </div>

          <div className="input-group mb-3">
            <div className="input-group-prepend">
              <span className="input-group-text" id="basic-addon1">{ this.props.lang['label_username']}</span>
            </div>
            <input type="text" className="form-control" ref={this.username} placeholder={ this.props.lang['label_username']} aria-label="Username" aria-describedby="basic-addon1" />
          </div>
          <div className="input-group mb-3">
            <div className="input-group-prepend">
              <span className="input-group-text" id="basic-addon2">{ this.props.lang['label_password']}</span>
            </div>
            <input type="password" className="form-control" ref={this.password} placeholder={ this.props.lang['label_password']}  aria-label="password" aria-describedby="basic-addon2" />
          </div>
          <button className="btn btn-success" onClick={ this.handleLogin }>{ this.props.lang['label_send']}</button>
      </form>
    );
  }
}
