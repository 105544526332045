export const round = ( value, decimals, halfPoint, forceDecimals ) => {
  value = parseFloat( value );
  let rounded;
  if( isNaN( value ) )
    return false;

  if( typeof decimals === 'undefined' )
    decimals = 0;

  if( typeof halfPoint === 'boolean' && halfPoint === true )
    rounded = roundWithHalfValue( value, decimals );
  else
    rounded = roundToDecimals( value, decimals );


  if( typeof forceDecimals !== 'undefined' )
    return rounded.toFixed(decimals);

  return rounded;

};

// rounds to decimals .5
const roundWithHalfValue = ( value, decimals ) => {
  //return Math.floor(value*2) / 2;

  let integer = Math.floor(value);
  let rest = parseFloat( (value % 1).toFixed( decimals+1 ) );

  if( rest < 0.25 ){
    return parseInt( integer );
  } else if ( rest > 0.25 && rest < 0.75 ){
    return parseFloat( integer + 0.5 );
  } else {
    return parseInt( integer + 1 );
  }



}
// rounds to specified number of decimals
const roundToDecimals = ( value, decimals ) => {

  let float = ( decimals > 0 ? Math.pow(10,decimals) : 1 );

  let result = Math.round(value*float)/float;

  return result;

}
