import React from 'react';
import $ from 'jquery';
import { api } from './api';

const lang = require('../lang/se.json');
const extensions = ["jpg","jpeg","svg","gif"];

export class FileUpload extends React.Component{

  constructor( props ){
    super(props);
    this.fileInput = React.createRef();
    this.browseHolder = React.createRef();
    this.fileForm = React.createRef();
  }
  handleSubmit = ( e ) => {
    e.preventDefault();

    let files = this.fileInput.current.files;
    let formData = new FormData( );
    let errors = false;

    for(var i = 0; i < files.length; i++){

      let file = files.item(i);
      let ext = file.name.split('.')[1].toLowerCase();
      if (typeof file.type === 'undefined' || !file.type.match('image.*') || typeof file.size === 'undefined' || extensions.indexOf( ext ) === -1 ) {
        errors = true;
        continue;
      }
      formData.append('files', file, file.name);
    }

    if( errors === false ){

      this.props.onSubmit( formData );

    } else {

      alert( lang['file_extension_not_supported'] +'! '+ lang['extensions_supported']+''+extensions.join(' '));
      console.log("Upload error: ", files, " Supporded extensions ", extensions );

    }


  }
  handleChange = ( e ) =>  {
    let input = e.target;
    let ext = input.value.split('.')[1];

    if( extensions.indexOf( ext ) === -1 ){
      alert( lang['file_extension_not_supported'] +'! '+ lang['extensions_supported']+''+extensions.join(' '));
    } else {
      $(this.browseHolder.current).html( e.target.value ).parent().addClass('alert alert-info');
    }

  }



  render() {
    const {apiUrl} = this.props;
    return(

      <div className="card-body">
        <form
          ref={this.fileForm}
          onSubmit={ this.handleSubmit }
          action={ apiUrl }
          method="post"
          encType="multipart/form-data"
        >
          <div className="input-group mb-3">
            <div className="file">
              <input type="file" ref={this.fileInput} name="fileToUpload" onChange={ this.handleChange } className="custom-file-input" />
              <label className="custom-file-label">{ lang['browse'] }</label>
            </div>

          </div>
          <div>
            Vald fil: <span  ref={this.browseHolder} />
          </div>
          <input className="btn btn-primary btn-sm" type="submit" value={ lang['upload_image'] } name="submit"/>

          { 
            this.props.file && 
            <button className="btn btn-danger btn-sm pull-right" onClick={ (e) => this.props.onDelete( e, this.props.file ) }>{ lang["label_delete"] }</button>

          }
        </form>
      </div>
    )
  }
};
