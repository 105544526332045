import React from 'react';
import { api } from '../api';
import { Address } from '../Address';

export class CustomerView extends React.Component {

  constructor( props ){
    super( props );
    this.state = {
      loaded : false,
      users : [],
      referrals: []
    };
  }

  trucyString ( value ){
    return ( ( value === 1 || value === true || value === "true" ) ? this.props.lang['yes'] : this.props.lang['no'] );
  }

  renderEmployees( object, props ){
    let emps;
    let {users:employees} = this.state;

    if( typeof employees !== 'undefined' && employees.length > 0 ){
      emps = employees.map( emp => {
        if ( emp.deleted === true ) return false;
        return (
           <div>
            <span
              className="link"
              onClick={(e) => this.openWindow(e,'Employee', emp.id, ['fnamn','enamn'], false, props)}
            >
              { emp.fnamn } { emp.enamn }
            </span>
          </div>
        );
      })
    } else {
      return <div>{ this.props.lang['customer_has_no_employees'] }</div>;
    }

    return emps;
  }

  openWindow (e, module, objectId, titleColumns, argwindowParams, props){

    if( typeof titleColumns === 'undefined' )
      titleColumns = ['name']

    let query = ( (typeof objectId !== 'undefined' && objectId !== null && objectId > 0) ? api[module].read : api[module].new );

    let windowParams = {
      module    : module,
      api_query : query,
      iconSrc   : api[module].iconSrc,
      titleColumns: titleColumns
    };

    if( typeof argwindowParams === 'object' )
      windowParams = Object.assign({},windowParams, argwindowParams );

    return props.openWindow( objectId, windowParams );
  }

  async componentDidMount(){

    const response = await api.send('Company', 'company/listEmployees/'+this.props.object.id, {}, { type:'GET'});
    const res = await api.send( 'Company', 'company/referrals/'+this.props.object.id, {} , { type: 'GET' } );

    if( response.code === 200  ){

      this.setState({
        users: response.data,
        referrals: res.data,
        loaded: true
      });
    }

  }
  render(){
    const object = this.props.object;
    const referrals = this.state.referrals;

    let customerGroup;

    if( this.state.loaded === false ) return null;

    if( typeof object.CustomerGroup === 'object' && object.CustomerGroup !== null )
      customerGroup = object.CustomerGroup.name

    let invAdr = object.addresses.find( a => a.id === object.activeAddressId);
    let invoiceAddress = <Address lang={this.props.lang} object={invAdr} companyName={ object.name }/>

    return (
      <div className="container">
        <div className="row">
            <div className="col-sm-12 col-md-6 col-lg-4">
                <h6>{ this.props.lang['label_customer_information'] } </h6>
                <div><span className="label">{ this.props.lang['label_payment_terms'] }</span> {object.terms} </div>
                <div><span className="label">{ this.props.lang['label_email_invoice'] }</span> {object.email} </div>
                <div><span className="label">{ this.props.lang['label_customer_group'] +' (#'+object.customergroupid +')'}</span> {customerGroup } </div>
                <div><span className="label">{ this.props.lang['label_do_invoice_pool'] }</span> { this.trucyString(object.doInvoicePool) } </div>
                <div><span className="label">{ this.props.lang['label_use_minimum_value'] }</span> { this.trucyString(object.useminimumvalue) } </div>
                <h6 style={{ marginTop: 10 }}>{ this.props.lang['label_invoice_address'] } </h6>
                { invoiceAddress }
            </div>
            <div className="col-sm-12 col-md-6 col-lg-4">
                <h6>{ this.props.lang['label_employees'] } </h6>
                { this.renderEmployees( object, this.props ) }
            </div>
            <div className="col-sm-12 col-md-6 col-lg-4">
                {
                    referrals.length > 0 &&
                    <>
                        <h6>{ this.props.lang.label_referred_companies }</h6>
                        <table className="border-none">
                        {
                            referrals.map( (c,i) => {
                                return (
                                    <tr key={i}>
                                        <td >{ c.id }</td>
                                        <td >{ c.custnr }</td>
                                        <td >{ c.name }</td>
                                        
                                    </tr>
                                )
                            })
                        }
                        </table>
                    </>
                }
            </div>
        </div>
      </div>
    );

  }

};
