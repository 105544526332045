import React from 'react';
import {windowManager} from '../windowManager';
import {api} from './api';
import $ from 'jquery';
import {ObjectForm} from './ObjectForm.jsx';
//import {FileUpload} from './fileUpload';
//import {quoteFormAttributes} from './QuoteModule';
import {accountingNumbers} from '../config';
import {Dimensions, AttachMethod} from './priceQuoteOptions';
import {Block} from './block';
import Select from 'react-select';



export class QuoteFormModule extends React.Component{

  constructor( props ){
    super( props )

    this.state = {
      object : props.object,
      activeType: 'rectangular',
      product: false,
      price: 0
    };

    this.wm = new windowManager();
    this.addon = React.createRef();
    this.extra = React.createRef();
    this.quantity = React.createRef();
    this.discount = React.createRef();

  }

  updateWindowTitle = ( e ) => {

    this.props.updateTitle( e.target.value );

  }

  onChange = ( object, changes ) => {

    this.setState({ object : object });
    this.props.onChange( object, changes );
  }

  prepareSelection = ( selection, label, value, type ) => {
    let s = [];
    for(let prod in selection ){

      let p = selection[prod];


//      if(hideProducts.indexOf( p[label] ) > -1 ) continue;
      let translated = this.props.lang[p[label]];
      if( type === 'material' )
        translated = this.props.lang.materials[p[label]];

      s.push({
        label: translated,
        value: p[value],
        type: type
      });
    }

    return s;
  }

  async fetchObject(){
    const self = this;
//    const url = api.api+api.Quote.read+'/'+this.props.objectId;
//    let order = false;

    await api.send(
      'Quote',
      'quote/loadAll/'+this.props.objectId,
      false,
      {
        type: 'GET',
        success : ( response ) => {


          this.props.updateTitle( self.props.makeWindowTitle( response.data , ['name']) );
          const time = new Date().getTime();
          const ps = this.prepareSelection( response.products, 'translation_tag', 'translation_tag', 'product');
          const product = response.data.data;
          const mats = this.prepareSelection ( response.prices.materials, 'tag' , 'tag' ,'material' );
          const blockSelection = this.prepareSelection( response.blocks, 'translation_tag','translation_tag', 'blocks' );
          const type = response.data.type;
          const price = ( response.data.price > 0 ? response.data.price : response.price );

          this.setState({
            isUpdated:  time,
            product: product,
            activeType: type,
            tapes: response.tapes,
            object: response.data,
            prices : response.prices,
            product_price: response.price,
            price: price,
            products : response.products,
            blocks: response.blocks,
            productSelection: ps,
            blockSelection: blockSelection,
            materialSelection: mats
          });
        }
      }
    );

  }

  async componentDidMount(){
    await this.fetchObject();

  //  const response = await api.send( 'Prices', 'prices/priceList', {}, { type:'GET'} );



    $('#'+this.props.windowId).resizable({
      resize : function(){
        let items = $('.flexGrid');
        let flexWidth = $(this).width();

        if( flexWidth < 512 ){
          $.each( items, (i,e) => {
            if( !$(e).hasClass('col-md-12') )
              $(e).removeClass('col-md-6').addClass('col-md-12');

          });
        } else {
          $.each( items, (i,e) => {
            if(!$(e).hasClass('col-md-6'))
              $(e).removeClass('col-md-12').addClass('col-md-6');
          });

        }
      }
    });

    this.setState({ loaded: true });

  }

  post = async (formData) => {

      const response = await api.send(
        'QuoteModule',
        api.Quote.upload + '/' + this.state.object.id,
        formData,
        {
          processData: false,
          contentType: false,
          crossDomain: true,
          type: "POST"
        }
      );

      if( typeof response.object === 'object' && response.object !== null )
        this.onChange( response.object, response.object )

  }

  _calculatePrice( price ){
    price = parseFloat( price.replace(',','.') );

    let addon = parseInt(this.addon.current.value);
    let extra = parseInt(this.extra.current.value);
    let discount = parseInt(this.discount.current.value);
    let quantity = parseInt(this.quantity.current.value);

    if( isNaN(quantity) || quantity < 1 ){
      quantity = 1;
    }
    if( extra > 0 )
      price = price + extra;


    if( addon > 0 )
      price = price * (1+addon/100);


    if(discount > 0 )
      price = price * (1-discount/100);

    price = price * quantity;
    price = Math.round(price*100)/100;
    price = parseFloat( price.toFixed(2) );

    return price;


  }

  handlePriceChange = (e, element) => {
    let {product_price:price, object} = this.state;
    price = this._calculatePrice( price );
    object[element] = parseInt( e.target.value );
    this.setState({ price, object });

  }

  selectProduct = ( type ) => {

    this.setState({ activeType : type });
  }
  handleProductChange = async ( option ) => {

      const product = { ...this.state.products.find( p => p.translation_tag === option.value ) };
      const object = { ...this.state.object };
      const response = await api.send('Prices', 'prices/calculatePrice' , { data: JSON.stringify(product) }, { type:'GET' });
      const price = this._calculatePrice( response.data );

      object.type = product.translation_tag;

      this.setState({
        activeType: option.value,
        product: product,
        object: object,
        product_price: price,
        price: price
      });
  }

  handleChangeCombo = async ( option ) => {
    const product = { ...this.state.product };

    let activeType = this.state.activeType;
    switch( option.type ){
      default:
      case "product":

        activeType = option.label;
        break;
      case "material":
        product.material = option.value;

      break;

    }
    const response = await api.send('Prices', 'prices/calculatePrice' , { data: JSON.stringify(product) }, { type:'GET' });



    this.setState({
      activeType: activeType,
      product: product,
      price: response.data.replace(',','.'),
      product_price: response.data.replace(',','.')
    });
  }

  toggleBottomPanel = () => {
    $('.bottomPanel ').toggleClass('closed-bottom-panel');
  }

  handleShape = ( option ) => {
    const object = { ...this.state.product };
    object.shape = option.value;
    this.update( object );
  }

  handleAddBlock = ( option ) => {
    const product = { ...this.state.product };
    const blocks = [ ...product.blocks ];
    const newBlock = { ...this.state.blocks.find( b => b.translation_tag === option.value ) };
    newBlock.default_data.quantity = 1;
    blocks.push( newBlock.default_data );

    product.blocks = blocks;
    this.update( product );


  }

  updateProperty = (e, key) => {
    const object = { ...this.state.object };
    object[key] = e.target.value;
    this.setState({ object });

  }
  update = async ( product ) => {
    const response = await api.send('Prices', 'prices/calculatePrice' , { data: JSON.stringify(product), notification: false }, { type:'GET' });
    this.setState( { product: product, price: response.data.replace(',','.'), product_price: response.data.replace(',','.') });
   
  }

  save = async ( ) => {
    const object = this.state.object;
    object.data = this.state.product;
    object.price = this.state.price;

    const response = await api.send('Quote', api.Quote.save + '/'+ object.id, { data : JSON.stringify( object )},{ type:'GET'});
    if( response.code === 200 ){
      window.location.reload();
    }
  }

  delete = async () => {
    const object = this.state.object;
    if( window.confirm( this.props.lang.are_you_sure_you_want_to_delete ) ){
      const response = await api.send('Quote', api.Quote.delete + '/'+ object.id );
      if( response.code === 200 ){
        this.wm.removeWindow( this.props.windowId );
        this.props.updateState();
      }
    }

  }

  renderProductOptions( product ){
    let lang = this.props.lang;
    let hide = {
      dimensions : '',
      attachmethod : '',
      materials: ''
    };

    switch (product.translation_tag)
    {
      case "cablemarker":
      case "cablemarker_special":
      case "cablemarker_stainless":
      case "cablemarker_emboss":
        hide.materials = 'hide';
      break;

      default:
      case "rectangular":
      case "emergency_stop":
      case "valvetag":
      case "bib":

      break;


    }

    let content = (
      <>
        <div className={"materialWrapper option "+hide.materials}>
          <Select
            options={ this.state.materialSelection }
            onChange={this.handleChangeCombo}
            search="smart"
            defaultValue={ product.material }
            placeholder={ lang.materials[product.material] }
          />
        </div>
        <div className={"materialWrapper option "+hide.dimensions}>
          <Dimensions object={product} lang={this.props.lang} onUpdate={this.update}/>
        </div>
        <div className={"attachWrapper option "+hide.attachmethod}>
          <AttachMethod
            object={product}
            lang={this.props.lang}
            tapes={this.state.tapes}
            onUpdate={this.update}
            prepareSelection={this.prepareSelection}
          />
        </div>
      </>
    );
    return content;
  }
  render( ){


    if( typeof this.state.loaded === 'undefined') return null;
    let lang = this.props.lang;
    const { object, price, product, blockSelection } = this.state;
    const shapeSelection = [{ label: lang.shapes.rectangular, value: "rectangular" }, { label: lang.shapes.oval, value: "oval" }, { label: lang.shapes.rounded , value: "rounded" }];


//    let attributes = this.props.prepareEditableAttributes(quoteFormAttributes, object );
//    const imageSrc = api.root + object.imagelink;


    return (

        <div className="form-wrapper" id={this.props.windowId+'wrapper'} style={{ display:'table', width:'100%', height: '99%'}}>
          <div className="row">
            <div className="col-md-6 flexGrid" >
              <div className="option">
                <Select
                  options={ this.state.productSelection }
                  onChange={this.handleProductChange}
                  search="smart"
                  defaultValue={ product.translation_tag }
                  placeholder={ lang[product.translation_tag] }
                />
              </div>
              { ( product !== false && product.translate_tag !== false ) && this.renderProductOptions( product )}

            </div>
            <div className="option col-md-6 flexGrid">
              <div style={{ marginBottom: 10 }} >
                <Select
                  options={ shapeSelection }
                  onChange={ this.handleShape }
                  search="smart"
                  placeholder={ lang.label_choose_shape }
                />
              </div>
              <Select
                options={ blockSelection }
                onChange={ this.handleAddBlock }
                search="smart"
                placeholder={ lang.add_block }
              />
              <div className="row" style={{ marginTop: 15 }} >
                <div className="col">
                  {
                    product.blocks.length > 0 && product.blocks.map( (block,i) => {
                      return (
                        <Block
                          lang={this.props.lang}
                          key={i}
                          blockNr={i}
                          object={block}
                          onUpdate={this.update}
                          product={product}
                        />
                      )
                    })
                  }

                </div>
              </div>

            </div>
          </div>

          <div className="row bottomPanel bottom-panel" style={{ background:'rgba(255,255,255,0.9)', border: '1px solid #aaa' }}>

            <div className="col quote-form save-panel" style={{ paddingTop:5, display:'table' }}>

              <ObjectForm
                parent={this.props.parent}
                object={object}
                updateState={this.props.updateState}
                module="Quote"
              >
                  <div className="row" style={{ marginTop: 0 }} >
                    <div className="col">
                      <h6 className="text-danger" style={{ textAlign:'center', margin:0, padding:2 }}>{ lang.label_price }: { accountingNumbers.se(price,true) } </h6>
                    </div>
                  </div>
                  <div className="row product-form">
                    <div className="col">
                      <label >{ lang.label_additional_payment } %</label>
                      <input type="number" ref={ this.addon } defaultValue={object.addon} onChange={(e) => this.handlePriceChange(e,'addon') } className="form-control" />
                    </div>
                    <div className="col">
                      <label style={{ whiteSpace:'nowrap'}}>{ lang.label_extra } (kr/st)</label>
                      <input type="number" ref={ this.extra } defaultValue={object.extra} onChange={(e) => this.handlePriceChange(e,'extra') }  className="form-control" />
                    </div>
                    <div className="col">
                      <label >{ lang.label_discount } %</label>
                      <input type="number" defaultValue={object.discount} onChange={(e) => this.handlePriceChange(e,'discount') }  ref={ this.discount } className="form-control"/>
                    </div>
                    <div className="col">
                      <label >{ lang.label_quantity } </label>
                      <input type="number" ref={ this.quantity } defaultValue={object.quantity} onChange={(e) => this.handlePriceChange(e,'quantity') }  className="form-control" />
                    </div>
                  </div>
                <hr style={{ margin:'20px 0 20px 0' }}/>
                <div className="row">
                  <div className="col">
                    <div className="row">
                      <div className="col">
                        <label >{ lang.label_label } </label>
                        <input type="text" id="label" value={ object.name } onChange={ (e) => this.updateProperty(e, 'name') } className="form-control" placeholder={ lang.label_label } />
                      </div>
                      <div className="col">
                        <label >{ lang.label_reference } </label>
                        <input type="text" id="our_reference" value={ object.reference } onChange={ (e) => this.updateProperty(e, 'reference') } className="form-control" placeholder={ lang.label_our_reference } />
                      </div>
                      <div className="col">
                        <label >{ lang.label_contact } </label>
                        <input type="text" value={ object.contact } onChange={ (e) => this.updateProperty(e, 'contact') } id="contact" className="form-control" placeholder={ lang.label_contact } />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col">
                        <label >{ lang.label_description } </label>
                        <textarea id="description" className="form-control" placeholder={ lang.label_description}  value={ object.description } onChange={ (e) => this.updateProperty(e, 'description') } />
                      </div>
                    </div>
                  </div>
                  <div className="col" style={{ maxWidth:100 }}>
                    <div style={{ paddingTop:10 }}><button className="btn btn-sm btn-success" onClick={ this.save }> { lang.label_save } </button></div>
                    <div style={{ marginTop:10 }}><button className="btn btn-sm btn-danger" onClick={ this.delete }> { lang.label_delete } </button></div>
                  </div>
                </div>
              </ObjectForm>
            </div>
          </div>
        </div>

    );

  }
};
