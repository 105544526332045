import React from 'react';
import {api} from '../api';

export class AreaPrices extends React.Component {

  constructor( props ) {
    super(props);

    this.state = {
      prices    : [],
      percentage : 0,
      currentType: false
    };

    this.percentage = React.createRef();
  }

  handleRegret = () => {
    const prices = { ...this.state.prices };
    const dPrices = [ ...this.props.prices[this.state.currentType] ];

    prices[this.state.currentType] = dPrices;

    this.setState({ prices });
  }

  handleReset = () => {

    const prices = { ...this.state.prices };
    const data = [ ...prices[this.state.currentType] ];
    for(let i in data ){
      let item = { ...data[i] };

      item.price = item.prevPrice;

      data[i] = item;

    }
    prices[this.state.currentType] = data;

    this.setState({ prices });

  }

  handlePercentageChange = () => {

    const percentage = this.percentage.current.value;
    if( percentage === 0 ) return false;
    const changeValue = 1 + percentage / 100;

    const prices = { ...this.state.prices };
    const data = [ ...prices[this.state.currentType] ];



      let array = [];
      for(let i in data ){
        let item = { ...data[i] };
        let newValue = item.price * changeValue;
        item.price = parseFloat(newValue.toFixed(2));
        array[i] = item;


      }
      prices[this.state.currentType] = array;


    this.setState({ prices });


  }

  handleSave = async () => {
    const data = this.state.prices[this.state.currentType];
//    const prices = {...this.state.prices};
    const response = await api.send( 'Prices', 'prices/updatePricearea', { data: JSON.stringify(data) });
    if( response.code === 200 ){

      this.props.refreshPriceList();

    }

  }

  updatePercentage = (e) => {
    this.setState({ percentage: e.target.value });
  }
  UNSAFE_componentWillReceiveProps(nextProps){

    if(JSON.parse(JSON.stringify(this.props.prices)) !== JSON.parse(JSON.stringify(nextProps.prices)) )
      this.setState({ prices: nextProps.prices });

  }
  componentDidMount(){

    if( this.state.prices.length === 0 ){
      this.setState({
        prices : { ...this.props.prices }
      });
    }

  }
  componentDidUpdate() {


  }

  selectType = ( e ) => {
    let selected = e.target.options[e.target.selectedIndex].value;

//    if( selected == -1 ) return false;
    this.setState({ currentType : selected });
  }
  handlePriceChange = ( e, priceType, rowId ) => {
    const data = { ...this.state.prices };
    const value = parseFloat(e.target.value);
    const dataArray = [ ...data[priceType] ];
    const item = data[priceType].find( r =>  r.aid === rowId );
    const row = { ...item };
    const index = data[priceType].indexOf(item);

    row.price = value;
    dataArray[index] = row;
    data[priceType] = dataArray;

    this.setState( { prices: data });

  }

  renderPriceTable() {

    const lang = this.props.lang;
    const rows = this.state.prices[this.state.currentType];


    if ( typeof rows === 'undefined' || rows.length === 0 ) return null;

    return (
      <div className="container-fluid" style={{ minWidth: 500 }}>
        <div className="percentage row">
          <div className="col-md-5">
            <div className="input-group">
              <div className="input-group-prepend">
                <span className="input-group-text">{ lang.change_prices_percentage } %</span>
              </div>
              <input type="number" id="percentage" style={{ maxWidth:70, height: 31}} className="form-control" onChange={ this.updatePercentage } value={ this.state.percentage } ref={ this.percentage }/>
              <div className="input-group-append" id="button-addon4">
                <button className="btn btn-sm btn-secondary" onClick={ this.handlePercentageChange }> { lang.label_update } </button>
              </div>
            </div>
          </div>
          <div className="col-md-2" style={{ textAlign: 'center'}}>
            <div className="btn-group">

              <button className="btn btn-sm btn-secondary" onClick={ this.handleRegret } title="Regret latest price updates"> { lang.label_regret } </button>
              <button className="btn btn-sm btn-danger" onClick={ this.handleReset } title="Reset to previous price"> { lang.label_reset } </button>
            </div>
          </div>
          <div className="col-md-5">
            <button className="btn btn-sm btn-success pull-right" onClick={ this.handleSave }> { lang.label_save } </button>
          </div>
        </div>
        <div className="row" style={{ marginTop: 30 }}>
          <div className="col-md-12">
            <table className="table table-striped priceTable">
              <thead>
                <tr>
                  <th>Min</th>
                  <th>Max</th>
                  <th>{ lang.table_columns.price }</th>
                  <th>{ lang.label_previous_price }</th>

                </tr>
              </thead>
              <tbody>
              {
                  rows.map( (row,i) => {
                    return (
                      <tr key={i}>

                        <td>{ row.min }</td>
                        <td>{ row.max }</td>
                        <td>
                          <input key={"price_"+row.aid} style={{ maxWidth:70 }} className="form-control" type="number" step="0.01" onChange={ (e) => this.handlePriceChange( e, this.state.currentType, row.aid )} value={ row.price } />
                        </td>
                        <td>{ row.prevPrice }</td>

                      </tr>
                    );
                  })
              }
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }

  render( ){
    const {currentType} = this.state;
    const {lang} = this.props;
    const types = Object.keys(this.state.prices).sort( function(a,b){ return lang[a] > lang[b] });

    return (
      <div>
        <select
          value={ currentType }
          placeholder="fasdfasdf"
          className="form-control"
          onChange={ this.selectType }
          style={{ marginBottom: 30 }}
        >
          <option value="-1">-- { lang.choose_pricetable_item } --</option>
          {
            types.map( ( label, i ) => {

              return (
                <option key={i} value={ label }>{ lang[label] }</option>
              );
            })
          }
        </select>
        { currentType !== false && this.renderPriceTable( currentType )}
      </div>

    );

  }
};

export class MaterialPrices extends React.Component {

  constructor( props ) {
    super(props);

    this.state = {
      prices    : [],
      percentage : 0,
      currentType: false
    };

    this.percentage = React.createRef();
  }

  handleRegret = () => {

    this.setState({ prices: this.props.prices });
  }

  handleReset = () => {

    const prices = { ...this.state.prices };

    for(let i in prices ){
      let item = { ...prices[i] };

      item.price = item.prevPrice;
      item.procent = item.prevPercent;

      prices[i] = item;

    }

    this.setState({ prices });

  }

  handlePercentageChange = (e) => {
    const percentage = this.percentage.current.value;
    if( percentage === 0 ) return false;

    const prices = { ...this.state.prices };
    const changeValue = 1 + percentage / 100;

    for( let i in prices ) {
      let material = { ...prices[i] };
      let newPrice = material.price * changeValue;
      let newPercent = material.procent * changeValue;
      newPercent = parseInt( newPercent );
      newPrice = parseFloat( newPrice.toFixed(2) );
      material.price = newPrice;
      material.procent = newPercent;

      prices[i] = material;
    }

    this.setState({ prices });

  }

  handleMatPercentChange  = (e, material) => {
    const prices = { ...this.state.prices };
    const value = parseFloat(e.target.value);
    const row = { ...prices[material] };

    row.procent = value;
    prices[material] = row;
    this.setState( { prices });
  }

  handlePriceChange = (e, material ) => {
    const prices = { ...this.state.prices };
    const value = parseFloat(e.target.value);
    const row = { ...prices[material] };

    row.price = value;
    prices[material] = row;
    this.setState( { prices });

  }


  handleCharAddonChange = (e, material ) => {
    const prices = { ...this.state.prices };
    const value = parseFloat(e.target.value);
    const row = { ...prices[material] };

    row.charAddon = value;
    prices[material] = row;
    
    this.setState( { prices });

  }

  handleSave = async () => {

    const prices = {...this.state.prices};
    const response = await api.send( 'Prices', 'prices/updatePricematerial', { data: JSON.stringify(prices) } );
    if( response.code === 200 ){

      this.props.refreshPriceList();

    }

  }

  updatePercentage = (e) => {
    this.setState({ percentage: e.target.value });
  }
  componentDidMount(){

    if( this.state.prices.length === 0 ){
      this.setState({
        prices : { ...this.props.prices }
      });
    }

  }

  UNSAFE_componentWillReceiveProps(nextProps){

    if(JSON.parse(JSON.stringify(this.props.prices)) !== JSON.parse(JSON.stringify(nextProps.prices)) )
      this.setState({ prices: nextProps.prices });

  }

  translationTags( rows ){

    let i;
    let withTrans = [];
    for(i in rows ){
      let item = {
        tag: rows[i].tag,
        trans:  this.props.lang.materials[rows[i].tag]
      };

      withTrans.push( item );
    }

    return withTrans;
  }

  render() {

    const lang = this.props.lang;
    const rows = this.state.prices;
    const tags = this.translationTags( rows );
    const sorted = tags.sort( function(a, b) { return a.trans > b.trans });

    return (
      <div className="container-fluid" style={{ minWidth: 500 }}>
        <div className="percentage row">
          <div className="col-md-5">
            <div className="input-group">
              <div className="input-group-prepend">
                <span className="input-group-text">{ lang.change_prices_percentage } %</span>
              </div>
              <input type="number" id="percentage" style={{ maxWidth:70, height: 31}} className="form-control" onChange={ this.updatePercentage } value={ this.state.percentage } ref={ this.percentage }/>
              <div className="input-group-append" id="button-addon4">
                <button className="btn btn-sm btn-secondary" onClick={ this.handlePercentageChange }> { lang.label_update } </button>
              </div>
            </div>
          </div>
          <div className="col-md-2" style={{ textAlign: 'center'}}>
            <div className="btn-group">

              <button className="btn btn-sm btn-secondary" onClick={ this.handleRegret } title="Regret latest price updates"> { lang.label_regret } </button>
              <button className="btn btn-sm btn-danger" onClick={ this.handleReset } title="Reset to previous price"> { lang.label_reset } </button>
            </div>
          </div>
          <div className="col-md-5">
            <button className="btn btn-sm btn-success pull-right" onClick={ this.handleSave }> { lang.label_save } </button>
          </div>
        </div>
        <div className="row" style={{ marginTop: 30 }}>
          <div className="col-md-12">
            <table className="table table-striped priceTable">
              <thead>
                <tr>
                  <th>Material</th>
                  <th>{ lang.table_columns.price }</th>
                  <th>{ lang.label_previous_price }</th>
                  <th>{ lang.label_percentage }</th>
                  <th>{ lang.label_previous_percent }</th>
                  <th>{ lang.label_char_addon }</th>
                </tr>
              </thead>
              <tbody>
              {
                  sorted.map( (sortItem,i) => {
                    let key = sortItem.tag;
                    let row = rows[key];
                    return (
                      <tr key={i}>

                        <td>{ lang.materials[row.tag] }</td>
                        <td>
                          <input key={"price_"+row.mid} style={{ maxWidth:70 }} className="form-control" type="number" step="0.01" onChange={ (e) => this.handlePriceChange( e, key, row.mid )} value={ row.price } />
                        </td>
                        <td> { row.prevPrice } </td>
                        <td>
                          <input key={"price_"+row.mid} style={{ maxWidth:70 }} className="form-control" type="number" step="1" onChange={ (e) => this.handleMatPercentChange( e, key, row.mid )} value={ row.procent } />
                        </td>
                        <td> { row.prevPercent } </td>
                        <td> 
                          <input key={"price_"+row.mid} style={{ maxWidth:70 }} className="form-control" type="number" step="0.05" onChange={ (e) => this.handleCharAddonChange( e, key, row.mid )} value={ row.charAddon || 0 } />
                        </td>

                      </tr>
                    );
                  })
              }
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }



};


export class DiscountPrices extends React.Component {

  constructor( props ) {
    super(props);

    this.state = {
      prices    : [],
      percentage : 0,
      currentType: false
    };

    this.percentage = React.createRef();
  }

  handleRegret = () => {

    this.setState({ prices: this.props.prices });
  }

  handleSave = async () => {

    const prices = {...this.state.prices};
    const response = await api.send( 'Prices', 'prices/updatePricediscount', { data: JSON.stringify(prices) } );
    if( response.code === 200 ){

      this.props.refreshPriceList();

    }

  }

  handlePriceChange = ( e, productType, rowId ) => {
    const value = e.target.value;
    const prices = { ...this.state.prices };
    const items = [ ...prices[productType] ];
    const row = items.find( i => i.did === rowId );
    const item = { ...row };
    const index = items.indexOf(row);

    item.discount = parseInt(value);
    items[index] = item;
    prices[productType] = items;

    this.setState({ prices });

  }
  UNSAFE_componentWillReceiveProps(nextProps){

    if(JSON.parse(JSON.stringify(this.props.prices)) !== JSON.parse(JSON.stringify(nextProps.prices)) )
      this.setState({ prices: nextProps.prices });

  }
  componentDidMount(){

    if( this.state.prices.length === 0 ){
      this.setState({
        prices : { ...this.props.prices }
      });
    }

  }

  render() {

    const lang = this.props.lang;
    const rows = this.state.prices;
    const sorted = Object.keys(rows).sort( function(a,b){ return a > b });
    return (
      <div className="container-fluid" style={{ minWidth: 500 }}>
        <div className="percentage row">
          <div className="col-md-5">
            <button className="btn btn-sm btn-secondary" onClick={ this.handleRegret } title="Regret latest price updates"> { lang.label_regret } </button>
          </div>
          <div className="col-md-2" style={{ textAlign: 'center'}} />
          <div className="col-md-5">
            <button className="btn btn-sm btn-success pull-right" onClick={ this.handleSave }> { lang.label_save } </button>
          </div>
        </div>
        <div className="row" style={{ marginTop: 30 }}>
          <div className="col-md-12">
            <table className="table table-striped priceTable">
              <thead>
                <tr>
                  <th>{ lang.label_product }</th>
                  <th>Min</th>
                  <th>Max</th>
                  <th>{ lang.label_price_discount }</th>
                </tr>
              </thead>
              <tbody>
              {
                  sorted.map( (key,i) => {
                    let productType = rows[key];
                    let typeContent = productType.map( ( row, i ) => {


                      return (
                        <tr key={i}>
                          <td>{ lang[row.tag] } ( {row.tag} )</td>
                          <td>{ row.min }</td>
                          <td>{ row.max }</td>
                          <td>
                            <input key={"price_"+row.did} style={{ maxWidth:70 }} className="form-control" type="number" step="1" onChange={ (e) => this.handlePriceChange( e, row.tag, row.did )} value={ row.discount } />
                          </td>
                        </tr>

                      );
                    })

                    return typeContent;
                  })
              }
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }



};

export class OtherPrices extends React.Component {

  constructor( props ) {
    super(props);

    this.state = {
      prices    : [],
      rowpercentage : 0,
      minpercentage : 0
    };

    this.rowpercentage = React.createRef();
    this.minpercentage = React.createRef();
  }

  updateTextrowPercentage = (e) => {
    this.setState({ rowpercentage: e.target.value });
  }

  updateMinPercentage = (e) => {
    this.setState({ minpercentage: e.target.value });
  }

  handleHoleRegret = () => {
    const prices = { ...this.state.prices };
    prices.holePrice = this.props.prices.holePrice;

    this.setState({ prices});
  }

  handleTextrowRegret = () => {
    const prices = { ...this.state.prices };
    const textrows = [ ...this.props.prices.textrows ];

    prices.textrows = textrows;

    this.setState({ prices });
  }

  handleMinimumRegret = () => {
    const prices = { ...this.state.prices };
    const minOrderValue = [ ...this.props.prices.minOrderValue ];

    prices.minOrderValue = minOrderValue;

    this.setState({ prices });
  }

  handleTextrowReset = () => {

    const prices = { ...this.state.prices };
    const data = [ ...prices.textrows ];
    for(let i in data ){
      let item = { ...data[i] };

      item.price = item.prevPrice;

      data[i] = item;

    }
    prices.textrows = data;

    this.setState({ prices });

  }

  handleTextrowChange = () => {

    const percentage = this.rowpercentage.current.value;
    if( percentage === 0 ) return false;
    const changeValue = 1 + percentage / 100;

    const prices = { ...this.state.prices };
    const data = [ ...prices.textrows ];



      let array = [];
      for(let i in data ){
        let item = { ...data[i] };
        let newValue = item.price * changeValue;
        item.price = parseFloat(newValue.toFixed(2));
        array[i] = item;


      }
      prices.textrows = array;


    this.setState({ prices });


  }
  handleHolePriceChange = (e) => {
    const value = e.target.value;
    const prices = { ...this.state.prices };


    prices.holePrice = value;

    this.setState({ prices });


  }
  handleMinPriceChange = (e, rowId) => {
    const prices = { ...this.state.prices };
    const data = [ ...prices.minOrderValue ];
    const value = parseFloat(e.target.value);

    const item = data.find( r =>  r.oid === rowId );
    const row = { ...item };
    const index = data.indexOf(item);

    row.price = value;
    data[index] = row;
    prices.minOrderValue = data;

    this.setState( { prices });
  }

  handleTextrowPriceChange = (e, rowId) => {
    const prices = { ...this.state.prices };
    const data = [ ...prices.textrows ];
    const value = parseFloat(e.target.value);

    const item = data.find( r =>  r.rid === rowId );
    const row = { ...item };
    const index = data.indexOf(item);

    row.price = value;
    data[index] = row;
    prices.textrows = data;

    this.setState( { prices });
  }

  handleMinimumChange = (e) => {
    const percentage = this.minpercentage.current.value;
    if( percentage === 0 ) return false;
    const changeValue = 1 + percentage / 100;

    const prices = { ...this.state.prices };
    const data = [ ...prices.minOrderValue ];



      let array = [];
      for(let i in data ){
        let item = { ...data[i] };
        let newValue = item.price * changeValue;
        item.price = parseFloat(newValue.toFixed(2));
        array[i] = item;


      }
      prices.minOrderValue = array;


    this.setState({ prices });



  }

  handleMinProductPriceChange = async (e, type) => {
      const prices = { ...this.state.prices };
      const minPrice = { ...prices.minPrice };

      minPrice[type].minPrice = parseFloat( e.target.value );

      prices.minPrice = minPrice;

      this.setState({ prices });


  }

  handleMinProductPriceSave = async () => {

    const data = this.state.prices.minPrice;
    const response = await api.send( 'Prices', 'prices/updateMinProductPrice', { data: JSON.stringify(data) });
   
    if( response.code === 200 ){

      this.props.refreshPriceList();

    }

  }


  handleMinimumSave = async () => {
    const data = this.state.prices.minOrderValue;
    const response = await api.send( 'Prices', 'prices/updatePriceorder', { data: JSON.stringify(data) });
    if( response.code === 200 ){

      this.props.refreshPriceList();

    }

  }

  handleHoleSave = () => {
    api.send( 'Prices', 'prices/updatePricehole', { price: this.state.prices.holePrice });
  }

  handleTextrowSave = async () => {
    const data = this.state.prices.textrows;
    const response = await api.send( 'Prices', 'prices/updatePricetextrows', { data: JSON.stringify(data) });
    if( response.code === 200 ){

      this.props.refreshPriceList();

    }

  }

  componentDidMount(){

    if( this.state.prices.length === 0 ){
      this.setState({
        prices : { ...this.props.prices }
      });
    }

  }

  componentWillReceiveProps(nextProps){

    if(JSON.parse(JSON.stringify(this.props.prices)) !== JSON.parse(JSON.stringify(nextProps.prices)) )
      this.setState({ prices: nextProps.prices });

  }

  renderHolePrice( price ){

    return (
      <React.Fragment>
        <h3>{ this.props.lang.label_hole_price }</h3>

        <table className="table table-striped priceTable">
          <tbody>
            <tr >
              <td>{ this.props.lang.label_per_extra_row }</td>
              <td>
                <input style={{ maxWidth:70 }} className="form-control" type="number" step="0.01" onChange={ (e) => this.handleHolePriceChange( e, 1 )} value={ price } />
              </td>
              <td>
                <button className="btn btn-sm btn-secondary pull-left" onClick={ this.handleHoleRegret }> { this.props.lang.label_regret } </button>

                <button className="btn btn-sm btn-success pull-right" onClick={ this.handleHoleSave }> { this.props.lang.label_save } </button>

              </td>
            </tr>

          </tbody>
        </table>
      </React.Fragment>
    )
  }

  renderMinimumValue( items ){
    const {lang} = this.props;

    if( this.state.prices.length === 0 ) return null;
    return (
      <div className="container">
        <h3>{ lang.label_minimum_value }</h3>
        <div className="percentage row">
          <div className="col-md-6">
            <div className="input-group">
              <div className="input-group-prepend" style={{ maxWidth: 30 }}>
                <span className="input-group-text">%</span>
              </div>
              <input type="number" id="percentage" style={{ width:60, height: 31}}  onChange={ this.updateMinPercentage } value={ this.state.minpercentage } ref={ this.minpercentage }/>
              <div className="input-group-append" id="button-addon4">
                <button className="btn btn-sm btn-secondary" onClick={ this.handleMinimumChange }> { lang.label_update } </button>
              </div>
            </div>
          </div>
          <div className="col-md-2" style={{ textAlign: 'center'}}>
            <div className="btn-group">

              <button className="btn btn-sm btn-secondary" onClick={ this.handleMinimumRegret } title="Regret latest price updates"> { lang.label_regret } </button>

            </div>
          </div>
          <div className="col-md-4">
            <button className="btn btn-sm btn-success pull-right" onClick={ this.handleMinimumSave }> { lang.label_save } </button>
          </div>
        </div>
        <div className="row" style={{ marginTop: 30 }}>
          <div className="col-md-12">
            <table className="table table-striped priceTable">
              <thead>
                <tr>

                  <th>{ lang.label_description }</th>
                  <th>{ lang.label_price }</th>
                  <th>{ lang.label_translation_tag }</th>
                </tr>
              </thead>
              <tbody>
              {
                  items.map( (row,i) => {
                    
                      return (
                        <tr key={i}>

                          <td>{ lang[row.tag] }</td>
                          <td>
                            <input key={"price_"+row.oid} style={{ maxWidth:70 }} className="form-control" type="number" step="1" onChange={ (e) => this.handleMinPriceChange( e, row.oid )} value={ row.price } />
                          </td>
                          <td>{ row.tag }</td>

                        </tr>

                      );
                    })

              }
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );

  }
  renderTextrows( textrows ){


    const {lang} = this.props;
    if( this.state.prices.length === 0 ) return null;
    return (
      <div className="container">
        <h3>Radtill&auml;gg</h3>
        <div className="percentage row">
          <div className="col-md-5">
            <div className="input-group">
              <div className="input-group-prepend">
                <span className="input-group-text">{ lang.change_prices_percentage } %</span>
              </div>
              <input type="number" id="percentage" style={{ maxWidth:70, height: 31}} className="form-control" onChange={ this.updateTextrowPercentage } value={ this.state.rowpercentage } ref={ this.rowpercentage }/>
              <div className="input-group-append" id="button-addon4">
                <button className="btn btn-sm btn-secondary" onClick={ this.handleTextrowChange }> { lang.label_update } </button>
              </div>
            </div>
          </div>
          <div className="col-md-2" style={{ textAlign: 'center'}}>
            <div className="btn-group">

              <button className="btn btn-sm btn-secondary" onClick={ this.handleTextrowRegret } title="Regret latest price updates"> { lang.label_regret } </button>
              <button className="btn btn-sm btn-danger" onClick={ this.handleTextrowReset } title="Reset to previous price"> { lang.label_reset } </button>
            </div>
          </div>
          <div className="col-md-5">
            <button className="btn btn-sm btn-success pull-right" onClick={ this.handleTextrowSave }> { lang.label_save } </button>
          </div>
        </div>
        <div className="row" style={{ marginTop: 30 }}>
          <div className="col-md-12">
            <table className="table table-striped priceTable">
              <thead>
                <tr>

                  <th>TH Min</th>
                  <th>TH Max</th>
                  <th>{ lang.label_price }</th>
                  <th>{ lang.label_previous_price }</th>
                </tr>
              </thead>
              <tbody>
              {
                  textrows.map( (row,i) => {

                      return (
                        <tr key={i}>

                          <td>{ row.min }</td>
                          <td>{ row.max }</td>
                          <td>
                            <input key={"price_"+row.rid} style={{ maxWidth:70 }} className="form-control" type="number" step="0.01" onChange={ (e) => this.handleTextrowPriceChange( e, row.rid )} value={ row.price } />
                          </td>
                          <td>{ row.prevPrice }</td>
                        </tr>

                      );
                    })

              }
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );

  }

  renderMinimumPrice( minPrices ){
      const {lang} = this.props;
      if( this.state.prices.length === 0 ) return null;
      
      return (
        <div className="container">
          <div class="row">
              <div class="col-sm-6">
                  <h3 class="pull-left">Minimumpris skyltar</h3>
                  <div><small></small></div> 
               </div>
               <div class="col-sm-6">
                  <button className="btn btn-sm btn-success pull-right" onClick={ this.handleMinProductPriceSave }> { lang.label_save } </button>
               </div>
          </div>
          
          
            <div className="row" style={{ marginTop: 30 }}>
              <div className="col-md-12">
                <table className="table table-striped priceTable">
                  <thead>
                    <tr>

                      <th>{ lang.label_product_type }</th>
                      <th>{ lang.label_minimum_price }</th>
                      <th>{ lang.label_previous_price }</th>
                      <th>{ lang.label_last_updated }</th>
          
                    </tr>
                  </thead>
                  <tbody>
                  {
                      Object.entries( minPrices ).map( (item,i) => {
                        let row = item[1];
                        
                          return (
                            <tr key={i}>

                              <td>{ row.name }</td>
                              <td>
                                <input key={"price_"+row.id} style={{ maxWidth:70 }} className="form-control" type="number" step="0.1" onChange={ (e) => this.handleMinProductPriceChange( e, row.name )} value={ row.minPrice } />
                              </td>
                              <td>{ row.prevPrice }</td>
                              <td>{ row.updated }</td>
                            </tr>

                          );
                        })

                  }
                  </tbody>
                </table>
              </div>
            </div>
          </div>
      )
  }

  render(){

    const { minOrderValue, holePrice, minPrice } = this.state.prices;

/*
        <div className="row">
          {this.renderTextrows( textrows )}
        </div>
        <hr/>
*/

    return (
      <div className="container">
        <div className="row">
          {this.renderMinimumPrice( minPrice )}
        </div>
        <hr/>
        <div className="row">
          <div className="col-md-6">
            { this.renderHolePrice( holePrice ) }
          </div>
          <div className="col-md-6">
            { this.renderMinimumValue( minOrderValue ) }
          </div>
        </div>

      </div>
    );
  }
};
