import React from 'react';
import { api } from '../api';
import {accountingNumbers} from '../../config.js';
const lang = require('../../lang/se.json');

export const StickerView = (props) => {
  const object = props.object;
  const imageLink =  api.root+''+ object.imagelink;

  return (
    <div className="">
      <div>
        <img style={{ maxWidth:'350px', float:'left', maxHeight:'200px', marginRight:'10px' }} src={ imageLink } alt="image_thumb"/>
         <div>{ object.name }</div>
         <div>ID #{ object.id }</div>

         <div style={{ float:'left' }}>
           <div>{ lang['label_description'] }</div>
           <div>{ object.description }</div>
           <div className="price-wrapper">
             <div>{ lang['label_price'] } <span className="price" id="price"> { accountingNumbers.se(object.price,true) } </span> </div>

           </div>
         </div>
       </div>
    </div>

  );

};

