import React, { Component } from 'react';
import { getFont } from '../config.js';
/**
 * USING <TEXT> instead of <TSPAN>
 */
export class TextBlock extends Component{

    constructor( props ){
        super(props);
        this.block = React.createRef(null);
        this.pc = props.pixelConverter;
    }

    positionRows( textElement, sign ){
        
    }

    positionBlock( block, sign ){
        
        let data = sign.blocks[ block.getAttribute('index') ];
        
        if( typeof data.rows === 'undefined' || data.rows.length <= 0 )
            return false;

        if( typeof data === 'undefined' ){ 

            console.log( 'ERROR: Block data not found. Block will not be visible ' );
            return false;
        };
        
        let font = getFont( 'Gravur Condensed' );
        //let font = getFont( this.props.data.active_font );
        let left = sign.margin_left;
        let top = sign.margin_top;
        let right;
        let bottom;
        const bBox = block.getBBox();
        let blockHeight = bBox.height;
        let blockWidth = bBox.width;
        let hasSameRow = false;
        let longestRowWidth = 0;
        Object.values(block.childNodes).forEach( (r,index) => {
            let tb = r.getBBox();
            if( tb.width > longestRowWidth )
                longestRowWidth = tb.width;
        });


        data.rows.forEach( (r) => {
            if( r?.sameRow === true )
                hasSameRow = true;
        });


        const maxBlockWidth = sign.width - sign.margin_left - sign.margin_right;
        

        let boxTopMargin = parseFloat( data.rows[0].size * font.multiplier * font.top_margin );
        let boxBottomMargin = parseFloat( data.rows[ data.rows.length -1 ].size * font.bottom_margin );

        if( blockWidth > longestRowWidth )
            blockWidth = longestRowWidth;

        if( hasSameRow === true || blockWidth > maxBlockWidth ){
            blockWidth = maxBlockWidth;
        }

        if( data.maximized !== true ){
            left = data?.position?.left > 0 ? this.pc.toPixel( data.position.left ) : bBox.x;
            top = data?.position?.top > 0 ? this.pc.toPixel( data.position.top ) : bBox.y;
        }
        let firstRowHeight = this.pc.toPixel( data?.rows[0].size ) || 0;
        let lastRowHeight = this.pc.toPixel( data?.rows[ data.rows.length -1 ].size ) || 0;
    
        // VERTICAL BLOCK ALIGNMENT
        switch( data.vertical_block_alignment ){

            case "t":
                top = sign.margin_top - boxTopMargin; // ;
            break;

            case "m":
                top = ( sign.height - blockHeight )/2 - boxTopMargin;
            break;
            
            case "b":
                top = sign.height - ( blockHeight + sign.margin_bottom )  - boxTopMargin ;
                break;
            
            default:
                top = top - boxTopMargin; 
        }
            
    

     

        let x = 0;
        let dy = 0;

        // HORISONTAL BLOCK ALIGNMENT
        switch( data.horizontal_block_alignment ){

            case "l":
                left = sign.margin_left;
            break;
            case "m":
                left =  ( sign.width - blockWidth )/2;
            break;
            case "r":
                left = sign.width - blockWidth - sign.margin_right;

            default:
                left = Math.max(left, sign.margin_left);
 
        }

        // HORISONTAL ROW ALIGNMENT

        for( let i=0; i<block.childNodes.length; i++ ){    
            let row = block.childNodes[i];
            //const initialHeight = row.height;
            let rBox = row.getBBox();
            let condWidth = 0;
            let widthScale =  maxBlockWidth/rBox.width;
            let rowdy = parseFloat( row.getAttribute( 'y' ) ) || 0;
            
            if( rBox.width === longestRowWidth && rBox.width < maxBlockWidth )
                row.setAttribute('textLength', longestRowWidth );

            if( row.getAttribute('text-anchor') == 'start' ){
                x = 0;
            } else if( row.getAttribute('text-anchor') == 'middle' ){
                if( rBox.width > maxBlockWidth ){
                   
                    condWidth = (rBox.width - widthScale * rBox.width)/2;
                   // row.setAttribute( 'transform', `scale( ${widthScale}, 1 )`);
                   row.setAttribute('textLength', maxBlockWidth );
                   row.setAttribute('lengthAdjust', 'spacingAndGlyphs');
                }
                x = blockWidth/2;
                
            } else if( row.getAttribute('text-anchor') == 'end' ){
                
                if( rBox.width > maxBlockWidth ){
                    condWidth = (rBox.width - widthScale * rBox.width);
                    row.setAttribute('textLength', maxBlockWidth );
                    row.setAttribute('lengthAdjust', 'spacingAndGlyphs');
                    //row.setAttribute( 'transform', `scale( ${widthScale}, 1 )`);
                }
               
                x = blockWidth;
            } 
            row.setAttribute('x', x );
            
            dy +=  rowdy/2
        }
        
        block.setAttribute( 'transform', `translate(${left},${top})`);
        //block.setAttribute( 'x', boxX )
        //block.setAttribute( 'y', blockY )
        block.setAttribute( 'width', blockWidth );

        
    }

    componentDidMount( ){
        this.positionBlock( this.block.current, this.props.sign );
    }
    
    render(){
        let block = this.props.data;
        let font = getFont( 'Gravur Condensed' );
       // let font = getFont( block.active_font );
        let blockWidth = this.pc.toPixel( block.width );
        let sign = this.props.sign;
        let dy = 0;
        let prevDy = 0;
        return ( 
            <g ref={this.block} key={ this.props.index } index={ this.props.index } >

                    { block.rows.length > 0 &&
                        block.rows.map( (t,nr) => {
                            let prev = block.rows[nr-1];
                            let tSize = parseFloat(t.size)*font.multiplier;
                            
                            if( typeof prev !== 'undefined' ){
                                
                               
                                dy = dy + this.pc.toPixel( parseFloat( prev.size )*font.bottom_margin + parseFloat( t.size ) + parseFloat(t.size)*font.top_margin );
                                if(  prev?.sameRow === true && t?.sameRow === true  )                     
                                    dy = prevDy;
 
                            } else {
                                dy = this.pc.toPixel( tSize );
                            
                            }
                            
                            prevDy = dy;
                            let alignmentProps = {
                                textAnchor: 'middle',
                                style: { strokeWidth: 0 , stroke: 'none', fill: '#000', fontFamily: font.name, letterSpacing: font.letter_spacing },
                            };
                            
                            if( t.alignment == 'right' ){
                                alignmentProps.textAnchor = 'end';
                                
                            } else if ( t.alignment == 'left' ){
                                alignmentProps.textAnchor = 'start';
                            
                            };

                            return (
                                <text 
                                    dominantBaseline="auto"
                                    data-maximized={ block.maximized }
                                    multiplier={ font.multiplier }
                                    fontSize={this.pc.toPixel( tSize )}
                                    { ...alignmentProps } 
                                    y={ `${dy}px` } 
                                    key={nr}
                                >
                                    { t.text }
                                </text>
                            );
                        })
                    }
                    
                
            </g>
        );
    }

}

