import React from 'react';
import {windowManager} from '../windowManager';
import {api} from './api';
import $ from 'jquery';
import {ObjectForm} from './ObjectForm.jsx';
//import {Address} from './Address.jsx';
//import {ContentBlock,DropdownSelection} from './HtmlComponents.jsx';
//import {Window} from '../window';
import ComboSelect from 'react-combo-select';
import 'react-combo-select/style.css';
import {FileUpload} from './fileUpload';
import {articleFormAttributes} from './ArticleModule';
const lang = require("../lang/se.json");

export class ArticleFormModule extends React.Component{

  constructor( props ){
    super( props )

    this.state = {
      object : props.object,
      companies : []
    };

    this.wm = new windowManager();


  }

  updateWindowTitle = ( e ) => {

    this.props.updateTitle( e.target.value );

  }

  onChange = ( object, changes ) => {

    if( ('price') in changes ){
      object.previousPrice = changes.price.old;
    }

    this.setState({ object : object });
    this.props.onChange( object, changes );



  }

  post = async (formData) => {

      const response = await api.send(
        'ArticleModule',
        api.Article.upload + '/' + this.state.object.id,
        formData,
        {
          processData: false,
          contentType: false,
          crossDomain: true,
          type: "POST"
        }
      );

      if( typeof response.object === 'object' && response.object !== null )
        this.onChange( response.object, response.object )

  }

  handleDelete = () => {
    $('#'+this.props.windowId).find('.close').click();
   // window.location.reload();
  }

  async componentDidMount(){
    await this.fetchObject();
    const companies = await this.getCustomers();
    this.setState({ companies });

  }

  handleChangeCombo = async ( objId ) => {
    let {object} = this.state;
    const response = await api.send( "Article", api.Article.addToCompany + '/'+object.id, { foretagId: objId } );

    if( response.code === 200 ){
      object.companies.push( response.data );
      this.setState({ object });
//      this.props.onChange( object , object.companies );
//      this.props.updateState();

//      this.fetchOrder();
    }
  }

  handleRemoveCompany = async (comp, i ) => {

    let object = { ...this.state.object };
    if( window.confirm( lang['really_delete_company_from_article'] ) ){

      const response = await api.send( "Article", api.Article.removeItem + '/' + object.id, { foretagId: comp.id } );
      if( response.code === 200 ){
        delete object.companies[i];
        this.setState({ object });
      }
    }

  }
  async getCustomers() {
    let list = [];
    const response = await api.send(
      "Customer",
      api.Customer.list,
      false,
      { type:'GET' }
    );
    if( response.data.length > 0 ){

      for(let i in response.data ){
        let obj = response.data[i];
        if( obj.name === null || obj.name.length === 0 ) continue;

        let item = {
          "text" : obj.custnr+" - "+obj.name,
          "value": obj.id
        }
        list.push(item);
      }



    }
    return list;
  }

  async fetchObject(){
    let self = this;

    await api.send(
      'Article',
      'article/'+this.props.objectId,
      false,
      {
        type: 'GET',
        success : function( response ){

          let object = response.data;
          let time = new Date().getTime();
          self.setState({ isUpdated:  time, object: object });
        //  self.props.updateState();
          self.props.updateTitle( self.props.makeWindowTitle( object , ['name']) );

        }
      }
    );

  }

  render( ){

    let object = this.state.object;
    if( typeof object === 'undefined' || object === false ) return null;

    let attributes = this.props.prepareEditableAttributes(articleFormAttributes, object );
    let companiesContent;
    let imageSrc;
    if( object.imagelink.length > 0 )
      imageSrc =  api.root+''+ object.imagelink;
    else {
      imageSrc = '/assets/images/icons/no_image.svg';
    }
    if( typeof this.state.companies === 'undefined' || this.state.companies.length === 0 ) return false;

    if( object.companies.length > 0 ){
      companiesContent = object.companies.map( (company,i) => {
        return <li key={i} className="list-group-item"> { company.custnr } - { company.name } <i className="fas fa-times-circle deleteListItem" onClick={()=>this.handleRemoveCompany(company,i)}></i></li>;
      });
    } else {
      companiesContent = <li className="list-item">{ lang['article_not_connected'] }</li>;
    }


    return (
      <div className="container">
        <div className="row">
          <div className="col-md-7">
            <ObjectForm
              objectId={ object.id }
              parent={this.props.parent}
              attributes={attributes}
              tabIndex={this.props.tabIndex}
              savePath={ api.Article.save }
              object={object}
              onChange={this.onChange}
              updateState={this.props.updateState}
              module="Article"
              onDelete={this.handleDelete}
            />
          </div>
          <div className="col-md-5 windowInfo">
            <div className="card">
              <img className="card-img-top" src={ imageSrc } style={{ width: '100%', maxHeight:300, objectFit: 'cover' }} alt="Card thumb cap" />
              <FileUpload
                onSubmit={this.post}
                id={ "fileForm_"+ object.id }
                apiUrl={api.api + api.Article.upload + '/'+ object.id }
              />
            </div>
            <h6 style={{ marginTop:20}}>{ lang['is_used_by'] }</h6>
            <ul className="list-group" style={{ marginBottom:5}}>
            {companiesContent}
            </ul>
            {
              ( typeof this.state.companies !== 'undefined' && this.state.companies.length > 0 ) &&
              <ComboSelect
                data={ this.state.companies }
                type="mutltiselect"
                search="smart"
                style={{ maxHeight: 350 }}
                onChange={this.handleChangeCombo}
                text={ lang['add_to_customer']}
              />

            }

          </div>
        </div>
      </div>
    );

  }
};
