import React from 'react';
import {windowManager} from '../windowManager.js';
import {api} from './api.js';
import $ from 'jquery';
import JsonEditor from 'react-json-editor-ui'
import 'react-json-editor-ui/dist/react-json-editor-ui.cjs.development.css'
import ComboSelect from 'react-combo-select';
import 'assets/combo-select.css';

import {ObjectForm} from './ObjectForm.jsx';
//import {Address} from './Address.jsx';
//import {ContentBlock,DropdownSelection} from './HtmlComponents.jsx';
//import {Window} from '../window';
import {FileUpload} from './fileUpload.jsx';
import {templateFormAttributes} from './TemplateModule.js';
const lang = require("../lang/se.json");
//const lang = require("../lang/se.json");

export class TemplateFormModule extends React.Component{

  constructor( props ){
    super( props )

    this.state = {
      object : props.object,
      files: [{
        source: 'index.html',
        options: {
            type: 'local'
        },
        lockJson : true,
        allCompanies: [],
        allUsers: [],
        isLoaded: false
      }]
    };
    this.wm = new windowManager();
    this.jsonToggle = React.createRef( true );


  }

  updateWindowTitle = ( e ) => {

    this.props.updateTitle( e.target.value );

  }

  onChange = ( object, changes ) => {
    this.setState({ object : object });
    this.props.onChange( object, changes );
  }

    async getCompanies() {
        let list = [];
        const response = await api.send(
        "Customer",
        api.Customer.list,
        false,
        { type:'GET' }
        );
        if( response.data.length > 0 ){

            for(let i in response.data ){
                let obj = response.data[i];
                
                let item = {
                text : `${obj.custnr} [ #${obj.id} ]: ${obj.name}`,
                value: obj.id
                }
                list.push(item);
            }



        }
        
        this.setState({ allCompanies: list });
    }

    async getUsers(){
        let list = [];
        const response = await api.send(
            "Employee",
            api.Employee.list,
            false,
            { type:'GET' }
        );
        if( response.data.length > 0 ){

        for(let i in response.data ){
            let obj = response.data[i];
            
            let item = {
                text : `[#${obj.id}]: ${obj.fnamn} ${obj.enamn} - ${obj.company}`,
                value: obj.id
            }
            list.push(item);
        }



        }

    
        this.setState({ allUsers: list }); 
    }

  async fetchObject(){
    let self = this;
//    let url = api.api+api.Template.read+'/'+this.props.objectId;
//    let order = false;
    await api.send(
      'Template',
      'template/'+this.props.objectId,
      false,
      {
        type: 'GET',
        success : function( response ){

          let object = response.data;
            const users = object.users;
            let sharedWithUsers = []
            if( users.length > 0 ){
                users.forEach( u => {
                    sharedWithUsers.push( { 
                        text: `${u?.company?.custNr} - ${u.fnamn} ${u.enamn}`,
                        value: u?.company?.id || null 
                    })
                })
            }
            object.sharedWithUsers = [ ...sharedWithUsers ];

            console.log( ' fetched object ', object );
            const image = Array.isArray(object.images) && typeof object.images[0] !== 'undefined' ? object.images[0] : null;
          let time = new Date().getTime();
          self.setState({ isUpdated:  time, object: object, image: image || null, isLoaded: true });
          self.props.updateTitle( self.props.makeWindowTitle( object , ['name']) );
        }
      }
    );

  }

  async componentDidMount(){
    await this.fetchObject();
    await this.getCompanies();
    await this.getUsers();
  }

  post = async (formData) => {

      const response = await api.send(
        'TemplateModule',
        api.Template.upload + '/' + this.state.object.id,
        formData,
        {
          processData: false,
          contentType: false,
          crossDomain: true,
          type: "POST"
        }
      );

      if( typeof response.object === 'object' && response.object !== null )
        this.onChange( response.object, response.object )

      const image = response.object?.images[0] || null;
      this.setState( { image: image })

  }

  toggleEditable = ( e ) => {
    this.jsonToggle.current.classList.toggle('fa-lock');
    this.jsonToggle.current.classList.toggle('fa-unlock');
    this.setState({ lockJson: !this.state.lockJson });
  }

  toggleCompanies = ( open, value ,text ) => {
    

    if( open === true ){
        const attached = [ ...this.state.object.companies ];
        const filteredArray = this.state.allCompanies.filter(obj1 => !attached.some(obj2 => obj1.value === obj2.id));
        this.setState({ allCompanies: filteredArray });
    
    }

  }
  handleDelete = () => {
    $("#"+this.props.windowId).find('.close').click();
    window.location.reload(true);

  }

    handleChangeCombo = async ( objId ) => {
        let {object} = this.state;
       // let { allCompanies } = this.state;

        const response = await api.send( "Template", `template/share/${object.id}`, { userId: objId } );

        if( response.code === 200 ){
            const users = response.data;
            object.users = [ ...users ];
            this.setState({ object });
        }
    }

    handleRemoveCompany = async (comp, i ) => {

        let object = { ...this.state.object };
        if( window.confirm( lang['really_delete_company_from_article'] ) ){

        const response = await api.send( "Article", api.Article.removeItem + '/' + object.id, { foretagId: comp.id } );
        if( response.code === 200 ){
            delete object.companies[i];
            this.setState({ object });
        }
        }

    }
    async convertTemplate( e ){
        e.preventDefault();
        e.stopPropagation();
        let object = { ...this.state.object };
        // let { allCompanies } = this.state;
 
         const response = await api.send( "Template", `template/convertTemplate/${object.id}`, {}, { type: 'GET' } );
 
         if( response.code === 200 ){
             const jsonTemplate = response.data;
             object.object = { ...jsonTemplate };
             this.setState({ object });
         }
    }

    detachUser = async ( userId ) => {

        if( window.confirm( lang['are_you_sure_you_want_to_detach_this_user'] ) ){
            const object = { ...this.state.object };
            const response = await api.send( "Template", `template/detach/${object.id}`, { userId }, { type: 'GET' } );
            if( response.code === 200 ){
                const users = response.data;
                object.users = users;
                this.setState({ object });
            }
        }
    }

    detachCompany = async ( companyId ) => {

        if( window.confirm( lang['are_you_sure_you_want_to_detach_this_company'] ) ){
            const object = { ...this.state.object };
            const response = await api.send( "Template", `template/detachCompany/${object.id}`, { companyId }, { type: 'GET' } );
            if( response.code === 200 ){
                const companies = response.data;
                object.companies = [ ...companies ];
                this.setState({ object });
            }
        }
    }

    sharetoCompany = async ( objId ) => {
        let {object} = this.state;
       // let { allCompanies } = this.state;

        const response = await api.send( "Template", `template/shareCompany/${object.id}`, { companyId: objId } );

        if( response.code === 200 ){
            const companies = response.data;
            object.companies = [ ...companies ];
            this.setState({ object });
        }
    }

    deleteFile = async ( e, file ) => {

        e.preventDefault();
        e.stopPropagation();

        console.log(' file ', file );
        const res = await api.send( "Template", `template/deleteFile/${file.fid}`, { }, { type: 'POST' } );
        if( res.code === 200 ){
            this.setState({ image: null } );
        }


    }

    handleJsonUpdate = ( json ) => {
        let object = { ...this.state.object };
        
        object.object = JSON.stringify( json );
        this.setState({ object });
    }

    renderTemplate( ){

        if( this.state.object?.id > 0 && this.state.object?.object?.length > 0 ){
            return ( 
                <iframe 
                    title="Scaled preview of product" 
                    style={{ border:'none', height:'500px', width:400 }} 
                    src={api.api+`productView/template/rectangular/${this.state.object.id}`+"?maxWidth="+400+"&maxHeight="+500+"&background=white" } 
                />
            );
        }
        return ( <><h5>Kan inte visa malldata</h5><p>Antingen är detta en gammal mall eller så finns ingen data sparad.</p></>);
    }

    render( ){
        if( !this.state.isLoaded ) return null;
        
        console.log(' render ');
        let object = this.state.object;
        
        if( typeof object === 'undefined' || object === false ) return null;
        const lockJson = this.state.lockJson;
        let attributes = this.props.prepareEditableAttributes(templateFormAttributes, object );
        const json = object?.object?.length > 0 ? JSON.parse( object?.object ) : null;
        
        let imageSrc = '';
        if( this.state.image )
            imageSrc = `${api.root}${this.state.image.path}${this.state.image.name}`;
    
        return (
            <div className="container-fluid">
                <div className="row">
                <div className="col-md-6">
                    <ObjectForm
                        objectId={ object.id }
                        parent={this.props.parent}
                        attributes={attributes}
                        tabIndex={this.props.tabIndex}
                        savePath={ api.Template.save }
                        object={object}
                        onChange={this.onChange}
                        updateState={this.props.updateState}
                        module="Template"
                        onDelete={this.handleDelete}
                        
                    >
                        <input type="button" className="btn btn-primary ml-2" onClick={ (e) => this.convertTemplate( e ) } title={ lang.convert_old_template } value={ lang.label_convert } />
                    </ObjectForm>

                    
                </div>
                <div className="col-md-6 windowInfo">
                    <div className="card">
                        <div className="card-header">{ lang['personal_sharing'] }</div>
                        <div className="card-body">
                            {
                                ( typeof this.state.allUsers !== 'undefined' && this.state.allUsers.length > 0 ) &&
                                <ComboSelect
                                    data={ this.state.allUsers }
                                    type="mutltiselect"
                                    search="smart"
                                    style={{ maxHeight: 350 }}
                                    onChange={this.handleChangeCombo}
                                    text={ lang['share_template']}
                                />

                            }
                            <p>{ lang['shared_to'] }</p>
                            <div className="list-group" style={{ maxHeight: 200, overflowY:'auto' }}>
                            {
                                object?.users?.length > 0 &&
                                object.users.map( (u,i) => {
                                    
                                    return (
                                        <li key={i} className="list-group-item list-group-item-action">
                                            { u.id } : { u.fnamn } { u.enamn } - { u?.company?.name }
                                            <span className="pull-right"><i className="fa fa-times-circle text-danger" onClick={ () => this.detachUser( u.id ) } style={{ cursor:'pointer' }}></i></span>
                                        </li> 
                                    )
                                })
                            }

                            </div>                    
                        </div>    
                        
                    </div>    
                    <div className="card">
                        <div className="card-header">{ lang['automatic_sharing'] }</div>
                        <div className="card-body">
                            {
                                ( typeof this.state.allCompanies !== 'undefined' && this.state.allCompanies.length > 0 ) &&
                                <ComboSelect
                                    data={ this.state.allCompanies }
                                    type="select"
                                    search="smart"
                                    style={{ maxHeight: 350 }}
                                    onChange={ this.sharetoCompany }
                                    text={ lang['share_template']}
                                    onToggle={ this.toggleCompanies }
                                />

                            }
                            <p>{ lang['shared_to'] }</p>
                            <div className="list-group" style={{ maxHeight: 200, overflowY:'auto' }}>
                            {
                                object?.companies?.length > 0 &&
                                object.companies.map( (company,i) => {
                                    return (
                                        <li key={i} className="list-group-item list-group-item-action">
                                            { company.custnr } : { company.name } (#{company.id})
                                            <span className="pull-right"><i className="fa fa-times-circle text-danger" onClick={ () => this.detachCompany( company.id ) } style={{ cursor:'pointer' }}></i></span>
                                        </li> 
                                    )
                                })
                            }

                            </div>                    
                        </div>    
                        
                    </div>   
                </div>
                </div>
                <div className="row">
                    <div className="col-md-6 mt-5">
                        { this.renderTemplate() }


                        <div className="card">
                            {
                                this.state.image &&
                                <img className="card-img-top" src={ imageSrc } style={{ width: '100%', maxHeight:300, objectFit: 'cover' }} alt={"image thumb "+imageSrc } />

                            }
                            <FileUpload
                                onSubmit={this.post}
                                id={ "fileForm_"+ object.id }
                                apiUrl={ `${api.api}${api.Template.upload}/${object.id}` }
                                file={this.state.image}
                                onDelete={ this.deleteFile }
                            />
                        </div>
                    </div>
                    <div className="col-md-6 mt-5" >
                        <div className="col" style={{ padding:30, border: '1px solid #dadada' }}>
                            <h5>Malldata</h5>
                            <div className="alert alert-warning"><small>Var väldigt försiktig om du ändrar i mallen. Det är bäst att du vet vad du gör.</small></div>
                            <div style={{ width: '100%', overflowX: 'auto'}} >

                                {
                                    json !== null && 
                                    <JsonEditor 
                                        data={json}
                                        onChange={data => { this.handleJsonUpdate( data ) }}
                                    />

                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );

    }
};
/*

*/