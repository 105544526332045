import React from 'react';
import ReactDOM from 'react-dom';
import $ from 'jquery';
import {api} from './api';
import {windowManager} from '../windowManager';
import {ObjectForm} from './ObjectForm.jsx';
import {OrderItem} from './OrderItem.jsx';
//import {OrderProject} from './OrderProject.jsx';
import { OrderTotal } from './views/orderTotal';
import {DropdownSelection} from './HtmlComponents.jsx';
//import {DropdownAddressSelect} from './DropdownAddressSelect.jsx';
//import ComboSelect from 'react-combo-select';
import Select from 'react-select';
import {Address} from './Address.jsx';
import {accountingNumbers} from '../config.js';
import {orderFormAttributes} from './OrderModule';
import 'react-combo-select/style.css';
import { ProductionFormModule } from 'modules/ProductionFormModule';
import { ExcelImport } from 'modules/ExcelImport';
import { SlideInView } from './views/SlideInView';
import store from '../store';

const lang = require("../lang/se.json");

export class OrderFormModule extends React.Component{

  constructor( props ){
    super( props )

    this.state = {
        object: null,
      rows    : [],
      users   : [],
      User    : false,
      isLoaded: false,
      isUpdated: false,
      rows_fetched : false,
      is_changing_items: false,
      tapes: [],
      openOrder: false,
      openImport: false
    };
    this.wm = new windowManager();

    this.changeAttachMethod = this.changeAttachMethod.bind( this );


  }
    openSlideIn = ( e, excel ) => {
        if( typeof excel !== 'undefined' )
            this.setState({ openImport: true });
        else 
            this.setState({ openOrder: true })
    }

    closeSlide( e, excel ){
        if( typeof excel !== 'undefined' )
            this.setState({ openImport: false });
        else 
            this.setState({ openOrder: false })
    }
  onChange = ( object, changes ) => {
    this.setState({ object : object });
    this.props.onChange( object, changes );
  }

  onAddressUpdate = ( address ) => {
    let order = Object.assign({}, this.state.object );
    order.addressJson = address;
    this.setState({
      object: order
    });

    this.wm.updateWindowObject(this.props.windowId, order );
  }

  onItemUpdate = ( updatedRows ) => {
    this.fetchOrder();

  }

  updateInput ( e , property ){

    let object = Object.assign({}, this.state.object );
    object[property] = e.target.value;
    let win = this.wm.getWindow( this.props.windowId );
    win.object = object;
    win.title = object.name;
    this.wm.setWindow( win, this.props.windowId );
    this.props.onChange( object );
    this.setState({ object });

  }
  async fetchOrder(){
    let self = this;


    const res = await api.send( 'Order', 'order/'+this.props.objectId, {}, { type: 'GET' });
    if( res.code === 200 ){
        const order = res.data;
        store.set('objectItems', Object.values( order.rows ) );
        let time = new Date().getTime();
        this.setState({ isUpdated:  time, object: order });
        this.props.updateState();
        this.props.updateTitle( this.props.makeWindowTitle( order , ['oid','ordername']) );
        return order;
    }
    return null;
  }

  getTotalValue(){

    let o = this.state.object;
    return Math.max( o.minimumvalue, o.totalprice );

  }


    closeTape( e ){
    
        this.setState({
            is_changing_items: false,
            tapes: []
        });
    }


    handleChangeTape = () => {
        let self = this;

        api.send(
            'Prices',
            'prices/tapes',
            false,
            {
                type: "GET",
                success: function ( response ){

                    let tapes = JSON.parse(response.data);
                    if( response.code === 200 ){
                        self.setState({
                            is_changing_items: true,
                            tapes
                        });
                    }

                }
            }
        );

    }

    renderTapeForm( orderId ){
        const tapes = this.state.tapes;
        return (
            <div className="popup">
                <div className="dialog-closer"><i className="fas fa-times-circle" onClick={self.closeTape}/></div>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="arrow" style={{ position:'absolute', top:-30, left:0 }} />
                            <h5>{ lang['label_change_tape'] }</h5>
                            <select id={"tapes_"+orderId} className="form-control">
                                <option value="-1">{ lang['label_nothing_chosen'] }</option>
                                {
                                    tapes.map( (obj,i ) => {
                                        return ( <option key={"option_"+i} value={obj.tag}>{obj.tape}</option> );
                                    })
                                }
                            </select>
                            <button onClick={(e) => this.changeAttachMethod(e, orderId)} className="btn btn-success" id="update" style={{marginTop:20}}>{ lang['label_update']}</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
  
  deleteSelectedItems( e ){
    let self = this;
    if( window.confirm( lang["are_you_sure_you_want_to_delete_items"] ) ){
        let selectedItems = store.getSelectedItems();
        let rowIds = [];
        selectedItems.forEach( item => {
            let s = {
                rowId: item.id
            }
            rowIds.push( s );
        })

        api.send(
            'Order',
            'order/deleteItems/'+this.state.object.id,
            { data: { items: JSON.stringify( rowIds ) } },
            {
                type: 'DELETE',
                success: response => {
                    let object = response.data;

                    store.set('objectItems', object.rows );
                    store.set('seletecItems', [] );
                    self.setState( { object } );
                    
                    
                }
            }
        )
    }
  }

    changeAttachMethod( e, orderId ){
    
        let items = store.get('objectItems');
        let selected = JSON.stringify( store.getSelectedItems() );
    
        let self = this;
        let newTape = $('#tapes_'+orderId).val();


        api.send(
        'Order',
        'order/rows/changeTape/'+orderId,
        { items: selected, tape: newTape },
        {
            type: 'POST',
            success: function ( response , status ) {


            let order = response.order;

            $.each(items, function(i,el){
                $(el).prop('checked', false);

            });
            store.set('objectItems', order.rows );
            self.setState({ object: order });
            
            
            self.wm.updateWindowObject(self.props.windowId, order );
            self.closeTape();
            }
        }
        );
    }

  toggleSelect( row ){

    store.toggleSelectObjectItem( row )
    let rows = store.get('objectItems');
    let object = this.state.object;
    object.rows = rows;
    this.setState({ object });

  }

  handleClick( e , opened ){

  }
/*
  getRowsWithNoProject(){
//    const order = { ...this.state.object };

  }
*/
    renderRows(){

        let order = { ...this.state.object };

    /*
        if( order.projects.length > 0 ){

        let projectContent = this.state.object.projects.map( (project, i ) => {
            return ( <OrderProject key={i} name={project.name} onItemUpdate={this.onItemUpdate} order={order} rows={order.rows} object={project} expanded={true} /> );
        });

    //    this.getRowsWithNoProject();

        return projectContent;

        } else {
    */
        let rows = store.get('objectItems');

        if( rows.length > 0 ){

            let rowsContent = rows.map( ( row, i ) => {
                if( row.deleted === true ) return false;
                return ( 
                    <OrderItem 
                        key={i} 
                        onItemUpdate={this.onItemUpdate} 
                        order={order} 
                        object={row}
                        toggleSelect={ () => this.toggleSelect( row ) }
                        rows={rows} 
                        handleClick={this.handleClick} opened={ false } 
                    /> 
                );
            });

            return rowsContent;
        }
        return (<tr><td colSpan="10">{ lang['order_is_empty'] }</td></tr>);

    //    }

    }
  getAddressById( company, addressId ){
    let addresses = company.addresses;
    let i;
    for( i in addresses ){
      let adr = addresses[i];
      if( adr.id === addressId )
        return adr;
    }
    return false;

  }

  getAddressesByType( type )
  {
    let order = this.state.object;
    if(  api.exists( order.User ) && api.exists( order.User.Foretag ) ){
      let i;
      let addresses = order.User.Foretag.addresses;
      let selection = []
      for(i in addresses ){
        let adr = addresses[i];
        if( adr.type === type ){
          selection.push( adr );
        }
      }
      return selection;
    }
    return false;
  }

  openItemWindow( objectId ){

//    let query = ( ( typeof objectId === 'undefined' || objectId === null ) ? api.Address.new :  api.Address.read );
    let type = ( objectId > 0 ? "GET" : "POST" );
    let windowParams = {
      module    : 'Address',
      iconSrc   : api.Address.iconSrc,
//      api_query : query,
      titleColumns: ['name'],
      data  : { parentId: this.state.object.id },
      parent: { key: 'foretagId', data: this.state.User.Foretag.id },
      requestType: type
    };
    this.props.openWindow(false, "Address", objectId, ['name','company'], windowParams );
  }

  setDeliveryAddress = async ( item, parentId, edit ) => {

//    let itemId = item.id;

    if( typeof edit !== 'undefined' && edit === true ){

      const response = await api.send( 'Address', api.Address.new, { parentId: this.state.User.Foretag.id } );
      this.openItemWindow( response.data.id );


    } else {

      let order = Object.assign({}, this.state.object );
      order.addressJson = JSON.stringify( item );
      let self = this;
      api.send(
        "Order",
        "order/address/update/"+order.oid,
        { id: order.oid, address: JSON.stringify( item )  },
        {
          success: function( response ){
            self.setState({ object: order });
          }
        }
      );
    }


  }

  renderDeliveryAddress( object, User ){

    let selection = this.getAddressesByType( 'del' );

    let address;


    if( object.addressJson !== null && object.addressJson.length > 0 )
    {
        address = JSON.parse(object.addressJson);

    } else if( object.addressId > 0 ){
      address = this.getAddressById( this.state.User.Foretag, object.addressId );
    } else {
//      let invAdress = User.Foretag.ActiveInvoiceAddress
    //  address = invAdress;
    }
    let activeAddress = false;

    if( address !== false ){
      activeAddress = (
        <Address
          object={address}
          lang={this.props.lang}
          items={ object.addresses }
          parentId={object.id}
          openWindow={this.props.openWindow}
          parent={object}
          type='del'
          companyName={ typeof User.Foretag !== 'undefined' && User.Foretag.name }
        />
      );
    } else {
      activeAddress = null;
    }

    return (
      <DropdownSelection
        title={ lang['label_delivery_address'] }
        label={ "deliveyAddresses" }
        selection={ selection }
        content={ activeAddress }
        parent={ object }
        selectable={true}
        addable={false}
        noEdit={true}
        onSelect={ this.setDeliveryAddress }
        filter={this.filter}
        filterKey={ this.filterKey }
        filterValue={ this.filterValue }

      />
    );
  }

  renderInvoiceAddress( company ){

    let invAaddress = (
      <Address
        lang={this.props.lang}
        object={company.ActiveInvoiceAddress}
        onAddressUpdate={this.onAddressUpdate}
        items={ company.addresses }
        parentId={company.id}
        openWindow={this.props.openWindow}
        parent={this.state.object}
        companyName={ company.name }
        type='inv'
      />
    );
    let selection = this.getAddressesByType( 'inv' );

    return (
      <DropdownSelection
        content={ invAaddress }
        label={ "invoiceAddresses" }
        title={ lang['label_invoice_address'] }
        selection={ selection }
        noEdit={true}
        selectable={ true }
        parent={ this.state.object }
        editable={ true }
        addable={ true }
        onSelect={ this.onItemSelect }
        filter={this.filter}
        filterKey={ this.filterKey }
        filterValue={ this.filterValue }

      />
    );

  }

  async componentDidMount(){
    const object = await this.fetchOrder();

    let self = this;
    $('.project-minimizer').click ( function( e ){
      e.stopPropagation();
      var minimize = $(this).closest('tr').siblings('.project_'+$(this).data('key'));
      $(this).find('.minimize-project').toggleClass('fa-chevron-down fa-chevron-up');
      $.each(minimize, function(i,el){
        $(el).children('td').toggleClass('closedRow');
      });
    });

    $("#dropdownMenu1"+object.oid).click( function(e ){

      if($('#order-rows'+object.oid+' input:checked').length <= 0 ){

        e.preventDefault();
        e.stopPropagation();
        alert( lang.warnings['check_boxes_first'] );
      }
    });

    $('#main-checkbox-'+object.oid).click( function () {
      var rows = $('#order-rows'+object.oid).find(':checkbox');
      var status = false;
      if($(this).is(':checked')){
        status = true;
      }
      $.each(rows, function(i,el){

        $(el).prop('checked',status);
      });
    });

    let User = {};
    const users = await this.getUsers();
    if( object.userId > 0 ){
      const response = await api.send('Employee', 'employee/'+object.userId, { group:'plain'}, { type:'GET'} );
      User = response.data;
  //    this.setState({ isLoaded:true });

    }

    this.setState({ User ,users, isLoaded:true });



  }

  toggleSelectAll( e ){

    store.toggleSelectAllObjectItems( e );
    const items = store.get('objectItems');
    let object = { ...this.state.object };
    object.rows = items;
    this.setState( { object });
//    this.renderRows()

  }

  handleChangeCombo = async ( obj ) => {
    let {object} = this.state;
    let objId = obj.value;

    object.userId = objId;
    const response = await api.saveObject( object.id, { userId: objId } , "Order");


    if( response.code === 200 ){
      const response = await api.send('Employee', 'employee/'+object.userId, { group:'plain'}, { type:'GET'} );
      const User = response.data;
      this.setState({ object, User, isLoaded:true });
//      this.setState({ object, isLoaded:true });
      //this.fetchOrder();
    }

  }

  async getUsers() {
    let list = [];
    const response = await api.send(
      "Employee",
      api.Employee.list,
      false,
      { type:'GET' }
    );
    if( response.data.length > 0 ){

      for(let i in response.data ){
        let obj = response.data[i];

        let item = {
          "label" : obj.fnamn +' '+obj.enamn,
          "value": obj.id
        }
        list.push(item);
      }



    }
    return list;
  }
  render(){
    let {object, User, isLoaded } = this.state;
    if( isLoaded === false ) return null;
  //  if( typeof object.id !== 'undefined' && object.userId === null )  return null;
    let orderContact;
    if( object.contact !== null && object.contact.length > 0 ){
      let phone;
      if( object.phone.length > 0 )
        phone = object.phone;

      orderContact = <div><div className="label">Leveranskontakt</div><div>{ object.contact }<br />{ phone }</div></div>
    }
    let attributes = this.props.prepareEditableAttributes( orderFormAttributes, object );

    let invoiceContent = ( ( api.exists( User ) &&api.exists( User.Foretag ) ) ? this.renderInvoiceAddress(User.Foretag) : <div> { lang['no_address_registered']} </div>) ;

    return (
        <>
        <div className="container-fluid">
            { !this.state.openOrder && !this.state.openImport &&
            <>
            <div className="row">
                <div className="col-md-6">
                    <ObjectForm
                        objectId={ object.oid }
                        attributes={attributes}
                        tabIndex={this.props.tabIndex}
                        savePath={ api.Order.save }
                        object={object}
                        onChange={this.onChange}
                        updateState={this.props.updateState}
                        module="Order"
                        lastUpdated={ ( <div className="alert alert-warning"><div className="small">{ typeof this.props.objectId !== 'undefined' && lang['label_last_updated'] + ' :: '+ object.time }</div></div> ) }
                    />
                    
                    <button
                        className="btn btn-sm btn-info ml-1"
                        onClick={(e) => this.openSlideIn(e, 'import')}
                    >
                        Import Excel
                    </button>
                </div>
                <div className="col-md-6 windowInfo">

                    { ( api.exists( User ) && api.exists( User.Foretag ) && User.Foretag.id > 0 ) &&
                    <div>
                        <div><span className="propLabel">{ lang['label_customer_number'] }</span> { User.Foretag.custnr }</div>
                        <span className="link" onClick={(e) => this.props.openWindow(e, 'Customer', User.Foretag.id, ['name'] )}>{ User.Foretag.name }</span>
                        <div><span className="propLabel">{ lang['label_email_invoice']}</span> { User.Foretag.email }</div>
                    </div>
                    }
                    { ( api.exists( User ) && User.id > 0 ) &&
                    <div className="row mt-2">
                        <div className="col">
                        <div className="propLabel">{ lang['label_buyer'] }</div>
                        <div><span className="link" onClick={(e) => this.props.openWindow(e,'Employee', User.id, ['fnamn','enamn'] )}>{ User.fnamn } { User.enamn }</span></div>
                        <div>{ User.email }</div>
                        </div>
                        <div className="col">
                        {orderContact}
                        </div>
                    </div>
                    }
                    <div style={{ margin:'20px 0'}}>
                    {
                        <Select
                        options={ this.state.users }
                        onChange={this.handleChangeCombo}
                        search="smart"
                        placeholder={ lang["order_owner"] }
                        />
                    }
                    </div>
                    { invoiceContent }
                    { object.pickup === false && this.renderDeliveryAddress ( object, User ) }
                    { object.pickup === true &&
                    <div className="card text-white bg-secondary mb-4">
                        <div className="card-body p-1"><h6 className="m-1">{ object.pickup === true && lang['will_be_picked_up'] }</h6></div>
                    </div>
                    }
                    <h3>Ordervärde: { accountingNumbers.se( this.getTotalValue() ,true ) }</h3>
                </div>
                </div>
                <hr className="separator"/>
                <div className="row">
                <div className="col-md-12">
                    <div className="dropdown">
                    <button className="btn btn-primary btn-sm dropdown-toggle changeTapeButton" type="button" id={"dropdownMenu1"+this.state.object.oid} data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
                        { lang['label_with_selected'] } <span className="caret"></span>
                    </button>
                    <button
                        className="btn btn-sm btn-info ml-1"
                        onClick={(e) => this.openSlideIn(e)}
                    >
                        { lang['open_production_order'] }
                    </button>
                    <ul className="dropdown-menu" aria-labelledby={"dropdownMenu1"+this.state.object.oid}>
                        <li className="dropdown-item">
                            <span className="" id="change-attach-method" onClick={(e) => this.handleChangeTape() }>
                                <i className="fas fa-cogs"></i> { lang['label_change_tape'] }
                            </span>
                        </li>
                        <li className="dropdown-item text text-danger">
                            <span onClick={(e) => this.deleteSelectedItems(e) }>
                                <i className="fas fa-times"></i> { lang['label_delete'] }
                            </span>
                        </li>
                        <li role="separator" className="divider"></li>
                    </ul>
                    <div id={"dialogHolder"} >
                    {
                        this.state.is_changing_items === true &&
                        
                            this.renderTapeForm( object.id )                         
                    }
                    </div>
                    </div>
                    <table className=" customer-order table responsive" id={object.oid+"_order_rows"}>
                    <thead>
                        <tr>
                        <th><input type="checkbox" onClick={ (e) => this.toggleSelectAll( e ) } id={'main-checkbox-'+this.state.object.oid} title={ lang['select_deselect_all'] }/></th>
                        <th></th>
                        <th>Produkt</th>
                        <th>Rader</th>
                        <th>Storlek</th>
                        <th>Fästmetod</th>
                        <th>Färg</th>
                        <th >ápris</th>
                        <th >Antal</th>
                        <th className="number">Pris</th>
                        </tr>
                    </thead>
                    <tbody id={"order-rows"+this.state.object.oid}>
                        { this.renderRows() }
                    </tbody>
                    </table>
                </div>
                <div className="col-sm-12">
                    <OrderTotal object={ object } />
                </div>
            </div>
            </>
        }
        
        </div>
        <div className="container-fluid">
            { 
                this.state.openOrder && 
                <SlideInView onClose={ e => this.closeSlide( e ) }>
                    <ProductionFormModule object={this.state.object} objectId={ this.state.object.id } lang={ lang }/>
                </SlideInView>
            }
            {
                this.state.openImport && 
                <SlideInView onClose={ e => this.closeSlide( e, 'import' ) }>
                    <ExcelImport object={this.state.object} objectId={ this.state.object.id } lang={ lang }/>
                </SlideInView>
            }
        </div>
      </>
    );
  }
};
