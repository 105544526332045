import React from 'react';
import useQRCodeGenerator from 'react-hook-qrcode-svg'

export default function QrCodeBlock( { data, sign, pixelConverter: pc } ){

    const x = pc.toPixel( data.position.left );
    const y = pc.toPixel( data.position.top );
    const props = {
        width: pc.toPixel( data.width ),
        height: pc.toPixel( data.height ),
    };
    const qrCodeData = this.fetchQrData();
    const { path, viewBox } = useQRCodeGenerator(value, QRCODE_LEVEL, QRCODE_BORDER)

    const fetchQrData = () => {
        const blocks = sign.blocks;
        const dataRows = data.value || [];
        let data = [];
        dataRows.forEach( item => {
            const [blockNr, rowNr] = item.split("_");
            const row = blocks[blockNr].rows[rowNr];
            data.push(row.text);
        })

        return data.join('\n');
    }



    return ( 
        <g transform={`translate(${x}, ${y})` } { ...props } dangerouslySetInnerHTML={{ __html: data.svg }} >
            <QrCode
                data={ qrCodeData }
                height={ props.height }
                width={ props.width }
                fgColor={ `${data.color_code}` }
                margin="0"
            />
        </g> 
    );
}
