import React, {Fragment} from 'react';
import Select from 'react-select';

export class Dimensions extends React.Component{

  constructor( props ) {
    super( props );
    this.label = this.props.lang.label_dimensions;
  }

  changeMeasurement = ( e ) => {
    const product = { ...this.props.object };
    const value = e.target.value;
    if( value.indexOf('x') > -1 ){
      const exp = value.split('x');
      product.width = parseInt(exp[0]);
      product.height = parseInt(exp[1]);

    } else {
      product.diameter = parseInt( value );
    }



    this.props.onUpdate( product )
  }
  changeMeasure = ( e, type ) => {
      const product = { ...this.props.object };
      product[type] = e.target.value;

      this.props.onUpdate( product );
  }
  renderMeasurement(){
    const product = { ...this.props.object };
    let html = (
      <div className="row option">
        <div className="col">
          <div>{ this.props.lang.label_width }</div>
          <input type="text" className="form-control" value={product.width} onChange={(e) => this.changeMeasure(e,'width')} />
        </div>
        <div className="col">
          <div>{ this.props.lang.label_height }</div>
          <input type="text" className="form-control" value={product.height} onChange={(e) => this.changeMeasure(e,'height')} />
        </div>
      </div>
    );

    return html;

  }
  renderSelection( selection ){
    const product = { ...this.props.object };
    let measurement = '';
    if( typeof product.diameter !== 'undefined' &&  product.diameter > 0 ){
      measurement = product.diameter;
    } else {
      measurement = product.width+'x'+product.height;
    }

    let html = (
      <Fragment>
        { this.label } <select
          value={ measurement }
          onChange={this.changeMeasurement}
          placeholder={ this.props.label }
          className="form-control"
        >
          {
            selection.map( (measure,i) => {

              return (
                <option key={i} value={measure}>{measure}</option>
              )
            })
          }
        </select>
      </Fragment>
    );

    return html;
  }

  getSelection( prodType ){
    let selection = [];
    switch( prodType ){

      case "ValveTag":
        selection = ["30","35","40","50"];
        this.label = this.props.lang.label_diameter;
      break;
      case "EmergencyStop":
        selection = ["40","50","60","70","80","90","100","110"];
        this.label = this.props.lang.label_diameter;
      break;
      case "CableMarker":
        selection = ["40x10","60x10","60x19","80x19"];
        this.label = this.props.lang.label_dimensions;
      break;
      case "CableMarkerSpecial":
        selection = ["30","40","50","60","70","80","90","100"];
      break;
      case "CableMarkerEmboss":
        selection = ["45x10","45x20","90x10","90x20"];
      break;
      case "WireMarker":

        selection = ["4.5","5","6","7","9","10","13"];
        this.label = this.props.lang.width;
      break;

      default:
      
    }

    return selection;
  }

  render(){

    const {object:product } = this.props;
    const selection = this.getSelection( product.product_type );

    return (
      <Fragment>
        { ( selection.length > 0 ) && this.renderSelection( selection ) }
        { selection.length <= 0 && this.renderMeasurement() }
      </Fragment>
    );
  }
};

export class AttachMethod extends React.Component {
  constructor( props ) {
    super( props );

  }

  handleTapeChange = ( option ) => {
    const {object:product} = this.props;
    product.tape = option.value;

    this.props.onUpdate( product );
  }

  handleHoleChange = ( e ) => {
    const {object:product} = this.props;
    product.hole_quantity = e.target.value;

    this.props.onUpdate( product );
  }

  shouldDisplay( type ){
    if( type === 'tape' ){
      let displayTape = ['rectangular','bib_a','bib_b','bib_c','bib_d','bib_e','bib_f','bib_g','emergency_stop'];
      return ( displayTape.indexOf(this.props.object.translation_tag  ) > -1 ? 'block' : 'none' );

    } else if( type === 'holes' )
     return ( this.props.object.translation_tag === 'rectangular' ? 'block' : 'none' );

  }
  render(){
    const selection = this.props.prepareSelection( this.props.tapes, 'tag','tag', 'tapes');
    const product = this.props.object;
    const displayTape = this.shouldDisplay( 'tape' );
    const displayHoles = this.shouldDisplay( 'holes' );
    const lang = this.props.lang;

    return(
      <div className="row">
        <div className="col" style={{ display:displayTape }}>
          <label >{ lang.label_attach_method }</label>
          <Select
            className="option"
            options={selection}
            defaultValue={this.props.object.tape}
            placeholder={ lang[this.props.object.tape]}
            onChange={ this.handleTapeChange }
          />

        </div>
        <div className="col" style={{ display:displayHoles }}>
          <label> { lang.label_holes } </label>
          <select
            className="form-control"
            defaultValue={product.hole_quantity}
            onChange={this.handleHoleChange}
          >
            <option key={0} value="0"> 0 </option>
            <option key={1} value="1"> 1 </option>
            <option key={2} value="2"> 2 </option>
            <option key={3} value="4"> 4 </option>
            <option key={4} value="6"> 6 </option>
            <option key={5} value="8"> 8 </option>
          </select>

        </div>
      </div>
    );

  }
};
