import React from 'react';
import {windowManager} from '../windowManager';
import {api} from './api';
import $ from 'jquery';
import {ObjectForm} from './ObjectForm.jsx';
import {newsFormAttributes} from './NewsModule';
import {FileUpload} from './fileUpload';

export class NewsFormModule extends React.Component{

  constructor( props ){
    super( props )

    this.state = {
      object : props.object,
      files: [{
        source: 'index.html',
        options: {
            type: 'local'
        }
      }]
    };

    this.wm = new windowManager();


  }

  updateWindowTitle = ( e ) => {

    this.props.updateTitle( e.target.value );

  }

  onChange = ( object, changes ) => {
    this.setState({ object : object });
    this.props.onChange( object, changes );
  }

  async fetchObject(){
    let self = this;
//    let url = api.api+api.News.read+'/'+this.props.objectId;
//    let order = false;

    await api.send(
      'News',
      'news/'+this.props.objectId,
      false,
      {
        type: 'GET',
        success : function( response ){

          let object = response.data;
          let time = new Date().getTime();
          self.setState({ isUpdated:  time, object: object });
          self.props.updateTitle( self.props.makeWindowTitle( object , ['title']) );
        }
      }
    );

  }

  async componentDidMount(){
    await this.fetchObject();
  }

  post = async (formData) => {

      const response = await api.send(
        'NewsModule',
        api.News.upload + '/' + this.state.object.id,
        formData,
        {
          processData: false,
          contentType: false,
          crossDomain: true,
          type: "POST"
        }
      );

      if( typeof response.object === 'object' && response.object !== null )
        this.onChange( response.object, response.object )

    //  console.log( response.object );
  }

  handleDelete = () => {
    $("#"+this.props.windowId).find('.close').click();
    window.location.reload(true);

  }

  render( ){

    let object = this.state.object;
    //console.log( object );
    if( typeof object === 'undefined' || object === false ) return null;
    let imageSrc = '/assets/images/icons/no_image.svg';
    if( typeof object.imagelink !== 'undefined' && object.imagelink.length > 0 )
      imageSrc =  api.root+''+ object.imagelink;
   
      
    let attributes = this.props.prepareEditableAttributes(newsFormAttributes, object );
    

    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-7">
            <ObjectForm
              objectId={ object.id }
              parent={this.props.parent}
              attributes={attributes}
              tabIndex={this.props.tabIndex}
              savePath={ api.News.save }
              object={object}
              onChange={this.onChange}
              updateState={this.props.updateState}
              module="News"
              onDelete={this.handleDelete}
            />
          </div>
          <div className="col-md-5">
            <div className="card">
                <img className="card-img-top" src={ imageSrc } style={{ width: '100%', maxHeight:300, objectFit: 'cover' }} alt="Card thumb cap" />
                <FileUpload
                  onSubmit={this.post}
                  id={ "fileForm_"+ object.id }
                  apiUrl={api.api + api.News.upload + '/'+ object.id }
                />
            </div>
          </div>
        </div>
      </div>
    );

  }
};
