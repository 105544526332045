export default{

    state: {
        activeModule: {},
        moduleItems: [],
        settings: {},
        objectItems: []
    },

    setState( newState ){
        const state = { ...this.state, ...newState };
        this.state = state;
    },   

    get( property ){
        return this.state[property];
    },
    set( property, payload ){
        if( Array.isArray( payload ) ){
            this.state[property] = [ ...payload ];
            
        } else if( typeof payload === 'object' ){
            this.state[property] = { ...payload };
            
        } else 
            this.state.property = payload;
    }, 

    toggleSelectAllObjectItems( event ){

        this.state.objectItems.forEach( item => {
            item.selected = event.target.checked;
        });
    },

    toggleSelectObjectItem( item ){
        
        const oItems = [ ...this.state.objectItems ]; 
        
        oItems.map( (i,index) => {
            let ii = { ... i };
            if( i.id === item.id ){
                ii.selected = !item.selected;
            }
            oItems[index] = ii;
        });

        this.state.objectItems = [ ...oItems ];

        
    },
    getSelectedItems(){
        return this.state.objectItems.filter( i => i?.selected === true );
    }


}