import React, { Component } from 'react';
//import $ from 'jquery';
//import {DataList} from './DataList.jsx'
import {api} from './api';
import {AreaPrices, MaterialPrices, DiscountPrices, OtherPrices} from './views/priceLists'
const lang = require("../lang/se.json");
const tabViews = {
  "areas"   : AreaPrices,
  "materials" : MaterialPrices,
  "discounts" : DiscountPrices,
  "other"     : OtherPrices
};

export class PricesModule extends Component{

  constructor( props ){
    super(props);

    this.state = {
      name          : 'Prices',
      module_query  : window.location.pathname.substring(1).split('/'),
      activeTab     : 'areas',
      priceList     : false

    };

  }

  openWindow = ( objectId ) => {
  //  this.props.openWindow( objectId, addressFormParams );
  }

  async _fetchPriceList(){
    const response = await api.send( 'Prices', 'prices/priceList', {}, { type:'GET'} );
    return response;
  }

  async componentDidMount(){

    let priceList = this.state.priceList || false;
    if( priceList === false ) {
      let response = await this._fetchPriceList();
      priceList = JSON.parse( response.data );
      this.setState ({ priceList });
    }

  }

  refreshPriceList = async () => {

    let response = await this._fetchPriceList();
    let priceList = JSON.parse( response.data );

    this.setState({ priceList });

  }

  onTabClick = ( prop ) => {
    this.setState({ activeTab: prop });
  }
  render(){

    const {activeTab} = this.state;
    const priceList = this.state.priceList;
    if( priceList === false ) return null;

    let tabs = [];
    for(let prop in priceList ) {
//      let list = priceList[prop];
      if(prop === 'textrows' || prop === 'holePrice' || prop === 'minOrderValue') continue;
      let content = (
        <a
          className={ activeTab === prop ? "nav-item nav-link active" : "nav-item nav-link"}
          id={"nav-"+prop+"-tab"}
          data-toggle="tab"
          key={prop}
          href={"#nav-"+prop}
          role="tab"
          aria-controls="nav-home"
          onClick={ () => this.onTabClick( prop ) }
        >
          { lang.priceLists[prop] }
        </a>
      );
      tabs.push(content);

    }
    const Component = tabViews[activeTab];

    const prices = ( activeTab === 'other' ? { ...priceList } : { ...priceList[activeTab] } );

    return (
      <div className="container-fluid">
        <div className="col" style={{ maxWidth: 1000 }}>
          <div id="moduleContent">
            <h3>{ lang[this.state.name] } </h3>
            <nav>
              <div className="nav nav-tabs" id="nav-tab" role="tablist">
              {tabs}
              <a
                className={ activeTab === 'other' ? "nav-item nav-link active" : "nav-item nav-link"}
                id={"nav-other-tab"}
                data-toggle="tab"
                key={'other'}
                href={"#nav-other"}
                role="tab"
                aria-controls="nav-home"
                onClick={ () => this.onTabClick( 'other' ) }
              >
                { lang.label_other }
              </a>
              </div>
            </nav>
            <div className="tab-content" id="nav-tabContent" style={{ border:'1px solid #dee2e6', borderTop:'none', padding:20, background:'white' }}>
              <div className="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
                  <Component prices={prices} lang={lang} refreshPriceList={this.refreshPriceList}/>
              </div>
            </div>
          </div>
        </div>
      </div>

    )

  }
}
