import React from 'react';
import { api } from '../api';
import {accountingNumbers} from '../../config.js';
const lang = require('../../lang/se.json');

export const SlideInView = (props) => {
    let style = {};
    if( typeof props.maxWidth !== 'undefined' )
        style.maxWidth = props.maxWidth;

    return (
        <div className="row" style={style}>
            <div className="col-sm-1">
                <button className="btn btn-custom" onClick={ props.onClose }><i className="fa fa-2x fa-arrow-left"></i></button>
            </div>
            <div className="col-sm-11">
                { props.children }
            </div>
            
        </div>

    );

};


