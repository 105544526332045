export const objectDatadump = ( jsonObject, extractData ) => {

    if( typeof extractData === 'undefined' ){
        extractData = ['id','entity','field1','aprice','rowprice','quantity','textrows'];
    }
    if( typeof jsonObject === 'string' && jsonObject.length > 0 ){
        jsonObject = JSON.parse( jsonObject );
    }
    //extract following data;
    let extracted = {};
        
    for( let property in jsonObject ){
        if( extractData.indexOf( property ) > -1 ){
            extracted[ property ] = jsonObject[ property ];
        }
    }

    return extracted;


};
